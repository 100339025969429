import React from 'react'
import { Label } from '../../components/label/label'
import { Box } from '@material-ui/core'
import { SingleSelect } from '../../components/singleSelect/singleSelect'
import { useTranslation } from 'react-i18next'
import { TextFieldComponent } from '../../components/textField/textField'
import { NumberInput } from 'src/components/numberInput/numberInput'

interface SelectPanelProps {
  details: any
  handleChange: Function
}

export function SelectPanelView(props: SelectPanelProps) {
  const { t } = useTranslation()
  const { handleChange, details } = props
  const result = details ? details.resultPercentage1 : ''
  const valueInVk = details && details.changeInDeductible ? details.changeInDeductible.vk : ''
  const valueInTk = details && details.changeInDeductible ? details.changeInDeductible.tk : ''
  const resultTypeOptions = [
    { label: t('pleaseChoose') },
    { label: 'reine Änderung der Vertragsform' },
    { label: 'Zuschlag' },
    { label: 'Zuschlag & SB-Erhöhung' },
    { label: 'SB-Erhöhung' },
    { label: 'Nachlass' },
    { label: 'reine Änderung der AKB' },
    { label: 'reine Änderung der Zahlweise' },
    { label: 'SB-Reduzierung' },
    { label: 'Ausgesetzt durch Verwaltung' },
    { label: 'Ausgesetzt durch Underwriter' },
  ]
  const typeOptions = [
    { label: t('pleaseChoose') },
    { label: 'Anpassung' },
    { label: 'Renewal' },
    { label: 'Sanierung' },
    { label: 'Sonstiges' },
  ]
  const brOptions = [{ label: t('pleaseChoose') }, { label: t('yes') }, { label: t('no') }]
  return (
    <Box display='flex' flexDirection='column' padding='3rem 0 0 4.5rem'>
      <Box width='232px' paddingBottom='1.4rem'>
        <Label value={t('type')} customStyles={{ fontSize: '0.625rem' }} />
        <SingleSelect
          options={typeOptions}
          defaultValue={details && details.type ? details.type : typeOptions[0].label}
          onChangeHandler={(value: any) => {
            handleChange(value, 'type')
          }}
        />
      </Box>
      <Box width='232px' paddingBottom='1.4rem'>
        <Label value={t('resultTypeCaps')} customStyles={{ fontSize: '0.625rem' }} />
        <SingleSelect
          options={resultTypeOptions}
          defaultValue={
            details && details.resultType ? details.resultType : resultTypeOptions[0].label
          }
          onChangeHandler={(value: any) => {
            handleChange(value, 'resultType')
          }}
        />
      </Box>
      <Box width='72px' paddingBottom='1.4rem'>
        <Label value={t('resultIn')} customStyles={{ fontSize: '0.625rem' }} />
        <NumberInput
          value={result}
          placeholder='XX%'
          size='large'
          onBlur={(value: any) => {
            handleChange(value, 'resultPercentage1')
          }}
        />
      </Box>
      <Box width='152px' paddingBottom='1.4rem'>
        <Label value={t('changeInDeductableCaps')} customStyles={{ fontSize: '0.625rem' }} />
        <Box display='flex' justifyContent='space-between'>
          <Box width='72px'>
            <TextFieldComponent
              value={valueInVk}
              placeholder='VK'
              size='large'
              handleChange={(value: any) => {
                handleChange(value, 'vk')
              }}
            />
          </Box>
          <Box width='72px'>
            <TextFieldComponent
              value={valueInTk}
              placeholder='TK'
              size='large'
              handleChange={(value: any) => {
                handleChange(value, 'tk')
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box width='232px' paddingBottom='1.4rem'>
        <Label value='BR-STOP' customStyles={{ fontSize: '0.625rem' }} />
        <SingleSelect
          options={brOptions}
          defaultValue={details && details.brStop ? details.brStop : brOptions[0].label}
          onChangeHandler={(value: any) => {
            handleChange(value, 'brStop')
          }}
        />
      </Box>
    </Box>
  )
}
