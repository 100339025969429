import { Box, Button, CircularProgress, makeStyles, Paper } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { DataTable, Column } from '../../components/data-table/data-table'
import { useProposals } from '../../reducers/proposal/proposal-hooks'
import { ReactComponent as ArrowIcon } from './arrow.svg'
import { NumberText } from '../../pages/calculation/claimHistoryTable/number-text'
import { CALCULATION_PAGE } from 'src/constants/routes'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flex: 'auto',
  },
  paper: {
    position: 'relative',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    overflowY: 'auto',
    display: 'flex',
    flex: 'auto',
  },
  progress: {
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
    top: '40%',
    width: '1rem',
    opacity: '80%',
  },
  roundButton: {
    width: '2.25rem',
    paddingLeft: '0.9rem',
    minWidth: '2.25rem',
    borderRadius: '2.25rem',
    padding: '2px 8px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  noPadding: {
    padding: 0,
  },
  customCell: {
    padding: '1.6rem 1rem',
    cursor: 'pointer',
  },
  errorMessage: {
    color: '#666666',
    fontSize: '16px',
  },
})

export function CalculationVersionsContainer(props: any) {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const { params } = props
  const [activeProposals = [], { loading }] = useProposals(params.id)
  const navigateToCalculationPage = (proposalId: string) => {
    history.push(`${CALCULATION_PAGE}/${params.id}/${proposalId}`)
  }
  const renderClickableCell = (item: any, record: any, precision?: number) => (
    <div className={classes.customCell} onClick={() => navigateToCalculationPage(record.id)}>
      {precision ? (
        <span>
          <NumberText precision={precision}>{item}</NumberText> %
        </span>
      ) : (
        item
      )}
    </div>
  )
  const headCells: Column<any>[] = [
    {
      uniqueId: 'version',
      key: 'version',
      title: 'Version',
      rowClassName: classes.noPadding,
      render: (item: any, record: any) => renderClickableCell(item, record),
    },
    {
      uniqueId: 'date',
      key: 'date',
      title: t('date'),
      rowClassName: classes.noPadding,
      render: (item: any, record: any) => renderClickableCell(item, record),
    },
    {
      uniqueId: 'underwriter',
      key: 'underwriter',
      title: t('author'),
      rowClassName: classes.noPadding,
      render: (item: any, record: any) => renderClickableCell(item, record),
    },
    {
      uniqueId: 'resultPercentage1',
      key: 'resultPercentage1',
      title: t('resultInPercentage'),
      rowClassName: classes.noPadding,
      render: (item: any, record: any) => renderClickableCell(item, record, 1),
    },
    {
      uniqueId: 'content',
      key: 'content',
      title: 'Art',
      rowClassName: classes.noPadding,
      render: (item: any, record: any) => renderClickableCell(item, record),
    },
    {
      uniqueId: 'resultType',
      key: 'resultType',
      title: t('proposalContent'),
      rowClassName: classes.noPadding,
      render: (item: any, record: any) => renderClickableCell(item, record),
    },
    {
      uniqueId: 'status',
      key: 'status',
      title: 'Status',
      rowClassName: classes.noPadding,
      render: (item: any, record: any) => (
        <div className={classes.customCell} onClick={() => navigateToCalculationPage(record.id)}>
          {t(item.toLowerCase())}
        </div>
      ),
    },
    {
      uniqueId: 'id2',
      key: 'id',
      title: '',
      rowClassName: classes.noPadding,
      render: (id: string) => (
        <div className={classes.customCell} onClick={() => navigateToCalculationPage(id)}>
          <Button className={classes.roundButton}>
            <ArrowIcon className='arrow' />
          </Button>
        </div>
      ),
    },
  ]

  return (
    <Box>
      {loading ? (
        <Paper className={classes.paper}>
          <Box height='200px'>
            <CircularProgress className={classes.progress} />
          </Box>
        </Paper>
      ) : activeProposals && activeProposals.length > 0 ? (
        <Paper className={classes.paper}>
          <Box className={classes.root}>
            <DataTable columns={headCells} data={activeProposals as any} />
          </Box>
        </Paper>
      ) : (
        <Box display='flex' justifyContent='center' className={classes.errorMessage}>
          {t('noData')}
        </Box>
      )}
    </Box>
  )
}
