import { createReducer } from 'redux-saga-tools'
import { CalculationCommentActionTypes } from './calculation-comments-action'
import { CalculationComments } from 'src/state/calculation-comments'

function addComment(state: CalculationComments, calculationComments: CalculationComments) {
  return { ...calculationComments }
}

export const calculationCommentsReducer = createReducer([], {
  [CalculationCommentActionTypes.LOAD_COMMENT]: addComment,
})
