import {
  ClaimSummary,
  Claim,
  SimulationByYear,
  Simulation,
  ObservationPeriod,
  RenewalOptions,
  Deductable,
  DeductableType,
} from '../../reducers/proposal/proposal'

function getSimulatedExpense(
  claim: Claim,
  fullCoverDeductible: number,
  partCoverDeductible: number,
) {
  if (claim.claimSegment === 'KH' || claim.claimSegment === 'SI' || claim.glas === '1') {
    return claim.totalExpense
  }

  if (claim.deductiblePaidByErgo > 0) {
    return claim.totalExpense
  } else {
    const value =
      claim.totalExpense - (claim.claimSegment === 'VK' ? fullCoverDeductible : partCoverDeductible)
    return value >= 0 ? value : 0
  }
}

export function simulateClaimSummary(
  claims: Claim[],
  claimSummary: ClaimSummary[],
  fullCoverDeductible: number,
  partCoverDeductible: number,
): SimulationByYear[] {
  const simulationByYear: {
    [id: number]: {
      expense: number
      noOfClaims: number
      reserves: number
      payments: number
      difference: number
    }
  } = {}
  claims.forEach(claim => {
    const year = claim.claimYear
    const acc = simulationByYear[year] || {
      expense: 0,
      noOfClaims: 0,
      reserves: 0,
      payments: 0,
      difference: 0,
    }
    const expense = getSimulatedExpense(claim, fullCoverDeductible, partCoverDeductible)
    acc.difference += expense - claim.expenseForErgo
    acc.expense += expense
    acc.reserves += claim.reserve || 0
    acc.payments = acc.expense - acc.reserves
    acc.noOfClaims +=
      claim.claimSegment === 'KH' ||
      (claim.expenseForErgo === 0 && claim.deductible === 0) ||
      expense > 0
        ? 1
        : 0
    if (!simulationByYear[year]) {
      simulationByYear[year] = acc
    }
  })

  const risksByYear = claimSummary.reduce((acc, claim) => {
    acc[claim.year] = claim.effectiveRisks || 1
    return acc
  }, {} as { [id: number]: number })

  const reserveByYear = claimSummary.reduce((acc, claim) => {
    acc[claim.year] = claim.reserves || 0
    return acc
  }, {} as { [id: number]: number })

  const paymentsByYear = claimSummary.reduce((acc, claim) => {
    acc[claim.year] = claim.payments || 0
    return acc
  }, {} as { [id: number]: number })

  return Object.keys(simulationByYear)
    .map(key => parseInt(key))
    .sort()
    .map(year => {
      const { noOfClaims, expense, payments, difference } = simulationByYear[year]
      return {
        year,
        expense,
        payments,
        noOfClaims,
        reserves: reserveByYear[year] || 0,
        claimPerRisk: (noOfClaims / risksByYear[year]) * 1000,
        averageClaim: noOfClaims
          ? (paymentsByYear[year] + reserveByYear[year] + difference) / noOfClaims
          : 0,
        difference,
      }
    })
}

export function getRenewalPremiumsForSimulations(
  claimSummary: ClaimSummary[],
  observationPeriod: ObservationPeriod,
  provisionCut: boolean,
  ratingReserve: number,
  ratioOfClaims: number,
  simulations: Simulation[],
  surcharge: number,
  currentCarpark: number,
  fleetPaymentMethod: string,
): RenewalOptions[] {
  const [lastPolicy = { premium: 0, months: 0 }] = [...claimSummary].sort(
    (claimA, claimB) => claimB.year - claimA.year,
  )

  const startYear = observationPeriod.startYear
  const endYear = observationPeriod.endYear || observationPeriod.startYear

  const claimSummaryUnderObservation = claimSummary.filter(
    summary => summary.year >= startYear && summary.year <= endYear,
  )
  const risksTillDate = claimSummaryUnderObservation
    .map(claim => claim.risks || 0)
    .reduce((total, risks) => total + risks, 0)
  const totalActualPayments = claimSummaryUnderObservation.reduce(
    (total, current) => total + current.payments,
    0,
  )
  const currentYear = new Date().getFullYear()
  const totalActualReserves = claimSummaryUnderObservation.reduce(
    (total, current) =>
      current.year === currentYear && current.reserves > 0
        ? total + (current.reserves * ratingReserve) / 100
        : total + current.reserves,
    0,
  )
  return simulations
    .filter(simulation => simulation)
    .map(simulation => {
      const simulationsUnderObservation = simulation.byYear.filter(
        s => s.year >= startYear && s.year <= endYear,
      )
      const totalPayments =
        simulation.type === DeductableType.CURRENT
          ? totalActualPayments
          : totalActualPayments +
            simulationsUnderObservation.reduce((total, sim) => total + sim.difference, 0)
      const totalReserves = DeductableType.CURRENT
        ? totalActualReserves
        : simulationsUnderObservation.reduce(
            (total, sim) =>
              sim.year === currentYear && sim.reserves > 0
                ? total + (sim.reserves * ratingReserve) / 100
                : total + sim.reserves,
            0,
          )
      // const grossReserves = (totalReserves * ratingReserve) / 100
      const totalClaimExpense = parseFloat((totalPayments + totalReserves).toFixed(2))
      const premiumPerRisk = parseFloat(
        (totalClaimExpense / parseFloat(risksTillDate.toFixed(2))).toFixed(2),
      )
      const neededPremiumPerRisk = parseFloat(((premiumPerRisk / ratioOfClaims) * 100).toFixed(2))
      const premiumPerRiskWithSurcharge = parseFloat(
        (neededPremiumPerRisk * (1 + surcharge / 100)).toFixed(2),
      )
      const premiumPerRiskWithProvisonCut =
        premiumPerRiskWithSurcharge * (provisionCut ? 1 - 9.5 / 100 : 1)
      const netPremiumPerRisk = parseFloat(premiumPerRiskWithProvisonCut.toFixed(2))
      const grossPremiumPerRisk = parseFloat((netPremiumPerRisk * 1.19).toFixed(2))
      let calculatedActualPremium = 0
      if (fleetPaymentMethod === 'M') {
        calculatedActualPremium = (lastPolicy.premium / lastPolicy.months) * 12
      } else if (fleetPaymentMethod === 'H' && lastPolicy.months <= 6) {
        calculatedActualPremium = lastPolicy.premium * 2
      } else if (fleetPaymentMethod === 'V' && lastPolicy.months <= 3) {
        calculatedActualPremium = lastPolicy.premium * 4
      } else if (fleetPaymentMethod === 'V' && lastPolicy.months <= 6) {
        calculatedActualPremium = (lastPolicy.premium / 2) * 4
      } else if (fleetPaymentMethod === 'V' && lastPolicy.months <= 9) {
        calculatedActualPremium = (lastPolicy.premium / 3) * 4
      } else {
        calculatedActualPremium = lastPolicy.premium
      }
      const actualPremium = parseFloat(calculatedActualPremium.toFixed(2))
      const netPremium = parseFloat((netPremiumPerRisk * currentCarpark).toFixed(2))
      const grossPremium = grossPremiumPerRisk * currentCarpark
      return {
        deductible: {
          type: simulation.type,
          fullCover: simulation.fullCoverDeductible,
          partCover: simulation.partCoverDeductible,
          includeInProposal: simulation.includeInProposal,
        },
        difference: netPremium - actualPremium,
        differencePercentage: ((netPremium - actualPremium) / actualPremium) * 100,
        grossPremium,
        grossPremiumPerRisk,
        netPremium,
        netPremiumPerRisk,
      }
    })
}

export function calculateNetClaimDetails(claimSummary: ClaimSummary[] = []) {
  let totalRisks = 0
  let totalPremium = 0
  let totalClaims = 0
  let totalPayments = 0
  let totalReserves = 0
  let totalExpense = 0
  let totalAverageClaim = 0
  let totalCpr = 0
  let totalRatioOfClaim = 0
  let effectiveRisks = 0
  claimSummary.forEach(claim => {
    totalRisks += claim.risks
    totalPremium += claim.premium
    totalClaims += claim.claims
    totalReserves += claim.reserves
    totalPayments += claim.payments
    effectiveRisks += (claim.risks / 12.0) * claim.months
  })

  totalCpr = (totalClaims / effectiveRisks) * 1000
  totalExpense = totalPayments + totalReserves
  totalAverageClaim = totalExpense / totalClaims
  totalRatioOfClaim = (totalExpense / totalPremium) * 100
  return {
    effectiveRisks,
    totalRisks,
    totalPremium,
    totalClaims,
    totalPayments,
    totalReserves,
    totalExpense,
    totalAverageClaim,
    totalCpr,
    totalRatioOfClaim,
  }
}

export function simulateClaim(
  claims: Claim[],
  claimSummary: ClaimSummary[],
  deductable: Deductable,
): Simulation {
  const { type, fullCover, partCover } = deductable
  const summaryByYear = simulateClaimSummary(claims, claimSummary, fullCover, partCover)
  return {
    type,
    fullCoverDeductible: fullCover,
    partCoverDeductible: partCover,
    totalExpense: summaryByYear
      .map(summary => summary.expense)
      .reduce((total = 0, expense) => total + expense),
    byYear: summaryByYear,
    includeInProposal: deductable.includeInProposal,
  }
}
