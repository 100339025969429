import { Reminder } from '../../state/reminder'

export const ReminderActionTypes = {
  LIST_REMINDERS: 'LIST_REMINDERS',
  LOAD_REMINDERS: 'LOAD_REMINDERS',
  MARK_AS_COMPLETE: 'MARK_AS_COMPLETE',
}

export const ReminderActions = {
  listReminders: () => ({ type: ReminderActionTypes.LIST_REMINDERS }),
  loadReminders: (reminders: Reminder[]) => ({
    type: ReminderActionTypes.LOAD_REMINDERS,
    payload: reminders,
  }),
  markReminderAsComplete: (id: string) => ({
    type: ReminderActionTypes.MARK_AS_COMPLETE,
    payload: id,
  }),
}
