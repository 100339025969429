import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectProgress } from 'redux-saga-tools'
import { AppState } from '../../state/app-state'
import { User } from './user'
import { UserActions, UserActionTypes } from './user-actions'

export function useCurrentUser(): [User | undefined, { loading: boolean; error?: string }] {
  const dispatch = useDispatch()

  // Select user from AppState
  const user = useSelector((state: AppState) =>
    state && state.users ? state.users.currentUser : undefined,
  )

  // Fetch user
  useEffect(() => {
    dispatch(UserActions.getCurrentUser())
  }, [dispatch])

  // Watch for action progress
  const { inProgress = false, error } =
    useSelector(state => selectProgress(state, UserActionTypes.GET_CURRENT_USER)) || {}

  return [user, { loading: inProgress, error }]
}

export function usePing() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(UserActions.getPing())
  }, [dispatch])
}
