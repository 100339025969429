import { createReducer } from 'redux-saga-tools'
import { SearchFilters } from '../../state/fleet-search-filters'
import { SearchFiltersActionTypes } from './fleet-search-filters-actions'

function addSearchFilters(state: SearchFilters, searchFilters: SearchFilters) {
  return searchFilters
}

export const searchFiltersReducer = createReducer(
  {},
  {
    [SearchFiltersActionTypes.LOAD_SEARCH_FILTERS]: addSearchFilters,
  },
)
