import React, { useState, useEffect } from 'react'
import { Box, makeStyles, createStyles, CircularProgress } from '@material-ui/core'
import CommentIcon from '@material-ui/icons/Comment'
import CloseIcon from '@material-ui/icons/Close'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { TextAreaComponent } from '../../components/textArea/textArea'
import { Button } from '../../components/button/button'
// import { useCurrentProposal } from '../../reducers/proposal/proposal-hooks'
import { useSelectedFleet } from '../../reducers/fleet/fleet-hooks'
import {
  useGetComments,
  useFetchComments,
  useSaveComments,
  useUpdateComments,
} from '../../reducers/calculation-comments/calculation-comments-hooks'
import { useTranslation } from 'react-i18next'

export function CalculationComments() {
  const [showComments, setShowComments] = useState(false)

  const useStyles = makeStyles(() =>
    createStyles({
      commentIconContainer: {
        position: 'fixed',
        right: '22px',
        bottom: '20px',
        cursor: 'pointer',
        color: 'white',
        width: '40px',
        height: '40px',
        background: '#545241',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
      },
    }),
  )

  const toggleDrawer = (show: any) => {
    const toShowComment = show !== 'undefined' ? show : !showComments
    setShowComments(toShowComment)
  }

  const classes = useStyles()
  return (
    <>
      <Box className={classes.commentIconContainer} onClick={toggleDrawer}>
        <CommentIcon />
      </Box>
      {showComments ? <DrawerComponent onClose={toggleDrawer} /> : null}
    </>
  )
}

function DrawerComponent(props: any) {
  const { onClose } = props
  const { t } = useTranslation()
  // const proposal: any = useCurrentProposal()
  const { id } = useSelectedFleet() || {}
  const [comment, setComment] = useState('')
  const [getComments, { fetchLoading, fetchError }] = useGetComments()
  const [saveComments, { saveLoading, saveError }] = useSaveComments()
  const [updateComments, { loading, error }] = useUpdateComments()
  const fleetCommentsObject = useFetchComments()
  const [buttonDisableStatus, setButtonDisableStatus] = useState(true)

  useEffect(() => {
    id && getComments(id)
  }, [id])

  useEffect(() => {
    fleetCommentsObject &&
    fleetCommentsObject.comment &&
    fleetCommentsObject.fleetId === parseInt(id)
      ? setComment(fleetCommentsObject.comment)
      : setComment('')
  }, [fleetCommentsObject])

  useEffect(() => {
    setButtonDisableStatus(fetchError || (!error && !saveError))
  }, [fetchError, error, saveError])

  const useStyles = makeStyles(() =>
    createStyles({
      commentsContainer: {
        width: '440px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '0 16px 0',
      },
      commentsHeaderContainer: {
        display: 'flex',
        flexBasis: '10%',
        paddingTop: '7px',
        paddingBottom: '7px',
      },
      commentsHeaderLeft: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '8px',
        boxSizing: 'border-box',
        '& svg': {
          marginTop: '3px',
        },
        '& h3': {
          marginLeft: '8px',
        },
      },
      commentsHeaderRight: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      commentsContentContainer: {
        display: 'flex',
        flexBasis: '90%',
        flexDirection: 'column',
      },
      commentsTextareaContainer: {
        flexBasis: '85%',
        position: 'relative',
      },
      commentsActionsContainer: {
        flexBasis: '15%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
    }),
  )
  const handleSaveComment = () => {
    setButtonDisableStatus(true)
    fleetCommentsObject &&
    fleetCommentsObject.commentId &&
    fleetCommentsObject.fleetId === parseInt(id)
      ? updateComments(fleetCommentsObject.commentId, comment)
      : saveComments(id, comment)
  }

  const handleCommentType = (e: any) => {
    setComment(e)
    !fetchError && buttonDisableStatus && setButtonDisableStatus(false)
  }

  const handleDrawerOpen = () => {
    // console.log('Open')
  }

  const classes = useStyles()
  return (
    <div>
      <SwipeableDrawer
        open
        onClose={e => {
          e.stopPropagation()
        }}
        onOpen={handleDrawerOpen}
        anchor='right'
      >
        <Box className={classes.commentsContainer}>
          <Box className={classes.commentsHeaderContainer}>
            <Box className={classes.commentsHeaderLeft}>
              <CommentIcon />
              <h3>Kommentare</h3>
            </Box>
            <Box className={classes.commentsHeaderRight}>
              <span
                onClick={() => {
                  onClose(false)
                }}
              >
                <CloseIcon />
              </span>
            </Box>
          </Box>
          <Box className={classes.commentsContentContainer}>
            <Box className={classes.commentsTextareaContainer}>
              <TextAreaComponent value={comment} handleChange={handleCommentType} />
            </Box>
            <Box display='flex' flex-flexDirection='row'>
              {!error && !fetchError && !saveError ? (
                <>
                  {loading || fetchLoading || saveLoading ? (
                    <Box position='absolute' top='50%' left='45%'>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Box />
                  )}
                </>
              ) : (
                <Box
                  display='flex'
                  justifyContent='center'
                  color='#666666'
                  fontSize='16px'
                  flexDirection='column'
                >
                  {t('somethingWentWrong')}
                </Box>
              )}
              <Box
                className={classes.commentsActionsContainer}
                display='flex'
                flexGrow='1'
                justifyContent='flex-end'
                paddingTop='1.5rem'
              >
                <Button
                  clickHandler={handleSaveComment}
                  disabled={buttonDisableStatus}
                  margin='0 0 0 1rem'
                  size='large'
                  color='primary'
                  height='40px'
                >
                  {t('save')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </SwipeableDrawer>
    </div>
  )
}
