import React from 'react'
import { Box, Paper, WithStyles, withStyles } from '@material-ui/core'
import { OverviewEmbed } from 'src/views/viya-objects/overview'

const styles = {
  sasWrapper: {
    display: 'flex',
    margin: '4px',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '700px',
  },
}

class SasOverviewContainer extends React.Component<WithStyles<typeof styles>, {}> {
  shouldComponentUpdate(): boolean {
    return false
  }

  render() {
    const { classes } = this.props
    return (
      <Paper square={false}>
        <Box className={classes.sasWrapper}>
          <OverviewEmbed />
        </Box>
      </Paper>
    )
  }
}

export default withStyles(styles)(SasOverviewContainer)
