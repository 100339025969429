import { createReducer } from 'redux-saga-tools'
import { FleetSearchResult, FleetDetail } from '../../state/fleet'
import { FleetActionTypes } from './fleet-actions'
import { FleetState } from 'src/state/fleet-state'

function addFleets(
  state: { fleetList: FleetSearchResult[]; selectedFleet: FleetSearchResult },
  fleets: FleetSearchResult[],
) {
  return (
    { fleetList: fleets, selectedFleet: state.selectedFleet } || {
      fleetList: [],
      selectedFleet: state.selectedFleet,
    }
  )
}

function setSelectedFleet(state: FleetState, id: number) {
  return {
    ...state,
    selectedFleet: state.byId[id],
  }
}

function addFleetById(state: FleetState, { fleet }: { fleet: FleetDetail }): FleetState {
  return {
    ...state,
    byId: {
      ...(state.byId || {}),
      [fleet.id]: fleet,
    },
  }
}

function addStatus(state: FleetState, payload: any): any {
  return {
    ...state,
    fleetWatch: payload,
  }
}

export const fleetReducer = createReducer([], {
  [FleetActionTypes.LOAD_FLEETS]: addFleets,
  [FleetActionTypes.SELECT_FLEET]: setSelectedFleet,
  [FleetActionTypes.ADD_FLEET]: addFleetById,
  [FleetActionTypes.ADD_WATCH_STATUS]: addStatus,
})
