export const USER_PROFILE_URL = '/api/auth/profile'
export const API_AUTH_URL = '/api/auth/login'

export const SAS_REPORT_URI = '/reports/reports/8e36a99c-2fa6-4230-88d0-f8ef4d0d5e48'

export const DASHBOARD_PAGE = '/app/dashboard'
export const SUMMARY_PAGE = '/app/summary'
export const FLEETS_PAGE = '/app/fleets'
export const FLEET_DETAILS_PAGE = '/app/fleet-details'
export const CALCULATION_PAGE = '/app/calculation'

export const PROD = 'krgf.ergo.de'
export const INTRANET_URL = 'http://ergo-teams/sites/KRIAD/SitePages/Homepage.aspx'
export const NEW_BUSINESS_URL =
  'http://ergo-teams/sites/KRIAD/Flottengesch%C3%A4ft/_layouts/15/start.aspx#/Kalkulation/Forms/AllItems.aspx'
