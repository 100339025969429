import React from 'react'
import { Box } from '@material-ui/core'
import { Logo } from '../../components/logo/logo'
import { Button } from 'src/components/button/button'
import { API_AUTH_URL } from 'src/constants/routes'

export function LoginPage() {
  const handleLoginClick = () => {
    window.location.assign(API_AUTH_URL)
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      width='100%'
      height='100vh'
    >
      <Box marginBottom='1rem' display='block'>
        <Logo width='160px' height='67px' />
      </Box>
      <Button size='large' clickHandler={handleLoginClick}>
        Anmelden
      </Button>
    </Box>
  )
}
