import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './app'
import './utils/languageTransformer'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from '@material-ui/styles'
import { theme } from './styles/theme'
import './index.css'

const renderApp = () => {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>,
    document.getElementById('root'),
  )
  window.addEventListener('keydown', (e: any) => {
    const nodeName = e.target.nodeName.toLowerCase()
    if (
      e.which === 8 &&
      !(nodeName === 'input' && e.target.type === 'text') &&
      nodeName !== 'textarea'
    ) {
      e.preventDefault()
    }
  })
}

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./app', renderApp)
}

renderApp()
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
