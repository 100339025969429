import React from 'react'
import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { NumberText } from '../../pages/calculation/claimHistoryTable/number-text'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      overflowX: 'auto',
      marginBottom: theme.spacing(2),
      marginTop: '7px',
      padding: '0 16px 4px',
    },
    tableHeadCell: {
      borderBottomWidth: '2px',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    tableCell: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      border: 0,
      textAlign: 'center',
    },
  }),
)

const StyledTableCell = withStyles({
  root: {
    color: '#333333',
    fontSize: '.75rem',
  },
})(TableCell)

interface SimpleTableProps<T, S> {
  rows: T[]
  headCells: S[]
}

export function SimpleTable<
  T,
  S extends { id: string; numeric?: boolean; suffix?: string; precision?: number },
>(props: SimpleTableProps<T, S>) {
  const classes = useStyles()
  const { rows = [], headCells } = props

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Table size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              {headCells.map((head: any) => (
                <StyledTableCell className={classes.tableHeadCell} key={head.label}>
                  {head.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any, index) => (
              <TableRow key={index}>
                {headCells.map(({ id, numeric, suffix, precision }, i) => {
                  return (
                    <StyledTableCell
                      component='td'
                      key={i}
                      scope='row'
                      align='left'
                      className={classes.tableCell}
                    >
                      {row[id] ? (
                        numeric ? (
                          <NumberText precision={precision}>{row[id]}</NumberText>
                        ) : (
                          row[id]
                        )
                      ) : (
                        ''
                      )}
                      {row[id] === 0 ? row[id] : ''}
                      {suffix && isNaN(row[id]) ? row[id] : suffix}
                    </StyledTableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  )
}
