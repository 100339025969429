import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import { createStyles } from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    labelStyles: {
      color: '#333333',
      fontSize: '1rem',
      lineHeight: '1.1875rem',
      height: '1.1875rem',
    },
  }),
)

interface LabelProps {
  value?: string
  customStyles?: object
}

export function Label(props: LabelProps) {
  const classes = useStyles()
  const { value, customStyles } = props
  return (
    <div className={classes.labelStyles} style={customStyles}>
      {value}{' '}
    </div>
  )
}
