import React from 'react'
import { Box, makeStyles, createStyles } from '@material-ui/core'
import { Button } from '../../components/button/button'
import { Label } from '../../components/label/label'
import { translateItem } from '../../utils/languageTransformer'
import envLink, { SAS_VA_QUICK_LINK } from '../../utils/envLink'
import { INTRANET_URL, NEW_BUSINESS_URL } from 'src/constants/routes'

const useStyles = makeStyles(() =>
  createStyles({
    buttonWrapper: {
      justifyContent: 'space-evenly',
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: '10px',
      '& > button': {
        flexBasis: '30%',
        margin: '0 0.5rem 1rem 0',
      },
    },
  }),
)

export default function QuickLinks() {
  const openExternalLink = (url: string) => {
    window.open(url)
  }
  const vaQuickLink = envLink(SAS_VA_QUICK_LINK)
  const classes = useStyles()
  return (
    <Box paddingTop='45px'>
      <Label value={translateItem('quickLinks')} />
      <Box padding='0.5rem' className={classes.buttonWrapper}>
        <Button
          clickHandler={() => openExternalLink(vaQuickLink)}
          size='small'
          color='secondary'
          height='32px'
        >
          {translateItem('sasVA')}
        </Button>
        {/* <Button
          clickHandler={() =>
            openExternalLink('file://///corp.ergo/public/KR_Firmen/KR_Flotten_DB.accdr')
          }
          size='small'
          color='secondary'
          height='32px'
        >
          {translateItem('datenbank')}
        </Button> */}
        <Button
          clickHandler={() => openExternalLink(INTRANET_URL)}
          size='small'
          color='secondary'
          height='32px'
        >
          {translateItem('intranet')}
        </Button>

        <Button
          clickHandler={() => openExternalLink(NEW_BUSINESS_URL)}
          size='small'
          color='secondary'
          height='32px'
        >
          {translateItem('newBusiness')}
        </Button>
        <Button
          clickHandler={() => (window.location.href = 'mailto:?')}
          size='small'
          color='secondary'
          height='32px'
        >
          {translateItem('message')}
        </Button>
      </Box>
    </Box>
  )
}
