import React, { ReactChild, ReactNode } from 'react'
import { Paper } from '@material-ui/core'

interface CardProps {
  rounded?: boolean
  children: ReactNode | ReactChild | Array<ReactChild | ReactNode>
}
export function Card(props: CardProps) {
  const { children, rounded = false } = props
  return <Paper square={!rounded}>{children}</Paper>
}
