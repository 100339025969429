import React, { useEffect, useState, ReactElement } from 'react'
import {
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableSortLabel,
} from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flex: 'auto',
  },
  table: {
    width: '100%',
  },
  paper: {
    position: 'relative',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flex: 'auto',
  },
  headerCell: {
    padding: '0.8rem',
    height: '38px',
    fontWeight: 'bold',
    color: '#333333',
    fontSize: '.75rem',
    backgroundColor: '#FFFFFF',
    borderBottom: '1px solid #333',
  },
  cell: {
    color: '#333333 !important',
    fontSize: '.75rem',
    padding: '1rem',
  },
  checkboxCell: {
    padding: 0,
    verticalAlign: 'middle',
    textAlign: 'center',
  },
  roundButton: {
    height: '2.25rem',
    width: '2.25rem',
    paddingLeft: '0.9rem',
    minWidth: '2.25rem',
    borderRadius: '2.25rem',
  },
  row: {
    '& .arrow': {
      transform: 'rotate(270deg)',
      width: '0.75rem',
      height: '0.75rem',
      opacity: '0.5',
    },
    '&:hover  .arrow': {
      opacity: 1,
    },
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
  },
  progress: {
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
    top: '40%',
    width: '1rem',
    opacity: '80%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  activeSortIcon: {
    color: '#8E0038 !important',
  },
  inactiveSortIcon: {
    color: '#CCCCCC !important',
  },
})

export interface Column<T> {
  uniqueId: React.Key
  key: React.Key
  title: string
  sortable?: boolean
  rowClassName?: string
  headerClassName?: string
  render?: (record: any, index: T) => ReactElement
}

interface DataTableProps<T> {
  columns: Column<T>[]
  data: T[]
  page?: number
  rowsPerPage?: number
  headClassName?: string
}

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

type Order = 'asc' | 'desc' | undefined

function getItem(a: 'INPROGRESS' | 'Create' | 'SENT' | 'NEGOTIATION' | 'DONE' | 'CANCELLED') {
  const statusEnum = {
    Create: 'Esterlen',
    INPROGRESS: 'InBearbeitung',
    SENT: 'Versendet',
    NEGOTIATION: 'Verhandlung',
    DONE: 'Abgeschlossen',
    CANCELLED: 'Storniert',
  }
  return statusEnum[a]
}

function desc(a: any, b: any, orderBy: any) {
  const aItem = orderBy === 'status' ? getItem(a[orderBy]) : a[orderBy]
  const bItem = orderBy === 'status' ? getItem(b[orderBy]) : b[orderBy]
  const firstItem = bItem && isNaN(bItem) ? bItem.toUpperCase() : bItem
  const secondItem = aItem && isNaN(aItem) ? aItem.toUpperCase() : aItem
  if (firstItem < secondItem) {
    return -1
  }
  if (firstItem > secondItem) {
    return 1
  }
  // if (firstItem === secondItem && b['id'] && a['id']) {
  //   if (b['id'] < a['id']) {
  //     return -1
  //   }
  //   if (b['id'] > a['id']) {
  //     return 1
  //   }
  //   return 0
  // }
  return 0
}

function getSorting<K extends keyof any>(
  order: Order,
  orderBy: K,
): (a: { [key in K]: any }, b: { [key in K]: any }) => number {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

export function DataTable<T extends { id: string; [id: string]: any }>(props: DataTableProps<T>) {
  const classes = useStyles()
  const { columns = [], data = [], page = 0, rowsPerPage = data.length, headClassName } = props
  const [items, setItems] = useState(data)
  const [order, setOrder] = useState<'asc' | 'desc' | undefined>('asc')
  const [orderBy, setOrderBy] = useState<any>()

  const handleRequestSort = (property: any) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }
  const createSortHandler = (property: keyof T) => {
    handleRequestSort(property)
  }

  useEffect(() => {
    setItems(data)
  }, [data])
  return (
    <Table className={classes.table} stickyHeader>
      <TableHead className={headClassName}>
        <TableRow>
          {columns.map(column => (
            <TableCell
              className={`${classes.headerCell} ${column.headerClassName}`}
              key={column.uniqueId}
            >
              {column.sortable ? (
                <TableSortLabel
                  active={column.sortable}
                  direction={order}
                  style={{
                    flexDirection: 'row-reverse',
                    cursor: !column.sortable ? 'inherit' : 'pointer',
                    color: !column.sortable ? 'inherit' : '#8E0038 !important',
                  }}
                  hideSortIcon={!column.sortable}
                  onClick={() => column.sortable && createSortHandler(column.key)}
                  classes={{
                    icon:
                      orderBy === column.key ? classes.activeSortIcon : classes.inactiveSortIcon,
                  }}
                >
                  {orderBy === column.key ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted ascending' : 'sorted descending'}
                    </span>
                  ) : null}
                  {column.title}
                </TableSortLabel>
              ) : (
                column.title
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {stableSort(items, getSorting(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(row => (
            <TableRow
              key={
                row.uwaName && row.brokerName
                  ? `${row.id + row.uwaName + row.brokerName}`
                  : (row.id as string)
              }
              className={classes.row}
            >
              {columns.map(column => (
                <TableCell
                  key={column.uniqueId}
                  className={`${classes.cell} ${column.rowClassName}`}
                >
                  {column.render ? column.render(row[column.key], row) : row[column.key]}
                </TableCell>
              ))}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}
