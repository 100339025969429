import { User } from './user'

export const UserActionTypes = {
  LOAD_USERS: 'LOAD_USERS',
  ADD_USER: 'ADD_USER',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  GET_CURRENT_USER: 'GET_CURRENT_USER',
  GET_PING: 'GET_PING',
}

export const UserActions = {
  loadUsers: () => ({ type: UserActionTypes.LOAD_USERS }),
  setCurrentUser: (user: User) => ({
    type: UserActionTypes.SET_CURRENT_USER,
    payload: user,
  }),
  getCurrentUser: () => ({
    type: UserActionTypes.GET_CURRENT_USER,
  }),
  getPing: () => ({
    type: UserActionTypes.GET_PING,
  }),
}
