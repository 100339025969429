import { User } from './user'
import { UserActionTypes } from './user-actions'
import { createReducer } from 'redux-saga-tools'
import { UserState } from 'src/state/user-state'

function addCurrentUser(state: UserState, user: User): UserState {
  return {
    ...state,
    currentUser: user,
  }
}

export const userReducer = createReducer([], {
  [UserActionTypes.SET_CURRENT_USER]: addCurrentUser,
})
