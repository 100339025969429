import { Box, CircularProgress } from '@material-ui/core'
import React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { LoginPage } from 'src/pages/login/login-page'
import { useCurrentUser } from 'src/reducers/user/user-hook'

export const ProtectedRoute = ({ children, component, ...rest }: RouteProps) => {
  const [currentUser, { loading }] = useCurrentUser()

  return (
    <Route
      component={currentUser && currentUser.id ? component : undefined}
      {...rest}
      render={() =>
        currentUser && currentUser.id ? (
          children
        ) : loading ? (
          <Box
            display='flex'
            flex='auto'
            justifyContent='center'
            alignItems='center'
            height='100vh'
          >
            <CircularProgress />
          </Box>
        ) : (
          <LoginPage />
        )
      }
    />
  )
}
