import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import ErrorIcon from '@material-ui/icons/Error'
import { Label } from '../../components/label/label'
import { Button } from '../../components/button/button'
import { translateItem } from '../../utils/languageTransformer'
import { useSetWatchList, useRemoveWatchList } from '../../reducers/fleet/fleet-hooks'
import { CALCULATION_PAGE } from 'src/constants/routes'

interface SelectedFleetProps {
  fleetPaymentText: any
  id: string
  fleetName: string
  directorateId: string
  agencyId: string
  brokerName: string
  knr: number
}

interface DetailsHeaderProps {
  selectedFleet: SelectedFleetProps
  inWatchList?: boolean
}

const useStyles = makeStyles(() => ({
  pageHeading: {
    fontSize: '1.5rem',
    fontWeight: 400,
    margin: 0,
    lineHeight: '23px',
  },
  pageHeadingLabel: {
    fontSize: '12px',
    lineHeight: '14px',
    color: '#737373',
  },
  newProposalContainer: {
    textAlign: 'right',
  },
}))

function AddIcons() {
  return <AddRoundedIcon />
}

export default function DetailsHeader(props: DetailsHeaderProps) {
  const { selectedFleet, inWatchList } = props
  const classes = useStyles()
  const history = useHistory()
  const [watchList, setWatchList] = useState(inWatchList)
  useEffect(() => {
    setWatchList(inWatchList)
  }, [inWatchList])
  const setWatchListValue = useSetWatchList()
  const removeWatchListValue = useRemoveWatchList()

  return (
    <Box display='flex' justifyContent='space-between' paddingBottom='2.5rem'>
      <Box display='flex' flexDirection='column'>
        <Box display='flex'>
          {watchList ? (
            <ErrorIcon
              color='primary'
              onClick={() => {
                removeWatchListValue(selectedFleet.id, false)
              }}
            />
          ) : (
            <ErrorOutlineIcon
              onClick={() => {
                setWatchListValue(selectedFleet.id, true)
              }}
            />
          )}
          <Label
            value={selectedFleet.fleetName}
            customStyles={{ fontSize: '1.5rem', color: '#333333', marginLeft: '7px' }}
          />
        </Box>
        <Box display='flex'>
          <Label
            value={`MNR: ${selectedFleet.id}`}
            customStyles={{ fontSize: '0.75rem', color: '#545241', padding: '0.375rem 1rem 0 0' }}
          />
          <Label
            value={`KNR: ${selectedFleet.knr}`}
            customStyles={{ fontSize: '0.75rem', color: '#545241', padding: '0.375rem 1rem 0 0' }}
          />
          <Label
            value={`RD: ${selectedFleet.directorateId}`}
            customStyles={{ fontSize: '0.75rem', color: '#545241', padding: '0.375rem 1rem 0 0' }}
          />
          <Label
            value={`AGT: ${selectedFleet.agencyId}`}
            customStyles={{ fontSize: '0.75rem', color: '#545241', padding: '0.375rem 1rem 0 0' }}
          />
          <Label
            value={`Vermittler: ${selectedFleet.brokerName}`}
            customStyles={{ fontSize: '0.75rem', color: '#545241', padding: '0.375rem 1rem 0 0' }}
          />
          <Label
            value={`ZW: ${selectedFleet.fleetPaymentText}`}
            customStyles={{ fontSize: '0.75rem', color: '#545241', padding: '0.375rem 1rem 0 0' }}
          />
        </Box>
      </Box>
      {history.location.pathname.includes('/notes') ? null : (
        <div className={classes.newProposalContainer}>
          <Button
            startIcon={AddIcons()}
            clickHandler={() => {
              history.push(`${CALCULATION_PAGE}/${selectedFleet.id}/`)
            }}
            size='large'
            color='primary'
            height='40px'
          >
            {translateItem('newCalculation')}
          </Button>
        </div>
      )}
    </Box>
  )
}
