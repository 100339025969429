import React from 'react'
import { CalculationVersionsContainer } from '../../../containers/fleet-details/calculations-versions-container'

export function CalculationVersions(props: any) {
  const { params } = props
  return (
    <React.Fragment>
      <CalculationVersionsContainer params={params} />
    </React.Fragment>
  )
}
