import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { AppRoutes } from './pages/app-routes'
import { store } from './store'

import { theme } from './styles/theme'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Box } from '@material-ui/core'

export const App: React.FC = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            height='100%'
            bgcolor='#EBE6D8'
            style={{ overflowX: 'hidden' }}
          >
            <AppRoutes />
          </Box>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  )
}
