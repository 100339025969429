export const NoteActionTypes = {
  GET_NOTE: 'GET_NOTE',
  LOAD_NOTE: 'LOAD_NOTE',
  SAVE_NOTE: 'SAVE_NOTE',
  UPDATE_NOTE: 'UPDATE_NOTE',
}

export const NoteActions = {
  getNote: (mnr: string) => ({ payload: mnr, type: NoteActionTypes.GET_NOTE }),
  saveNote: (mnr: string, note: string) => ({
    payload: { fleetId: mnr, note: note },
    type: NoteActionTypes.SAVE_NOTE,
  }),
  updateNote: (commentId: string, note: string) => ({
    payload: { commentId: commentId, note: note },
    type: NoteActionTypes.UPDATE_NOTE,
  }),
  loadNote: (fleetNotes: string) => ({
    type: NoteActionTypes.LOAD_NOTE,
    payload: fleetNotes,
  }),
}
