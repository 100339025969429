import React from 'react'
import { SelectPanelView } from '../../views/summary/selectPanelView'

export function SelectPanel(props: any) {
  const { details, handleChange } = props
  return (
    <SelectPanelView
      details={details}
      handleChange={(value: any, key: string) => handleChange(value, key)}
    />
  )
}
