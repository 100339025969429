import { Box } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Label } from '../../components/label/label'
import { SimpleTable } from '../../components/simpleTable/simple-table'
import { SingleSelect } from '../../components/singleSelect/singleSelect'
import CustomizedSwitches from '../../components/switch/switch'
import { Deductable, ObservationPeriod, Simulation } from '../../reducers/proposal/proposal'
import { useChangeDeductible } from '../../reducers/proposal/proposal-hooks'
import { NumberInput } from '../../components/numberInput/numberInput'

export interface ClaimFilterContainerProps {
  deductibles: Deductable[]
  simulations: Simulation[]
  ratioOfReserve: number
  surcharge: number
  currentCarpark: number
  provisionCut: boolean
  observationPeriod: ObservationPeriod
  onReserveRatioChange: (value: number) => void
  onObservationPeriodChange: (value: string) => void
  onSurchargeChange: (value: number) => void
  onProvisionCutChange: (value: boolean) => void
  onCurrentCarparkChange: (value: number) => void
}

const useStyles = makeStyles({
  editableInput: {
    '& input[type="text"]': {
      textAlign: 'center',
      paddingLeft: 0,
    },
  },
})

const ratingReserveOptions = [
  { label: '0%', value: '0' },
  { label: '80%', value: 80 },
  { label: '85%', value: 85 },
  { label: '90%', value: 90 },
  { label: '95%', value: 95 },
  { label: '100%', value: 100 },
]

const extraChargeOptions = [
  { label: '0%', value: '0' },
  { label: '7%', value: 7 },
  { label: '10%', value: 10 },
  { label: '13%', value: 13 },
  { label: '20%', value: 20 },
  { label: '25%', value: 25 },
]

export function ClaimFilterContainer(props: ClaimFilterContainerProps) {
  const classes = useStyles()
  const {
    deductibles,
    observationPeriod,
    onObservationPeriodChange,
    onProvisionCutChange,
    onReserveRatioChange,
    onSurchargeChange,
    provisionCut,
    ratioOfReserve,
    simulations,
    surcharge,
    currentCarpark,
    onCurrentCarparkChange,
  } = props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_currentDeductibe, deductible1, deductible2] = deductibles
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, simulation1, simulation2] = simulations
  const { t } = useTranslation()
  const changeDeductible = useChangeDeductible()
  const changeFullCoverDeductible = (value: string, deductible: Deductable) => {
    changeDeductible({ ...deductible, fullCover: parseFloat(value) || 0 })
  }
  const changePartCoverDeductable = (value: string, deductible: Deductable) => {
    changeDeductible({ ...deductible, partCover: parseFloat(value) || 0 })
  }
  const getObsevationPeriod = () => {
    const currentYear = new Date().getFullYear()
    const prevYear = currentYear - 1
    const endYear = currentYear - 6

    const years = []
    for (let v = currentYear; v > endYear; v--) {
      for (let i = v; i > endYear; i--) {
        if (v === currentYear || v === prevYear) {
          const label = v === i ? `${v}` : `${v}-${i}`
          years.push({ label, value: label })
        }
      }
    }

    return years
  }
  const observationPeriodOptions = getObsevationPeriod()

  const claimHistoryHeadData = [
    { id: 'year', label: t('years') },
    { id: 'noOfClaims', label: t('claims'), numeric: true, precision: 0 },
    {
      id: 'averageClaim',
      label: `\u00D8 - ${t('claim')}`,
      numeric: true,
      suffix: ' \u20AC',
      precision: 0,
    },
    { id: 'claimPerRisk', label: t('cpr'), numeric: true, precision: 0 },
  ]
  const { startYear, endYear } = observationPeriod
  return (
    <Box display='flex' flex='auto' flexDirection='row' height='100%'>
      <Box display='flex' flex='auto' flexDirection='column' height='100%' width='34%'>
        <Box
          display='flex'
          flexDirection='row'
          width='87%'
          paddingTop='5px'
          flex='auto'
          justifyContent='space-between'
        >
          <Box width='24%'>
            <Label
              value={t('observationPeriod').toUpperCase()}
              customStyles={{ fontSize: '0.63rem', fontWeight: 'bold' }}
            />
            <SingleSelect
              options={observationPeriodOptions}
              onChangeHandler={onObservationPeriodChange}
              defaultValue={startYear === endYear ? `${startYear}` : `${endYear}-${startYear}`}
            />
          </Box>
          <Box width='18%'>
            <Label
              value={t('ratingReserves').toUpperCase()}
              customStyles={{ fontSize: '0.63rem', fontWeight: 'bold' }}
            />
            <SingleSelect
              options={ratingReserveOptions}
              onChangeHandler={(value: string) => onReserveRatioChange(parseInt(value))}
              defaultValue={ratioOfReserve.toString()}
            />
          </Box>
          <Box width='18%'>
            <Label
              value={t('extraCharge').toUpperCase()}
              customStyles={{ fontSize: '0.63rem', fontWeight: 'bold' }}
            />
            <SingleSelect
              options={extraChargeOptions}
              defaultValue={surcharge.toString()}
              onChangeHandler={(value: string) => onSurchargeChange(parseInt(value))}
            />
          </Box>
          <Box width='12%'>
            <Label
              value={t('provisionsCut').toUpperCase()}
              customStyles={{ fontSize: '0.63rem', fontWeight: 'bold' }}
            />
            <CustomizedSwitches changeHandler={onProvisionCutChange} value={provisionCut} />
          </Box>
        </Box>
        <Box width='21%' paddingTop='10px'>
          <Label value='FUHRPARK' customStyles={{ fontSize: '0.63rem', fontWeight: 'bold' }} />
          <NumberInput
            className={classes.editableInput}
            value={currentCarpark}
            onBlur={(value: string) => onCurrentCarparkChange(parseInt(value))}
            size='medium'
            min={10}
          />
        </Box>
      </Box>
      <Box display='flex' flex='auto' flexDirection='row' height='100%'>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          flex='auto'
          paddingRight='24px'
        >
          <Box width='40%' display='flex' paddingLeft='1rem' flexDirection='column'>
            <Box alignItems='center' display='flex'>
              <Box paddingRight='0.5rem' fontSize='0.63rem' fontWeight='bold'>
                SB
              </Box>
              <Box width='50%' paddingRight='5px'>
                <NumberInput
                  className={classes.editableInput}
                  value={deductible1.fullCover}
                  onEnter={(value: string) => changeFullCoverDeductible(value, deductible1)}
                  onBlur={(value: string) => changeFullCoverDeductible(value, deductible1)}
                  size='small'
                />
              </Box>
              {'/'}
              <Box width='50%' paddingLeft='5px'>
                <NumberInput
                  className={classes.editableInput}
                  value={deductible1.partCover}
                  onEnter={(value: string) => changePartCoverDeductable(value, deductible1)}
                  onBlur={(value: string) => changePartCoverDeductable(value, deductible1)}
                  size='small'
                />
              </Box>
            </Box>
          </Box>
          <SimpleTable
            rows={(simulation1 && simulation1.byYear) || []}
            headCells={claimHistoryHeadData}
          />
        </Box>
        <Box display='flex' flexDirection='column' alignItems='center' flex='auto'>
          <Box width='40%' display='flex' paddingLeft='1rem' flexDirection='column'>
            <Box alignItems='center' display='flex'>
              <Box paddingRight='0.5rem' fontSize='0.63rem' fontWeight='bold'>
                SB
              </Box>
              <Box width='50%' paddingRight='5px'>
                <NumberInput
                  className={classes.editableInput}
                  value={deductible2.fullCover}
                  onEnter={(value: string) => changeFullCoverDeductible(value, deductible2)}
                  onBlur={(value: string) => changeFullCoverDeductible(value, deductible2)}
                />
              </Box>
              {'/'}
              <Box width='50%' paddingLeft='5px'>
                <NumberInput
                  className={classes.editableInput}
                  value={deductible2.partCover}
                  onEnter={(value: string) => changePartCoverDeductable(value, deductible2)}
                  onBlur={(value: string) => changePartCoverDeductable(value, deductible2)}
                />
              </Box>
            </Box>
          </Box>
          <SimpleTable
            rows={(simulation2 && simulation2.byYear) || []}
            headCells={claimHistoryHeadData}
          />
        </Box>
      </Box>
    </Box>
  )
}
