import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectProgress } from 'redux-saga-tools'
import { AppState } from '../../state/app-state'
import {
  ClaimSummary,
  Deductable,
  Proposal,
  ProposalStatus,
  ProposalDetails,
  DeductableType,
} from './proposal'
import { ProposalActions, ProposalActionTypes } from './proposal-actions'

export function useCurrentProposal() {
  const proposal = useSelector(({ proposals }: AppState) =>
    proposals ? proposals.currentProposal : undefined,
  )
  return proposal
}

export function useClaimData() {
  const claimData = useSelector(({ proposals }: AppState) => (proposals ? proposals.claimData : []))
  return claimData
}

export function useCreateProposal() {
  const dispatch = useDispatch()
  return (fleetId: string) => dispatch(ProposalActions.createProposal(fleetId))
}

export function useFetchClaimHistoryData() {
  const dispatch = useDispatch()
  return (fleetId: string) => dispatch(ProposalActions.fetchClaimHistory(fleetId))
}

export function useFetchDataFromSAS(): [
  (fleetId: string) => void,
  { loading: boolean; error: any },
] {
  const { inProgress = false, error = '' } =
    useSelector(
      state =>
        selectProgress(state, ProposalActionTypes.CREATE_NEW_PROPOSAL) ||
        selectProgress(state, ProposalActionTypes.FETCH_CLAIM_DETAILS),
    ) || {}
  const dispatch = useDispatch()
  const fetchClaimDetails = (fleetId: string) => {
    dispatch(ProposalActions.fetchClaimDetails(fleetId))
  }
  return [fetchClaimDetails, { loading: inProgress, error }]
}

export function useUpdateClaimSummary() {
  const dispatch = useDispatch()
  return (summary: ClaimSummary) => dispatch(ProposalActions.updateClaimSummary(summary))
}

export function useChangeObservationPeriod() {
  const dispatch = useDispatch()
  return (start: number, end: number) =>
    dispatch(ProposalActions.changeObservationPeriod(start, end))
}

export function useChangeReseveRatio() {
  const dispatch = useDispatch()
  return (value: number) => dispatch(ProposalActions.changeReseveRatio(value))
}

export function useChangeSurcharge() {
  const dispatch = useDispatch()
  return (value: number) => dispatch(ProposalActions.changeSurcharge(value))
}

export function useChangeCurrentCarpark() {
  const dispatch = useDispatch()
  return (value: number) => dispatch(ProposalActions.changeCurrrentCarpark(value))
}

export function useApplyProvisionCut() {
  const dispatch = useDispatch()
  return (hasProvisionCut: boolean) => dispatch(ProposalActions.applyProvisionCut(hasProvisionCut))
}

export function useChangeRatioOfClaims() {
  const dispatch = useDispatch()
  return (value: number) => dispatch(ProposalActions.changeRatioOfClaims(value))
}

export function useProposals(id: string): [Proposal[], { loading: boolean; error?: string }] {
  const dispatch = useDispatch()
  const activeProposals = useSelector(({ proposals }: AppState) =>
    proposals ? proposals.activeProposals : [],
  )
  const { inProgress = false, error } =
    useSelector(state => selectProgress(state, ProposalActionTypes.LIST_PROPOSALS)) || {}
  useEffect(() => {
    dispatch(ProposalActions.listProposals(id))
  }, [dispatch])
  return [activeProposals as any, { loading: inProgress, error }]
}

export function useSelectProposal(): (id: string) => void {
  const dispatch = useDispatch()
  const selectProposal = (id: string) => {
    dispatch(ProposalActions.selectProposal(id))
  }
  return selectProposal
}

export function useClearCurrentProposal(): () => void {
  const dispatch = useDispatch()
  const clearProposal = () => {
    dispatch(ProposalActions.clearCurrentProposal())
  }
  return clearProposal
}

export function useChangeDeductible() {
  const dispatch = useDispatch()
  return (deductible: Deductable) => dispatch(ProposalActions.changeDeductible(deductible))
}

export function useChangeStatus() {
  const dispatch = useDispatch()
  return (status: ProposalStatus) => dispatch(ProposalActions.changeStatus(status))
}

export function useSaveProposal(): [
  (proposal: Proposal) => void,
  { saveProposalActionloading: boolean; saveProposalAPIerror: any },
] {
  const { inProgress = false, error = '' } =
    useSelector(state => selectProgress(state, ProposalActionTypes.SAVE_PROPOSAL)) || {}
  const dispatch = useDispatch()
  const saveProposal = (proposal: Proposal) => {
    dispatch(ProposalActions.saveProposal(proposal))
  }
  return [saveProposal, { saveProposalActionloading: inProgress, saveProposalAPIerror: error }]
}

export function useGetProposalById(id: string, fleetId: string) {
  const dispatch = useDispatch()
  const proposal = useSelector(
    (state: AppState) =>
      (id && state.proposals && state.proposals.byId && state.proposals.byId[id]) || null,
  )
  useEffect(() => {
    if (id.trim().length > 0) {
      dispatch(ProposalActions.getProposalById(id, fleetId))
    }
  }, [id, dispatch])

  return [proposal]
}

export function useSaveCalculationComments(): (comment: string) => void {
  const dispatch = useDispatch()
  const saveCalculationComment = (comment: string) => {
    dispatch(ProposalActions.saveComment(comment))
  }
  return saveCalculationComment
}

export function useChangeDetails() {
  const dispatch = useDispatch()
  return (details: ProposalDetails) => {
    dispatch(ProposalActions.changeDetails(details))
  }
}

export function useChangeTemplateData() {
  const dispatch = useDispatch()
  return (data: ProposalDetails) => {
    dispatch(ProposalActions.changeTemplateData(data))
  }
}

export function useIncludeDeductibleInProposal() {
  const dispatch = useDispatch()
  const includeDeductible = (id: DeductableType, selected: boolean) => {
    dispatch(ProposalActions.toggleDeductible(id, selected))
  }
  return includeDeductible
}
