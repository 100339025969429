import React from 'react'
import { useTranslation } from 'react-i18next'

export function NumberText({
  children,
  precision = 2,
  minPrecision = 0,
}: {
  children: number
  precision?: number
  minPrecision?: number
}) {
  const { i18n } = useTranslation()
  return (
    <>
      {!isNaN(children) && children !== null
        ? children.toLocaleString(i18n.language, {
            maximumFractionDigits: precision,
            minimumFractionDigits: minPrecision || 0,
          })
        : '-'}
    </>
  )
}
