import { PROD } from 'src/constants/routes'

export function isProd(url = window.location.href): boolean {
  return url.includes(PROD)
}

export function printLogs(msg1: string, msg2?: any): void {
  if (!isProd()) {
    console.log(msg1, msg2)
  }
}
