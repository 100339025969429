import React from 'react'
import { Box } from '@material-ui/core'
import { Logo } from '../../components/logo/logo'
import './styles.css'

export function NoAccessPage() {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      width='100%'
      height='100vh'
    >
      <Box marginBottom='1rem' display='block'>
        <Logo width='160px' height='67px' />
      </Box>
      <div className='content'>
        Sie haben keinen Zugriff auf diese Anwendung <br /> You don&apos;t have access to this
        application
      </div>
    </Box>
  )
}
