import { createStyles, makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/styles'
import React, { useState, KeyboardEvent, useEffect } from 'react'

interface NumberInputProps {
  placeholder?: string
  required?: boolean
  classes?: any
  value?: number
  showClearIcon?: boolean
  className?: string
  onChange?: (event: any, name: string | undefined) => void
  onBlur?: (value: string) => void
  onEnter?: (value: string) => void
  name?: string
  size?: string
  min?: number
  max?: number
  id?: string
}

const InputField = withStyles({
  root: {
    borderRadius: '4px 4px 0 0',
    backgroundColor: '#F8F8F8',
    fontSize: '12px',
    '& > div:hover:before': {
      borderBottom: '2px solid #8E0038 !important',
    },
  },
})(TextField)

const inputContainerStyle = makeStyles(() =>
  createStyles({
    inputLarge: {
      '& input[type="text"]': {
        height: '27px',
        paddingLeft: '10px',
      },
    },
    inputMedium: {
      '& input[type="text"]': {
        height: '20px',
      },
    },
    inputSmall: {
      '& input[type="text"]': {
        height: '11px',
      },
    },
  }),
)

export function NumberInput(props: NumberInputProps) {
  const {
    placeholder,
    required = false,
    value,
    className = '',
    size,
    onChange,
    onBlur,
    onEnter,
    name,
    min = 0,
    max,
    id,
  } = props
  const [inputValue, handleInput] = useState<any>(value)
  const classes = inputContainerStyle()

  useEffect(() => {
    handleInput(value)
  }, [value])

  function handleOnChange(e: any) {
    const typedValue = e.target.value
    const patt = new RegExp(/^-?[0-9]*$/)
    const res = patt.test(typedValue.toString())
    if (!res) {
      return
    }
    if (onChange && inputValue !== typedValue) {
      onChange(e, name)
    }
    handleInput(typedValue)
  }

  function onblur(e: any) {
    let value = e.target.value
    if (typeof min !== 'undefined' && (!value || parseInt(value) < min)) {
      value = min
      handleInput(min)
    }
    if (typeof max !== 'undefined' && value && parseInt(value) > max) {
      value = max
      handleInput(max)
    }
    if (onBlur) {
      onBlur(value)
    }
  }

  function handleKeyPress(e: KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter') {
      let value = inputValue
      if (typeof max !== 'undefined' && value && parseInt(inputValue) > max) {
        value = max
        handleInput(max)
      }
      onEnter && onEnter(value.toString())
    }
  }

  function getButtonSize() {
    let returnClass = ``
    switch (size) {
      case 'small':
        returnClass = classes.inputSmall
        break
      case 'medium':
        returnClass = classes.inputMedium
        break
      case 'large':
        returnClass = classes.inputLarge
        break
      default:
        returnClass = classes.inputSmall
        break
    }
    return returnClass
  }

  function getClassnames() {
    return `custom-input ${className} ${getButtonSize()}`
  }

  return (
    <InputField
      id={id}
      type='text'
      placeholder={placeholder}
      required={required}
      fullWidth
      onChange={handleOnChange}
      onKeyPress={handleKeyPress}
      onBlur={onblur}
      value={inputValue}
      className={getClassnames()}
      spellCheck={false}
    />
  )
}
