import { makeStyles, createStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/styles'
import React, { useState, KeyboardEvent, useEffect } from 'react'

interface TextFieldProps {
  placeholder?: string
  required?: boolean
  classes?: any
  value?: string
  showCloseIcon?: boolean
  className?: string
  handleChange?: (event: any, name: string | undefined) => void
  onBlur?: (value: string) => void
  onEnter?: (value: string) => void
  name?: string
  size?: string
  disabled?: boolean
  handleCustomKeyPress?: (event: any) => void
  id?: string
}

const InputField = withStyles({
  root: {
    borderRadius: '4px 4px 0 0',
    backgroundColor: '#F8F8F8',
    fontSize: '12px',
    '& input[type="text"]': {
      paddingLeft: '10px',
    },
    '& > div:hover:before': {
      borderBottom: '2px solid #8E0038 !important',
    },
  },
})(TextField)

const inputContainerStyle = makeStyles(() =>
  createStyles({
    inputLarge: {
      '& input[type="text"]': {
        height: '27px',
      },
    },
    inputMedium: {
      '& input[type="text"]': {
        height: '20px',
      },
    },
    inputSmall: {
      '& input[type="text"]': {
        height: '11px',
      },
    },
  }),
)

export function TextFieldComponent(props: TextFieldProps) {
  const {
    placeholder,
    required = false,
    value = '',
    showCloseIcon = false,
    className = '',
    size,
    handleChange,
    onBlur,
    onEnter,
    name,
    handleCustomKeyPress,
    disabled = false,
    id,
  } = props
  const [inputValue, handleInput] = useState<string>(value)
  const classes = inputContainerStyle()
  useEffect(() => {
    handleInput(value)
  }, [value])

  function handleOnBlur() {
    onBlur && onBlur(inputValue.toString())
  }
  function handleOnChange(e: any) {
    if (handleChange && inputValue !== e.target.value) {
      handleChange(e.target.value, name)
    }
    handleInput(e.target.value)
  }
  function handleKeyPress(e: KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter') onEnter && onEnter(inputValue.toString())
    handleCustomKeyPress && handleCustomKeyPress(e)
  }
  function handleClick() {
    handleInput('')
    handleChange && handleChange('', name)
  }

  function getButtonSize() {
    let returnClass = ``
    switch (size) {
      case 'small':
        returnClass = classes.inputSmall
        break
      case 'medium':
        returnClass = classes.inputMedium
        break
      case 'large':
        returnClass = classes.inputLarge
        break
      default:
        returnClass = classes.inputSmall
        break
    }
    return returnClass
  }

  function getClassnames() {
    return `custom-input ${className} ${getButtonSize()}`
  }

  return (
    <InputField
      id={id}
      placeholder={placeholder}
      required={required}
      fullWidth
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      onKeyPress={handleKeyPress}
      value={inputValue}
      className={getClassnames()}
      spellCheck={false}
      disabled={disabled}
      InputProps={{
        endAdornment: showCloseIcon ? <CloseIcon onClick={handleClick} /> : null,
      }}
    />
  )
}
