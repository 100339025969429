import { Box, Checkbox } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { withStyles } from '@material-ui/styles'
import React, { useState, useEffect } from 'react'
import { ReactComponent as ArrowIcon } from '../../images/arrowIcon.svg'

interface FilterOptions {
  label: string
  id?: number
}

interface MultiSelectProps<FilterOptions> {
  options: FilterOptions[]
  label?: string
  onChangeHandler?: Function
  customLabel?: string
  defaultValue?: FilterOptions[]
  action?: string
}

const CustomTextField = withStyles({
  root: {
    borderRadius: '4px 4px 0 0',
    backgroundColor: '#F8F8F8',
    fontSize: '12px',
  },
})(TextField)

const CustomCheckBox = withStyles({
  root: {
    color: '#8E0038',
    '&$checked': {
      color: '#8E0038',
    },
  },
  checked: {},
})(Checkbox)

const AutocompleteSelect = withStyles({
  option: {
    borderBottom: 'none',
    fontSize: '0.75rem',
  },
  paper: {
    width: '100%',
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  popper: {
    borderRadius: '3px',
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '190px',
  },
  inputRoot: {
    flexWrap: 'nowrap',
    paddingTop: '13px',
    '&:hover:before': {
      borderBottom: '2px solid #8E0038 !important',
    },
  },
  tag: {
    backgroundColor: 'transparent',
    margin: '0px',
    display: 'none',
  },
  input: {
    minWidth: '0px !important',
    padding: '6px 0 !important',
  },
  clearIndicator: {
    padding: '0px',
  },
  clearIndicatorDirty: {
    display: 'none',
  },
})(Autocomplete as any)

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

export function MultiSelect(props: MultiSelectProps<FilterOptions>) {
  const {
    options,
    label,
    onChangeHandler,
    customLabel = 'All',
    defaultValue = [],
    action = 'active',
  } = props
  const [selectedCount, setSelectedCount] = useState(customLabel)
  const [selectedOption, setSelectedOption] = useState(defaultValue || options)
  const [filterOption, setFilterOption] = useState(options)
  const [value, setValue] = useState(defaultValue || options)

  function onClose() {
    let selectedLabel = ''
    if (options.length === selectedOption.length) {
      selectedLabel = customLabel
    } else if (selectedOption.length == 0) {
      selectedLabel = customLabel
    } else if (selectedOption.length === 1) {
      selectedLabel = selectedOption[0].label
    } else if (selectedOption.length) {
      selectedLabel = `Multiple(${selectedOption.length})`
    }
    setSelectedCount(selectedLabel)
    const selected = selectedOption.map(function (item: any) {
      return item['label']
    })
    onChangeHandler && onChangeHandler(selected, selectedOption)
  }

  useEffect(() => {
    if (defaultValue.length == 0 || defaultValue.length == options.length) {
      setSelectedCount(customLabel)
      setValue(defaultValue)
      setSelectedOption(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    if (action == 'reset') {
      setSelectedCount(customLabel)
      setValue(defaultValue)
      setSelectedOption(defaultValue)
    }
  }, [action])

  useEffect(() => {
    setFilterOption(options)
  }, [options])

  function onChange(event: any) {
    let temp = []
    if (selectedOption.some((e: FilterOptions) => e.label === event.currentTarget.textContent)) {
      temp = selectedOption.filter((item: any) => item.label !== event.currentTarget.textContent)
    } else {
      const selectedObj = options.filter(
        (item: any) => item.label == event.currentTarget.textContent,
      )
      temp = [...selectedOption, ...selectedObj]
    }
    setSelectedOption(temp)
    setValue(temp)
  }

  function onOpen() {
    setSelectedCount('')
  }

  function checkSelected(option: FilterOptions) {
    if (value.some((e: FilterOptions) => e.label === option.label)) {
      return true
    }
    return false
  }

  return (
    <Box position='relative'>
      <AutocompleteSelect
        options={filterOption}
        getOptionLabel={(option: FilterOptions) => option.label}
        onChange={(event: any) => onChange(event)}
        defaultValue={value}
        multiple
        onClose={onClose}
        onOpen={onOpen}
        disableCloseOnSelect
        renderInput={(params: any) => <CustomTextField {...params} fullWidth label={label} />}
        popupIcon={<ArrowIcon width='13px' style={{ margin: '0.5rem' }} />}
        renderOption={(option: FilterOptions, { selected: _selected }: { selected: any }) => (
          <React.Fragment>
            <CustomCheckBox
              icon={icon}
              color='default'
              checkedIcon={checkedIcon}
              style={{ marginRight: 8, fontSize: 12 }}
              checked={checkSelected(option)}
            />
            {option.label}
          </React.Fragment>
        )}
      />
      <Box
        bgcolor='transparent'
        color='default'
        position='absolute'
        top='37%'
        left='5%'
        zIndex={1}
        fontSize='0.7rem'
        textOverflow='ellipsis'
        overflow='hidden'
        whiteSpace='nowrap'
        width='70%'
      >
        {selectedCount}
      </Box>
    </Box>
  )
}
