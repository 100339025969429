import React from 'react'

export function Logo(props: any) {
  const { width, height } = props
  return (
    <svg width={width} height={height} viewBox='0 0 72 34' version='1.1'>
      <title>11CF0BD5-B237-4399-9653-1CA80D65947E</title>
      <desc>Created with sketchtool.</desc>
      <g id='main_flow' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='fleets-(old_version)' transform='translate(-40.000000, -11.000000)'>
          <g id='Header'>
            <g id='KFT_logo' transform='translate(40.000000, 11.000000)'>
              <g>
                <text
                  dx='1'
                  id='Flotten-Rechner'
                  fontFamily='FSMeWeb, FS Me Web'
                  fontSize='6'
                  fontWeight='normal'
                  letterSpacing='0.35'
                  fill='#333333'
                >
                  <tspan x='0' y='33'>
                    FLOTTEN-RENEWAL-TOOL
                  </tspan>
                </text>
                <g id='ergo_logo' fill='#BF1528' fillRule='nonzero'>
                  <path
                    d='M71.924,11.208 C71.924,17.3146667 67.8546667,21.7 61.8693333,21.7 C56.212,21.7 52.0066667,17.6746667 52.0066667,11.208 C52.0066667,4.26133333 57,0.688 62.06,0.688 C66.912,0.688 71.924,4.076 71.924,11.208 Z M67.028,11.208 C67.028,8.29066667 65.6173333,4.90666667 61.952,4.90666667 C58.628,4.90666667 56.8773333,7.76533333 56.8773333,11.208 C56.8773333,15.0746667 59.0346667,17.5093333 61.952,17.5093333 C65.6173333,17.5093333 67.028,14.1613333 67.028,11.208 Z'
                    id='Shape'
                  ></path>
                  <path
                    d='M50.2133333,19.7906667 C49.0146667,20.5933333 46.4946667,21.6813333 43.0573333,21.6813333 C36.304,21.6813333 32.9666667,17.0213333 32.9666667,11.328 C32.9666667,4.91866667 37.5013333,0.688 43.428,0.688 C46.3626667,0.688 48.2466667,1.17733333 49.916,1.99466667 L48.2053333,5.944 C46.932,5.412 45.4626667,5.09733333 43.724,5.09733333 C39.44,5.09733333 37.9866667,7.66 37.9866667,11.1986667 C37.9866667,15.0186667 39.9373333,17.4946667 43.336,17.4946667 C44.568,17.4946667 45.4906667,17.232 45.776,17.0466667 L45.776,13.4933333 L42.3253333,13.4933333 L42.3306667,9.472 L50.2106667,9.472 L50.2133333,19.7906667 Z'
                    id='Path'
                  ></path>
                  <path
                    d='M27.1453333,21.3493333 C26.4746667,18.9013333 25.3506667,17.0426667 24.4253333,15.852 C23.6453333,14.848 23.036,14.6333333 23.036,14.6333333 C22.404,14.652 21.9706667,14.6493333 21.392,14.6226667 L21.392,21.3493333 L16.68,21.3493333 L16.68,1.22666667 C17.672,1.06666667 18.384,0.98 19.4253333,0.882666667 C20.3853333,0.793333333 21.4693333,0.734666667 22.6706667,0.734666667 C24.26,0.734666667 25.6106667,0.910666667 26.72,1.26133333 C27.8293333,1.612 28.7346667,2.09333333 29.4373333,2.704 C30.1586667,3.31466667 30.676,4.072 30.9906667,4.904 C31.324,5.716 31.4906667,6.58666667 31.4906667,7.51066667 C31.4906667,8.73066667 31.192,9.91333333 30.5426667,10.924 C29.9666667,11.8226667 28.9986667,12.7413333 27.996,13.3746667 L27.996,13.4626667 C30.2373333,14.488 31.5053333,17.7853333 32.736,21.348 L27.1453333,21.348 L27.1453333,21.3493333 Z M26.7573333,7.62666667 C26.7573333,6.776 26.452,6.09466667 25.8413333,5.63333333 C25.2306667,5.152 24.392,4.916 23.3746667,4.9 C23.2613333,4.89866667 22.824,4.90266667 22.56,4.912 C22.3013333,4.91866667 21.6093333,4.98133333 21.392,5.02133333 L21.392,10.8186667 C21.7066667,10.856 22.3586667,10.8866667 22.8893333,10.8866667 C24.0173333,10.8866667 25.0666667,10.576 25.7333333,10.0586667 C26.4,9.52133333 26.7573333,8.66266667 26.7573333,7.62666667 Z'
                    id='Shape'
                  ></path>
                  <path
                    d='M0.113333333,16.5306667 L0.113333333,1.02133333 L13.96,1.02133333 L13.9626667,5.34666667 L4.828,5.34666667 L4.828,8.82666667 L12.5586667,8.82666667 L12.564,12.932 L4.828,12.932 L4.828,15.1066667 C4.828,16.472 5.632,17.0506667 6.67733333,17.0506667 C8.80133333,17.0506667 14.336,17.0493333 14.336,17.0493333 L14.3413333,21.3493333 L4.89466667,21.3493333 C1.35866667,21.3493333 0.113333333,19.716 0.113333333,16.5306667 Z'
                    id='Path'
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
