import React from 'react'
import { Box } from '@material-ui/core'

import envLink, { SAS_REPORT_URL } from '../../utils/envLink'
import { SAS_REPORT_URI } from '../../constants/routes'

export const OverviewEmbed = React.memo(() => {
  return (
    <Box alignItems='center' justifyContent='center' width='100%' minHeight='650px'>
      <sas-report-object
        authenticationType='credentials'
        url={envLink(SAS_REPORT_URL)}
        reportUri={SAS_REPORT_URI}
        objectName='ve3928'
        id='sas-report-ve3928-overview'
        style={{ width: '100%', height: '650px' }}
      ></sas-report-object>
    </Box>
  )
})
