import { all } from 'redux-saga/effects'
import { userSagas } from './user/user-saga'
import { reminderSagas } from './reminder/reminder-saga'
import { fleetSagas } from './fleet/fleet-saga'
import { deductableSagas } from './deductable/deductable-saga'
import { proposalSagas } from './proposal/proposal-saga'
import { fleetSearchFiltersSaga } from './fleet-search-filters/fleet-search-filters-saga'
import { fleetNoteSagas } from './notes/notes-saga'
import { fleetCommentSagas } from './calculation-comments/calculation-comments-saga'

export function* sagas(): any {
  yield all([
    ...userSagas,
    ...reminderSagas,
    ...fleetSagas,
    ...proposalSagas,
    ...deductableSagas,
    ...fleetSearchFiltersSaga,
    ...fleetNoteSagas,
    ...fleetCommentSagas,
  ])
}
