import { createSaga } from 'redux-saga-tools'
import { call, put } from 'redux-saga/effects'
import {
  CalculationCommentActionTypes,
  CalculationCommentsActions,
} from './calculation-comments-action'
import { getComments, saveComments, updateComments } from './calculation-comments-service'
import { CalculationComments } from '../../state/calculation-comments'

function* getFleetComments(mnr: string): any {
  const fleetComments = yield call(getComments, mnr)
  yield put(CalculationCommentsActions.loadComment(fleetComments))
}

function* saveFleetComments(payload: CalculationComments) {
  yield call(saveComments, payload)
}

function* updateFleetComments(payload: CalculationComments) {
  yield call(updateComments, payload)
}

export const fleetCommentSagas = [
  createSaga(CalculationCommentActionTypes.GET_COMMENT, getFleetComments),
  createSaga(CalculationCommentActionTypes.SAVE_COMMENT, saveFleetComments),
  createSaga(CalculationCommentActionTypes.UPDATE_COMMENT, updateFleetComments),
]
