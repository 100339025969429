import { createStore, applyMiddleware, compose } from 'redux'
import { reducers } from './reducers'
import createSagaMiddleware from 'redux-saga'
import { sagas } from './reducers/sagas'

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware))

export const store = createStore(reducers, enhancer)
if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./reducers', () => store.replaceReducer(reducers))
}
sagaMiddleware.run(sagas as any)
