import React, { useEffect } from 'react'
import { Card } from '../../components/card/card'
import { Box, TablePagination, makeStyles, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ErrorIcon from '@material-ui/icons/Error'
import { DataTable, Column } from '../../components/data-table/data-table'
import { Link, useHistory } from 'react-router-dom'
import { FleetSearchResult } from '../../state/fleet'
import { NumberText } from '../../pages/calculation/claimHistoryTable/number-text'
import moment from 'moment'
import { FLEET_DETAILS_PAGE } from 'src/constants/routes'

const useStyle = makeStyles(theme => ({
  link: {
    color: '#333',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  progress: {
    // position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
    top: '80%',
    width: '1rem',
    opacity: '80%',
  },
  tableHead: {
    '& tr th': {
      paddingLeft: 0,
    },
  },
  errorMessage: {
    color: '#666666',
    fontSize: '16px',
  },
  customCell: {
    cursor: 'pointer',
  },
  width100: {
    width: '100px',
  },
}))

interface FleetList {
  isLoading?: boolean
  data?: FleetSearchResult[]
  error?: any
}

export function FleetList(props: FleetList) {
  const { isLoading, data = [], error } = props
  const classes = useStyle()
  const { t } = useTranslation()
  const history = useHistory()
  const currencySymbol = '\u20AC'
  const navigateToFleetDetails = (recordId: string) => {
    history.push(`${FLEET_DETAILS_PAGE}/${recordId}/overview`)
  }
  const renderClickableCell = (item: any, record: any, precision?: number, currency?: string) => (
    <div className={classes.customCell} onClick={() => navigateToFleetDetails(record.id)}>
      {(precision || precision == 0) && (item || item == 0) ? (
        <span>
          <NumberText precision={precision} minPrecision={precision}>
            {item}
          </NumberText>
          {currency}
        </span>
      ) : (
        item
      )}
    </div>
  )
  const columns: Column<any>[] = [
    {
      uniqueId: 'fleetName',
      key: 'fleetName',
      sortable: true,
      title: t('name'),
      render: (item: any, record: any) => renderClickableCell(item, record),
    },
    {
      uniqueId: 'id',
      key: 'id',
      sortable: true,
      title: t('fleetNo'),
      render: (item: any, record: any) => renderClickableCell(item, record),
    },
    {
      uniqueId: 'currentCarpark',
      key: 'currentCarpark',
      sortable: true,
      title: 'Fuhrpark',
      render: (item: any, record: any) => renderClickableCell(item, record),
    },
    {
      uniqueId: 'fleetSumClaimsCurrYear',
      key: 'fleetSumClaimsCurrYear',
      sortable: true,
      title: t('sq'),
      render: (item: any, record: any) => renderClickableCell(item, record, 1),
    },
    {
      uniqueId: 'fleetSumClaims5years',
      key: 'fleetSumClaims5years',
      sortable: true,
      title: t('sqabg'),
      render: (item: any, record: any) => renderClickableCell(item, record, 1),
    },
    {
      uniqueId: 'annualFee',
      key: 'annualFee',
      sortable: true,
      title: t('premium'),
      render: (item: any, record: any) => renderClickableCell(item, record, 0, currencySymbol),
    },
    {
      uniqueId: 'pur',
      key: 'pur',
      sortable: true,
      rowClassName: classes.width100,
      title: t('pur'),
      render: (item: any, record: any) => renderClickableCell(item, record),
    },
    {
      uniqueId: 'dueDate',
      key: 'dueDate',
      sortable: true,
      title: t('dueDate'),
      render: (item: any, record: any) => (
        <div className={classes.customCell} onClick={() => navigateToFleetDetails(record.id)}>
          {item ? moment.unix(item).format('DD/MM/YYYY') : ''}
        </div>
      ),
    },
    {
      uniqueId: 'brokerName',
      key: 'brokerName',
      sortable: true,
      title: t('brokerName'),
      render: (item: any, record: any) => renderClickableCell(item, record),
    },
    {
      uniqueId: 'status',
      key: 'status',
      sortable: true,
      title: t('status'),
      render: (item: any, record: any) =>
        item === 'Create' ? (
          <Link to={`/app/calculation/${record.id}`} className={classes.link}>
            <Box display='flex' alignItems='center' justifyContent='space-between'>
              {item ? t(item.toLowerCase()) : ''}
              <Box paddingLeft='1rem'>{record.watched ? <ErrorIcon color='primary' /> : null}</Box>
            </Box>{' '}
          </Link>
        ) : (
          <Link to={`/app/fleet-details/${record.id}/versions`} className={classes.link}>
            <Box display='flex' alignItems='center' justifyContent='space-between'>
              {item ? t(item.toLowerCase()) : ''}
              <Box paddingLeft='1rem'>{record.watched ? <ErrorIcon color='primary' /> : null}</Box>
            </Box>
          </Link>
        ),
    },
  ]

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(15)

  useEffect(() => {
    setPage(0)
  }, [data])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  return (
    <>
      {!error ? (
        <>
          {isLoading ? (
            <Card rounded={true}>
              <Box p={3} paddingBottom={0}>
                <Box height='200px' alignItems='center' display='flex'>
                  <CircularProgress className={classes.progress} />
                </Box>
              </Box>
            </Card>
          ) : !isLoading && (data.length == 0 || !data[0].id) ? (
            <Box display='flex' justifyContent='center' className={classes.errorMessage}>
              {t('noFleetsMatching')}
            </Box>
          ) : (
            <>
              <Card rounded={true}>
                <Box p={3} paddingBottom={0}>
                  <DataTable
                    columns={columns}
                    data={data}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    headClassName={classes.tableHead}
                  />
                  <TablePagination
                    onPageChange={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPageOptions={[15, 25, 50, 100]}
                    component='div'
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={t('fleetsPerPage')}
                    labelDisplayedRows={({ from, to, count }) =>
                      `${t('fleets')} ${from}-${to} of ${count}`
                    }
                    backIconButtonProps={{
                      'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'next page',
                    }}
                  />
                </Box>
              </Card>
            </>
          )}
        </>
      ) : (
        <Box display='flex' justifyContent='center' className={classes.errorMessage}>
          {t('failedToFetch')}
        </Box>
      )}
    </>
  )
}
