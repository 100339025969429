import React from 'react'
import { useFleets } from '../../reducers/fleet/fleet-hooks'
import { useAddSearchParameters } from 'src/reducers/fleet-search-parameters/search-parameters-hook'
import { FleetSearchContainer } from './fleet-search-container'
import { useAddSearchFilters } from 'src/reducers/fleet-search-filters/fleet-search-filters-hook'
import { FleetListContainer } from './fleet-list-container'
import { Container, Box } from '@material-ui/core'

export function FleetContainer() {
  const [fleetData] = useFleets()
  const [addSearchFilterParams, { loading, error }] = useAddSearchParameters()
  const [addSearchFilters]: any = useAddSearchFilters()
  return (
    <>
      <Container disableGutters={true}>
        <Box display='flex' flexDirection='column' width='100%' padding='1.5rem 0.5rem' flex='auto'>
          <FleetSearchContainer
            addSearchFilterParams={addSearchFilterParams}
            addSearchFilters={addSearchFilters}
          />
          <Box width='100%' paddingTop='2rem' paddingBottom='1rem'>
            <FleetListContainer loading={loading} fleetData={fleetData} error={error} />
          </Box>
        </Box>
      </Container>
    </>
  )
}
