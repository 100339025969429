import { useDispatch, useSelector } from 'react-redux'
import { SearchParameter } from '../../state/search-filter-params'
import { SearchParametersActions } from './search-parameters-actions'
import { AppState } from 'src/state/app-state'
import { selectProgress } from 'redux-saga-tools'
import { FleetActionTypes, FleetActions } from '../fleet/fleet-actions'
import { useEffect } from 'react'

export function useAddSearchParameters(): [
  (filterOptions: SearchParameter) => void,
  { loading: boolean; error: any },
] {
  const dispatch = useDispatch()
  const addSearchFilterParams = (filterOptions: SearchParameter) => {
    dispatch(SearchParametersActions.addSearchFilterParameters(filterOptions))
  }
  const searchParameters = useSelector((state: AppState) => state.searchParameters)
  const { inProgress = false, error = '' } =
    useSelector(state => selectProgress(state, FleetActionTypes.LIST_FLEETS)) || {}
  useEffect(() => {
    if (JSON.stringify(searchParameters) !== '{}') {
      dispatch(FleetActions.listFleets(searchParameters))
    }
  }, [searchParameters])
  return [addSearchFilterParams, { loading: inProgress, error }]
}
