import { Box, Container } from '@material-ui/core'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import React, { useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CalculationHeader } from '../../components/stickyHeader/calculation-sticky-header'
import { SelectPanel } from '../../containers/summary/selectPanel'
import { SummaryProposal } from '../../containers/summary/summaryProposal'
import { useSelectFleetById } from '../../reducers/fleet/fleet-hooks'
import {
  useChangeDetails,
  useChangeStatus,
  useCurrentProposal,
  useGetProposalById,
  useSaveProposal,
  useClaimData,
  useFetchClaimHistoryData,
} from '../../reducers/proposal/proposal-hooks'
import { CalculationComments } from '../calculation/calculation-comments'
import { pdfDownload } from './pdf-component'
import { useTranslation } from 'react-i18next'
import GetAppIcon from '@material-ui/icons/GetApp'
import { Button } from '../../components/button/button'

export function SummaryComponent() {
  const { fleetId = '', proposalId = '' } = useParams<Record<string, string>>()
  const currentProposal: any = useCurrentProposal()
  const fetchClaimData = useFetchClaimHistoryData()
  const claimData: any = useClaimData()
  const { i18n, t } = useTranslation()
  const fleet = useSelectFleetById(fleetId || '')
  const changeStatus = useChangeStatus()
  const changeDetails = useChangeDetails()
  const [saveProposal, { saveProposalActionloading, saveProposalAPIerror }] = useSaveProposal()
  const [proposal] = useGetProposalById(
    (currentProposal && currentProposal.id) || proposalId || '',
    fleetId,
  )
  const currentYear = moment(new Date()).format('YYYY')
  const prevYear = (parseInt(currentYear) - 1).toString()
  const formatNum = (value: number) => {
    return isFinite(value)
      ? value.toLocaleString(i18n.language, {
          maximumFractionDigits: 1,
          minimumFractionDigits: 1,
        })
      : '-'
  }
  const getClaimRatio = (year: string) => {
    const result = currentProposal.claimsByYear.findIndex((obj: any) => obj.year == year)
    return result ? currentProposal.claimsByYear[result].ratioOfClaim : 0
  }

  const calimsTableData = [
    {
      label: 'Zeitraum',
      value: 'Schadenquote',
    },
    {
      label: prevYear,
      value:
        currentProposal &&
        currentProposal.claimsByYear &&
        currentProposal.claimsByYear.indexOf(prevYear)
          ? `${formatNum(getClaimRatio(prevYear))}%`
          : 0,
    },
  ]
  const detailsInitialState = currentProposal
    ? {
        ...currentProposal.details,
        type: currentProposal.type,
        resultType: currentProposal.resultType,
        resultPercentage1: currentProposal.resultPercentage1,
        brStop: currentProposal.brStop,
      }
    : {}
  const [selectedRecipient, setRecipient] = useState('brokerAddress')
  const reducerAction = (state: any, action: any) => {
    switch (action.type) {
      case 'brokerAddress':
        return { ...state, brokerAddress: action.payload }
      case 'customerAddress':
        return { ...state, customerAddress: action.payload }
      case 'contactPerson':
        return { ...state, contactPerson: action.payload }
      case 'extension':
        return { ...state, extension: action.payload }
      case 'fax':
        return { ...state, fax: action.payload }
      case 'customerInfo':
        return { ...state, customerInfo: action.payload }
      case 'validFromDate':
        return {
          ...state,
          validFromDate: moment(action.payload).format('X'),
        }
      case 'proposalDate':
        return {
          ...state,
          proposalDate: moment(action.payload).format('X'),
        }
      case 'type':
        return { ...state, type: action.payload }
      case 'resultType':
        return { ...state, resultType: action.payload }
      case 'resultPercentage1':
        return { ...state, resultPercentage1: action.payload }
      case 'brStop':
        return { ...state, brStop: action.payload }
      case 'vk':
        return {
          ...state,
          changeInDeductible: {
            vk: action.payload,
            tk: state.changeInDeductible.tk,
          },
        }
      case 'tk':
        return {
          ...state,
          changeInDeductible: {
            vk: state.changeInDeductible.vk,
            tk: action.payload,
          },
        }
      case 'intro':
        return { ...state, intro: action.payload }
      case 'reason':
        return { ...state, reason: action.payload }
      case 'offer':
        return { ...state, offer: action.payload }
      case 'responseDateAndEnding':
        return { ...state, responseDateAndEnding: action.payload }
      case 'dueDateTs':
        return { ...state, dueDateTs: moment(action.payload).format('X') }
      case 'intialSet':
        return { ...state, ...action.payload }
      case 'purBasedYear':
        return { ...state, purBasedYear: action.payload }
      case 'purBasedClaimRatio':
        return { ...state, purBasedClaimRatio: action.payload }
      case 'selectedItem':
        return { ...state, selectedItem: action.payload }
      default:
        return state
    }
  }
  const [details, dispatch] = useReducer(reducerAction, detailsInitialState)
  React.useEffect(() => {
    dispatch({ type: 'intialSet', payload: detailsInitialState })
  }, [currentProposal && currentProposal.fleetId])
  React.useEffect(() => {
    changeDetails(details)
    setRecipient(details ? details.selectedItem : 'brokerAddress')
  }, [details])
  React.useEffect(() => {
    if (!claimData) {
      fetchClaimData(fleetId)
    }
  }, [fleetId])
  const pdfDownloadFn = () => {
    const currentYear = new Date().getFullYear()
    let renOptions =
      currentProposal && currentProposal.renewalOptions
        ? currentProposal.renewalOptions.slice(1, 3)
        : []
    renOptions = renOptions.filter(function (el: any) {
      return el.deductible.includeInProposal === true
    })
    const marginValue = renOptions.length > 0 ? 173 : 163
    const filteredClaimData =
      claimData && claimData.length
        ? claimData
            .filter((claim: any) => claim.claimYear === currentYear - 1)
            .sort(
              (x: any, y: any) =>
                parseInt(moment(x.claimDate, 'DD/MM/YYYY').format('X')) -
                parseInt(moment(y.claimDate, 'DD/MM/YYYY').format('X')),
            )
        : []
    const fleetName = fleet ? fleet.fleetName : '-'
    pdfDownload(
      details,
      selectedRecipient,
      calimsTableData,
      filteredClaimData,
      fleetName,
      marginValue,
    )
  }
  const contractType =
    currentProposal && currentProposal.details && currentProposal.details.fleetContractType
      ? currentProposal.details.fleetContractType
      : 'SO'
  const hasProposalModified = !isEqual(proposal, currentProposal)
  return (
    <>
      {currentProposal && fleet && (
        <CalculationHeader
          original={proposal}
          proposal={currentProposal}
          fleet={fleet}
          navigationHandler={pdfDownloadFn}
          onSaveProposal={saveProposal}
          saveEnabled={hasProposalModified}
          onChangeStatus={changeStatus}
          contractType={contractType}
          isLoading={saveProposalActionloading}
        />
      )}
      <Container disableGutters={true}>
        <Box display='flex' flexDirection='row' paddingTop='4rem'>
          <Box display='flex' flexDirection='column' width='65%' padding='2rem 0.6rem'>
            <Box>
              {saveProposalAPIerror !== '' && (
                <Box color='#807E6F' fontSize='0.75rem' paddingLeft='35%'>
                  {t(saveProposalAPIerror)}
                </Box>
              )}
              <SummaryProposal
                details={details}
                calimsTableData={calimsTableData}
                handleChange={(value: any, key: string) => {
                  dispatch({ type: key, payload: value })
                }}
                changeRecipient={(value: any) => {
                  setRecipient(value)
                  dispatch({ type: 'selectedItem', payload: value })
                }}
                selectedRecipient={selectedRecipient}
              />
            </Box>
            <Box padding='2rem 0rem'>
              <Button
                disabled={hasProposalModified}
                color={!hasProposalModified ? 'primary' : 'secondary'}
                variant='contained'
                size='medium'
                padding='0 30px'
                startIcon={<GetAppIcon />}
                clickHandler={pdfDownloadFn}
              >
                {t('downloadPdf')}
              </Button>
            </Box>
          </Box>
          <SelectPanel
            handleChange={(value: any, key: string) => dispatch({ type: key, payload: value })}
            details={details}
          />
        </Box>
      </Container>
      <CalculationComments />
    </>
  )
}
