import i18next from 'i18next'
import en from '../constants/lang/en.json'
import de from '../constants/lang/de.json'
import { initReactI18next } from 'react-i18next'

export default i18next.use(initReactI18next).init({
  whitelist: ['de', 'en'],
  fallbackLng: {
    default: ['de'],
  },
  lng: 'en',
  debug: true,
  resources: {
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
  },
})

i18next.changeLanguage('de')

export function translateItem(key: any): string {
  return i18next.t(key)
}
