import { SearchFilters } from '../../state/fleet-search-filters'

export const SearchFiltersActionTypes = {
  ADD_SEARCH_FILTERS: 'ADD_SEARCH_FILTERS',
  LOAD_SEARCH_FILTERS: 'LOAD_SEARCH_FILTERS',
}

export const SearchFiltersActions = {
  addSearchFilters: () => ({ type: SearchFiltersActionTypes.ADD_SEARCH_FILTERS }),
  loadSearchFilters: (searchFilters: SearchFilters) => ({
    type: SearchFiltersActionTypes.LOAD_SEARCH_FILTERS,
    payload: searchFilters,
  }),
}
