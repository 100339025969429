import React from 'react'
import { withStyles, createStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'

const CustomSwitch = withStyles(() =>
  createStyles({
    root: {
      width: '67px',
      height: '40px',
      padding: '13px 12px 11px',
      // marginLeft: '-12px',
      // overflow: 'visible',
    },
    thumb: {
      width: 24,
      height: 24,
    },
  }),
)(Switch)

interface CustomizedSwitchProps {
  changeHandler?: any
  value?: boolean
}

export default function CustomizedSwitches(props: CustomizedSwitchProps) {
  const { changeHandler, value = false } = props

  const handleChange = (evt: any) => {
    changeHandler(evt.target.checked)
  }

  return (
    <CustomSwitch
      color='primary'
      checked={value}
      onChange={(evt: any) => {
        handleChange(evt)
      }}
      value='checked'
    />
  )
}
