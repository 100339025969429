import { combineReducers } from 'redux'
import { userReducer } from './user/user-reducer'
import { progressReducer } from 'redux-saga-tools'
import { reminderReducer } from './reminder/reminder-reducer'
import { fleetReducer } from './fleet/fleet-reducer'
import { deductableReducer } from './deductable/deductable-reducer'
import { searchFilterParamsReducer } from './fleet-search-parameters/search-parameters-reducer'
import { searchFiltersReducer } from './fleet-search-filters/fleet-search-filters-reducer'
import { calculationCommentsReducer } from './calculation-comments/calculation-comments-reducer'
import { proposalReducer } from './proposal/proposal-reducer'
import { fleetNotesReducer } from './notes/notes-reducer'

export const reducers = combineReducers({
  users: userReducer,
  reminders: reminderReducer,
  deductables: deductableReducer,
  progress: progressReducer,
  fleets: fleetReducer,
  proposals: proposalReducer,
  searchParameters: searchFilterParamsReducer,
  searchFilters: searchFiltersReducer,
  calculationComments: calculationCommentsReducer,
  fleetNotes: fleetNotesReducer,
})
