export async function getSearchFilters(): Promise<any> {
  return fetch(
    `/api/proxy/?_program=%2FERGO+System%2FKRG%2FStored+Processes%2Freturn_values&_action=execute&req=fleet_searchfilters`,
  )
    .then(response => response.json())
    .then(response => response.body || [])
    .then(filters => {
      const ObjKeys: any = Object.keys(filters[0])
      const obj: any = {
        UWA: [],
        PNR: [],
        uwaLocation: [],
        idDirectorate: [],
        idAgency: [],
        fleetContracttype: [],
        PUR: [],
      }
      for (let b = 0; b < ObjKeys.length; b++) {
        const innerArray: any = []
        filters.forEach(function (e: any) {
          if (e[ObjKeys[b]]) innerArray.push({ label: e[ObjKeys[b]], id: b })
        })
        const uniqueObjects = innerArray.filter(
          (e: any, i: any, arr: any) => arr.findIndex((t: any) => t.label === e.label) === i,
        )
        obj[Object.keys(obj)[b]] = uniqueObjects
      }
      obj.filters = filters
      obj.updatedTime = new Date()
      return obj
    })
}

export async function getFilterUpdatedTime(): Promise<any> {
  return fetch(
    `/api/proxy/?_program=%2FERGO+System%2FKRG%2FStored+Processes%2Freturn_values&_action=execute&req=fleettime`,
  )
    .then(response => response.json())
    .then(response => response.body || [])
    .then(filterTime => {
      const filterUpdatedTime: string = filterTime[0].tstmp.trim()
      const dateSeparatorIndex = filterUpdatedTime.indexOf(':')
      const filterFormattedTime = `${filterUpdatedTime.slice(
        0,
        dateSeparatorIndex,
      )} ${filterUpdatedTime.slice(dateSeparatorIndex + 1, filterUpdatedTime.length)}`
      return filterFormattedTime
    })
    .catch(e => {
      throw new Error(e)
    })
}
