import { Box, Container, CircularProgress } from '@material-ui/core'
import isEqual from 'lodash/isEqual'
import React, { useEffect, useReducer, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CalculationHeader } from '../../components/stickyHeader/calculation-sticky-header'
import { ClaimFilterContainer } from '../../containers/claimFilter/claim-filter-container'
import { useSelectFleetById } from '../../reducers/fleet/fleet-hooks'
import { ClaimSummary, ObservationPeriod, DeductableType } from '../../reducers/proposal/proposal'
import {
  useApplyProvisionCut,
  useChangeObservationPeriod,
  useChangeRatioOfClaims,
  useChangeReseveRatio,
  useChangeStatus,
  useChangeSurcharge,
  useChangeCurrentCarpark,
  useCreateProposal,
  useCurrentProposal,
  useFetchDataFromSAS,
  useGetProposalById,
  useIncludeDeductibleInProposal,
  useSaveProposal,
  useUpdateClaimSummary,
  useChangeTemplateData,
} from '../../reducers/proposal/proposal-hooks'
import { CalculationComments } from './calculation-comments'
import { ClaimHistoryTable } from './claimHistoryTable'
import { ProposalSelectionTable } from './proposal-selection-table'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Card } from '../../components/card/card'
import { useCurrentUser } from 'src/reducers/user/user-hook'
import { CALCULATION_PAGE } from 'src/constants/routes'

export function Calculation() {
  const { fleetId = '', proposalId = '' } = useParams<Record<string, string>>()
  const { t, i18n } = useTranslation()
  const currentProposal = useCurrentProposal()
  const fleet = useSelectFleetById(fleetId || '')
  const createProposal = useCreateProposal()
  const updateClaimSummary = useUpdateClaimSummary()
  const changeObservationPeriod = useChangeObservationPeriod()
  const changeReserveRatio = useChangeReseveRatio()
  const changeSurcharge = useChangeSurcharge()
  const changeCurrentCarpark = useChangeCurrentCarpark()
  const changeProvisionCut = useApplyProvisionCut()
  const changeRatioOfClaims = useChangeRatioOfClaims()
  const changeStatus = useChangeStatus()
  const [saveProposal, { saveProposalActionloading, saveProposalAPIerror }] = useSaveProposal()
  const [proposal] = useGetProposalById(
    (currentProposal && currentProposal.id) || proposalId || '',
    fleetId,
  )
  const includeInProposal = useIncludeDeductibleInProposal()
  const history = useHistory()
  const [fetchDataFromSAS, { loading, error }] = useFetchDataFromSAS()
  const changeDetails = useChangeTemplateData()
  const [setIntial, setSetIntial] = useState(true)
  const [user] = useCurrentUser()
  const onClaimDataChange = (claimData: ClaimSummary) => {
    if (!currentProposal || !claimData) return
    updateClaimSummary(claimData)
  }

  useEffect(() => {
    if (fleet && !currentProposal && proposalId.trim().length === 0) {
      createProposal(fleetId)
    }
  }, [fleet, fleetId, proposalId, createProposal, currentProposal])

  useEffect(() => {
    if (currentProposal && currentProposal.id && fleetId && proposalId.length === 0) {
      history.push(`${CALCULATION_PAGE}/${fleetId}/${currentProposal.id}`)
    }
  }, [currentProposal, fleetId, proposalId, history])
  const onObservationPeriodChange = (value: string) => {
    const [end, start] = value.split('-')
    changeObservationPeriod(parseInt(start ? start : end), parseInt(end))
  }

  const onProvisionCutChange = (value: boolean) => {
    changeProvisionCut(value)
  }

  const onSurchargeChange = (value: number) => {
    changeSurcharge(value)
  }

  const onCurrentCarparkChange = (value: number) => {
    changeCurrentCarpark(value)
  }

  const onReserveRatioChange = (value: number) => {
    changeReserveRatio(value)
  }

  const onRatioOfClaimsChange = (value: number) => {
    changeRatioOfClaims(value)
  }
  const observationPeriod: ObservationPeriod = {
    startYear: currentProposal ? currentProposal.observationPeriodStart : new Date().getFullYear(),
    endYear: currentProposal ? currentProposal.observationPeriodEnd : new Date().getFullYear(),
  }
  const formatNum = (value: number, max = 1) => {
    return isFinite(value)
      ? value.toLocaleString(i18n.language, {
          maximumFractionDigits: max,
          minimumFractionDigits: 0,
        })
      : '-'
  }
  // "Difference in premium" for the default Deductible option
  const diffPremium = () => {
    return currentProposal &&
      currentProposal.renewalOptions &&
      currentProposal.renewalOptions.length > 0 &&
      currentProposal.renewalOptions[0].differencePercentage !== null &&
      isFinite(currentProposal.renewalOptions[0].differencePercentage)
      ? formatNum(currentProposal.renewalOptions[0].differencePercentage)
      : '-'
  }

  // "Difference in premium" for the default Deductible option
  const diffPremiumTemp = () => {
    return currentProposal &&
      currentProposal.renewalOptions &&
      currentProposal.renewalOptions.length > 0 &&
      currentProposal.renewalOptions[0].differencePercentage != null &&
      currentProposal.renewalOptions[0].differencePercentage < 0
      ? 'Abschlag'
      : 'Zuschlag'
  }

  // Telephone number of the user
  const getTeleNum = () => {
    return user && user.telephoneNumber ? user.telephoneNumber : ''
  }

  // Get offer list template based on deductibles selected
  const offerList = () => {
    const listItem: any = []
    if (currentProposal && currentProposal.renewalOptions) {
      const renOptions = currentProposal.renewalOptions.slice(1, 3)
      renOptions.map((item: any) => {
        if (item.deductible.includeInProposal) {
          item.differencePercentage > 0
            ? listItem.push(
                `\n     •    SB VK ${formatNum(item.deductible.fullCover)}/TK ${formatNum(
                  item.deductible.partCover,
                )} mit einem Zuschlag von ${formatNum(item.differencePercentage)}%`,
              )
            : listItem.push(
                `\n     •    SB VK ${formatNum(item.deductible.fullCover)}/TK ${formatNum(
                  item.deductible.partCover,
                )} mit einem Abschlag von ${formatNum(item.differencePercentage)}%`,
              )
        }
        return null
      })
    }
    const template = listItem.map((item: any) => {
      return item
    })
    return listItem.length > 0
      ? `\n\nAlternativ schlagen wir eine Anpassung der SB vor:\n${template.join('')}`
      : ''
  }

  // offerlist template for SE with claim ratio < 100
  const offerTemplateOne = () => {
    return offerList()
      ? `\n\nZur Anpassung erhalten Sie von uns einen Nachtrag zum Kraftfahrtversicherungsvertrag. Die Änderungsbeiträge werden in der nächsten Stichtagsabrechnung erhoben.${offerList()}`
      : `${offerList()}`
  }

  // offerlist template for SO with claim ratio < 100
  const offerTemplateTwo = () => {
    return offerList()
      ? `\n\nZur Anpassung erhalten Sie von uns Änderungspolicen, sowie einen Nachtrag zum oben genannten Rahmenvertrag.${offerList()}`
      : `${offerList()}`
  }

  // offerlist template for SSE with claim ratio < 100
  const offerTemplateThree = () => {
    return offerList()
      ? `\n\nZur Anpassung erhalten Sie von uns einen Nachtrag zum Rahmenvertrag. Die Änderungsbeiträge werden in den nächsten Sammelabrechnungen erhoben.${offerList()}`
      : `${offerList()}`
  }

  const getTempForType = (contractType: string) => {
    return contractType === 'SO'
      ? 'Zur Anpassung erhalten Sie von uns einen Nachtrag zum Kraftfahrtversicherungsvertrag. Die Änderungsbeiträge werden in der nächsten Stichtagsabrechnung erhoben.\n\n'
      : contractType === 'SE'
      ? 'Zur Anpassung erhalten Sie von uns Änderungspolicen, sowie einen Nachtrag zum oben genannten Rahmenvertrag.\n\n'
      : 'Zur Anpassung erhalten Sie von uns einen Nachtrag zum Rahmenvertrag. Die Änderungsbeiträge werden in den nächsten Sammelabrechnungen erhoben.\n\n'
  }

  // response template based on deductibles selected
  const responseTemplate = (contract: string, contractType: string) => {
    let temp = ''
    if (currentProposal && currentProposal.renewalOptions) {
      let renwOptions = currentProposal.renewalOptions.slice(1, 3)
      renwOptions = renwOptions.filter(item => item.deductible.includeInProposal === true)
      if (renwOptions.length > 0) {
        temp =
          contract === 'ver'
            ? 'An unseren Alternativ-Vorschlag halten wir uns bis zum #DUE_DATE#  gebunden. Ohne Rückmeldung werden wir die Beitragsanpassung gemäß der vertraglichen Vereinbarung veranlassen.\n\n'
            : 'Bitte informieren Sie uns bis zum #DUE_DATE# über die Verhandlungsergebnisse.\n\n'
        return temp
      }
    }
    temp = contract === 'ver' ? getTempForType(contractType) : ''
    return temp
  }

  // Template data
  const data = {
    intro:
      'Sehr geehrte Damen und Herren, \n\nzur oben genannten Vertragsverbindung haben wir den Schadenverlauf für den relevanten Versicherungszeitraum ermittelt.',
    Neuverhandlung: {
      SO: {
        reason:
          'Der Schadenverlauf liegt über den vertraglich festgelegten Höchstgrenzen. Gemäß § 5 des Rahmenvertrages werden die Beiträge neu verhandelt.',
        offer: `Wir bitten Sie deshalb mit dem Kunden zum #VALID_DATE# einen ${diffPremiumTemp()} von ${diffPremium()}% auf die aktuellen Stückbeiträge zu vereinbaren.${offerList()}`,
        response: `${responseTemplate(
          'neu',
          'SO',
        )}Haben Sie noch Fragen? Rufen Sie an, wir sind gerne für Sie da.\n\nFreundliche Grüße\nERGO Versicherung AG`,
      },
      SE: {
        reason:
          'Der Schadenverlauf liegt über den vertraglich festgelegten Höchstgrenzen. Gemäß § 5 des Rahmenvertrages werden die Beiträge neu verhandelt.',
        offer: `Wir bitten Sie deshalb mit dem Kunden zum #VALID_DATE# einen ${diffPremiumTemp()} von ${diffPremium()}% auf die aktuellen Stückbeiträge zu vereinbaren.${offerList()}`,
        response: `${responseTemplate(
          'neu',
          'SE',
        )}Haben Sie noch Fragen? Rufen Sie an, wir sind gerne für Sie da.\n\nFreundliche Grüße\nERGO Versicherung AG`,
      },
      SP: {
        reason:
          'Der Schadenverlauf liegt über den vertraglich festgelegten Höchstgrenzen. Gemäß § 5 des Rahmenvertrages werden die Beiträge neu verhandelt.',
        offer: `Wir bitten Sie deshalb mit dem Kunden zum #VALID_DATE# einen ${diffPremiumTemp()} von ${diffPremium()}% auf die aktuellen Stückbeiträge zu vereinbaren.${offerList()}`,
        response: `${responseTemplate(
          'neu',
          'SP',
        )}Haben Sie noch Fragen? Rufen Sie an, wir sind gerne für Sie da.\n\nFreundliche Grüße\nERGO Versicherung AG`,
      },
      SF: {
        reason: '',
        offer: '',
        response: '',
      },
      SR: {
        reason: '',
        offer: '',
        response: '',
      },
      FV: {
        reason: '',
        offer: '',
        response: '',
      },
    },
    vertraglicheAnpassung: {
      SO: {
        reason:
          'Der Schadenverlauf liegt über den in § 5 festgelegten Höchstgrenzen des Rahmenvertrages.',
        offer: `Gemäß dessen tritt mit Wirkung zum #VALID_DATE# eine Beitragsanpassung in Höhe von ${diffPremium()}% in Kraft.${offerTemplateOne()}`,
        response: `${responseTemplate(
          'ver',
          'SO',
        )}Haben Sie noch Fragen? Rufen Sie an, wir sind gerne für Sie da.\n\nFreundliche Grüßen\nERGO Versicherung AG`,
      },
      SE: {
        reason:
          'Der Schadenverlauf liegt über den in § 5 vertraglich festgelegten Höchstgrenzen des Rahmenvertrages.',
        offer: `Gemäß dessen tritt mit Wirkung zum #VALID_DATE# eine Beitragsanpassung in Höhe von ${diffPremium()}% in Kraft.${offerTemplateTwo()}`,
        response: `${responseTemplate(
          'ver',
          'SE',
        )}Haben Sie noch Fragen? Rufen Sie an, wir sind gerne für Sie da.\n\nFreundliche Grüße\nERGO Versicherung AG`,
      },
      SP: {
        reason:
          'Der Schadenverlauf liegt über den in § 5 festgelegten Höchstgrenzen des Rahmenvertrages.',
        offer: `Gemäß dessen tritt mit Wirkung zum #VALID_DATE# eine Beitragsanpassung in Höhe von ${diffPremium()}% in Kraft.${offerTemplateThree()}`,
        response: `${responseTemplate(
          'ver',
          'SP',
        )}Haben Sie noch Fragen? Rufen Sie an, wir sind gerne für Sie da.\n\nFreundliche Grüße\nERGO Versicherung AG`,
      },
      SF: {
        reason: '',
        offer: '',
        response: '',
      },
      SR: {
        reason: '',
        offer: '',
        response: '',
      },
      FV: {
        reason: '',
        offer: '',
        response: '',
      },
    },
  }

  //Contract Type
  const contractType =
    currentProposal && currentProposal.details && currentProposal.details.fleetContractType
      ? currentProposal.details.fleetContractType
      : 'SO'

  //Claim ratio of prev year to get summary page  template
  const getClaimRatio = (year: string) => {
    const result =
      currentProposal && currentProposal.claimsByYear.length > 0
        ? currentProposal.claimsByYear.findIndex((obj: any) => obj.year == year)
        : 0
    return currentProposal &&
      currentProposal.claimsByYear.length > 0 &&
      currentProposal.claimsByYear[result].ratioOfClaim
      ? currentProposal.claimsByYear[result].ratioOfClaim
      : 0
  }

  // Template based on claim ratio of prev year
  const getDataType = () => {
    const claimRatio =
      currentProposal && currentProposal.claimsByYear.length > 0
        ? getClaimRatio(moment().subtract(1, 'year').format('YYYY'))
        : 0
    const contract =
      parseInt(claimRatio.toFixed(2)) < 100 ? 'vertraglicheAnpassung' : 'Neuverhandlung'
    return data[contract][contractType]
  }
  const dataType = getDataType()

  //Setting template first time
  const templateDataIntial = {
    intro:
      currentProposal && currentProposal.details && currentProposal.details.intro
        ? currentProposal.details.intro
        : data.intro,
    reason:
      currentProposal && currentProposal.details && currentProposal.details.reason
        ? currentProposal.details.reason
        : dataType.reason,
    offer:
      currentProposal && currentProposal.details && currentProposal.details.offer
        ? currentProposal.details.offer
        : dataType.offer,
    responseDateAndEnding:
      currentProposal && currentProposal.details && currentProposal.details.responseDateAndEnding
        ? currentProposal.details.responseDateAndEnding
        : dataType.response,
    validFromDate:
      currentProposal && currentProposal.details && currentProposal.details.validFromDate
        ? currentProposal.details.validFromDate
        : moment().startOf('year').format('X'),
    proposalDate:
      currentProposal && currentProposal.details && currentProposal.details.proposalDate
        ? currentProposal.details.proposalDate
        : moment().format('X'),
    brokerAddress:
      currentProposal && currentProposal.details && currentProposal.details.brokerAddress
        ? currentProposal.details.brokerAddress
        : '',
    customerAddress:
      currentProposal && currentProposal.details && currentProposal.details.customerAddress
        ? currentProposal.details.customerAddress
        : '',
    contactPerson:
      currentProposal && currentProposal.details && currentProposal.details.contactPerson
        ? currentProposal.details.contactPerson
        : '',
    extension:
      currentProposal && currentProposal.details && currentProposal.details.extension
        ? currentProposal.details.extension
        : getTeleNum(),
    fax:
      currentProposal && currentProposal.details && currentProposal.details.fax
        ? currentProposal.details.fax
        : '',
    customerInfo:
      currentProposal && currentProposal.details && currentProposal.details.customerInfo
        ? currentProposal.details.customerInfo
        : '',
    type: currentProposal && currentProposal.type ? currentProposal.type : 'Renewal',
    resultType:
      currentProposal && currentProposal.resultType ? currentProposal.resultType : 'Zuschlag',
    resultPercentage1:
      currentProposal && currentProposal.resultPercentage1
        ? currentProposal.resultPercentage1
        : '0',
    changeInDeductible:
      currentProposal && currentProposal.details && currentProposal.details.changeInDeductible
        ? currentProposal.details.changeInDeductible
        : { vk: '', tk: '' },
    brStop: currentProposal && currentProposal.brStop ? currentProposal.brStop : '',
    dueDateTs:
      currentProposal && currentProposal.details && currentProposal.details.dueDateTs
        ? currentProposal.details.dueDateTs
        : moment().add(14, 'days').format('X'),
    purBasedYear:
      currentProposal && currentProposal.details && currentProposal.details.purBasedYear
        ? currentProposal.details.purBasedYear
        : '',
    purBasedClaimRatio:
      currentProposal && currentProposal.details && currentProposal.details.purBasedClaimRatio
        ? currentProposal.details.purBasedClaimRatio
        : '',
    selectedItem:
      currentProposal && currentProposal.details && currentProposal.details.selectedItem
        ? currentProposal.details.selectedItem
        : 'brokerAddress',
  }

  // Changing template based on various cases
  const reducerAction = (state: any, action: any) => {
    const type = action.payload ? action.payload : dataType
    switch (action.type) {
      case 'offer':
        return {
          ...state,
          offer: (type && type.offer) || '',
          responseDateAndEnding: (type && type.response) || '',
        }
      case 'claimRatio':
        return {
          ...state,
          reason: (type && type.reason) || '',
          offer: (type && type.offer) || '',
          responseDateAndEnding: (type && type.response) || '',
        }
      case 'intialSet':
        return templateDataIntial
      default:
        return state
    }
  }
  const [templateData, dispatch] = useReducer(reducerAction, templateDataIntial)
  const {
    ratioOfReserve = 0,
    ratioOfClaims = 70,
    surCharge = 0,
    provisionCut = false,
    currentCarpark = 0,
  } = currentProposal || {}
  const hasProposalModified = !isEqual(proposal, currentProposal)
  React.useEffect(() => {
    changeDetails(templateData)
  }, [templateData])
  React.useEffect(() => {
    dispatch({ type: 'offer', payload: getDataType() })
  }, [currentProposal && currentProposal.renewalOptions])
  // React.useEffect(() => {
  //   dispatch({ type: 'purCalc', payload: getDataType() })
  // }, [currentProposal && currentProposal.details && currentProposal.details.pur])
  React.useEffect(() => {
    if (setIntial && currentProposal) {
      dispatch({ type: 'intialSet', payload: getDataType() })
      setSetIntial(false)
    } else {
      dispatch({ type: 'claimRatio', payload: getDataType() })
    }
  }, [currentProposal && currentProposal.claimsByYear, contractType])
  const onselectionchange = (type: DeductableType, selected: boolean) => {
    includeInProposal(type, selected)
    dispatch({ type: 'offer' })
  }
  return (
    <>
      {currentProposal && fleet && (
        <CalculationHeader
          original={proposal}
          proposal={currentProposal}
          fleet={fleet}
          onSaveProposal={saveProposal}
          saveEnabled={hasProposalModified}
          onChangeStatus={changeStatus}
          contractType={contractType}
          isError={error}
          isLoading={saveProposalActionloading}
        />
      )}
      {!error ? (
        <>
          {loading ? (
            <Card rounded={true}>
              <Box p={3} paddingBottom={0}>
                <Box height='200px' width='50px' margin='auto' alignItems='center' display='flex'>
                  <CircularProgress />
                </Box>
              </Box>
            </Card>
          ) : (
            <>
              <Box paddingTop='5rem'>
                {saveProposalAPIerror !== '' && (
                  <Box color='#807E6F' fontSize='0.75rem' paddingLeft='35%'>
                    {t(saveProposalAPIerror)}
                  </Box>
                )}
                <Container disableGutters={true}>
                  <Box display='flex' padding='0.5rem 0.5rem 25px' flexDirection='column'>
                    {currentProposal && (
                      <>
                        <Box paddingTop='2rem' fontSize='16px' paddingBottom='1rem'>
                          Renta
                        </Box>
                        <ClaimHistoryTable
                          proposal={currentProposal}
                          onClaimChange={onClaimDataChange}
                          onReloadSAS={fetchDataFromSAS}
                        />
                      </>
                    )}
                  </Box>
                  <Box display='flex' padding='0 0.5rem' flexDirection='column'>
                    {currentProposal && (
                      <>
                        <Box paddingTop='1rem' fontSize='16px'>
                          Kalkulation
                        </Box>
                        <ClaimFilterContainer
                          ratioOfReserve={ratioOfReserve}
                          surcharge={surCharge}
                          currentCarpark={currentCarpark}
                          provisionCut={provisionCut}
                          observationPeriod={observationPeriod}
                          deductibles={currentProposal.deductables}
                          simulations={currentProposal.simulations}
                          onReserveRatioChange={onReserveRatioChange}
                          onObservationPeriodChange={onObservationPeriodChange}
                          onSurchargeChange={onSurchargeChange}
                          onProvisionCutChange={onProvisionCutChange}
                          onCurrentCarparkChange={onCurrentCarparkChange}
                        />
                      </>
                    )}
                  </Box>

                  <Box display='flex' padding='5px 0.5rem 2rem' flexDirection='column'>
                    {currentProposal && (
                      <>
                        <Box paddingTop='1rem' fontSize='16px'>
                          Ergebnisse
                        </Box>
                        <ProposalSelectionTable
                          ratioOfClaim={ratioOfClaims}
                          renewalOptions={currentProposal.renewalOptions}
                          onRatioOfClaimsChange={onRatioOfClaimsChange}
                          onSelectionChange={onselectionchange}
                        />
                      </>
                    )}
                  </Box>
                </Container>
              </Box>
              <CalculationComments />
            </>
          )}
        </>
      ) : (
        <Card rounded={true}>
          <Box
            padding='7rem'
            display='flex'
            justifyContent='center'
            color='#666666'
            fontSize='16px'
          >
            {t('failedToFetch')}
          </Box>
        </Card>
      )}
    </>
  )
}
