import { Box, Table as MuiTable, TableRow } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TableBody from '@material-ui/core/TableBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SingleSelect } from '../../components/singleSelect/singleSelect'
import { EnhancedTableHeader } from '../../components/table/enhanced-table-head'
import { TableCell } from '../../components/table/table-cell'
import { DeductableType, RenewalOptions } from '../../reducers/proposal/proposal'
import { proposalTableHeadData } from './proposalTableData'
import { NumberText } from './claimHistoryTable/number-text'
const currencySymbol = '\u20AC'
const useStyles = makeStyles(() =>
  createStyles({
    paper: { width: '100%', height: '100%' },
    checkboxStyle: {
      '& > span': {
        '& > svg': {
          width: '16px',
          height: '16px',
          color: '#8E0038',
        },
      },
    },
    select: {
      width: '7%',
      '& > div': {
        '& > div': {
          color: '#009284',
        },
      },
    },
    labelStyle: {
      color: '#333333',
      fontSize: '12px',
      fontWeight: 'bold',
    },
    greenHighlight: {
      color: '#009284 !important',
    },
    redHighlight: {
      color: 'red !important',
    },
    bold: {
      fontWeight: 'bold',
    },
    tableHead: {
      '& th': {
        paddingTop: '12px',
        paddingBottom: '10px',
      },
    },
  }),
)

const ratioOfClaimsOptions = new Array(41)
  .fill(0)
  .map((_, i) => ({ label: `${i + 50}%`, value: i + 50 }))

export function ProposalSelectionTable({
  ratioOfClaim,
  renewalOptions = [],
  onRatioOfClaimsChange,
  onSelectionChange,
}: {
  ratioOfClaim: number
  renewalOptions?: RenewalOptions[]
  onRatioOfClaimsChange: (value: number) => void
  onSelectionChange?: (id: DeductableType, selected: boolean) => void
}) {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleCheckboxClick = (event: any, option: RenewalOptions) => {
    if (option.deductible.type !== DeductableType.CURRENT) {
      onSelectionChange &&
        onSelectionChange(option.deductible.type, !option.deductible.includeInProposal)
    }
  }

  return (
    <Box display='flex' flexDirection='column' height='100%' width='100%'>
      <Box display='flex' flexDirection='row' paddingBottom='5px'>
        <p className={classes.labelStyle}>{t('withTheRatioOfClaims')}</p>
        <Box className={classes.select}>
          <SingleSelect
            options={ratioOfClaimsOptions}
            defaultValue={ratioOfClaim.toString()}
            onChangeHandler={onRatioOfClaimsChange}
            size='small'
          />
        </Box>
        <p className={classes.labelStyle}>{t('premiumNeededis')}</p>
      </Box>
      <Box height='100%' width='100%'>
        <Paper>
          <Box padding='0 16px'>
            <MuiTable aria-labelledby='tableTitle' aria-label='enhanced table'>
              <EnhancedTableHeader
                className={classes.tableHead}
                tableHeaderData={proposalTableHeadData}
              />
              <TableBody>
                {renewalOptions.map((row, index) => {
                  return (
                    <TableRow key={index} hover={true}>
                      <TableCell key={'checkbox'}>
                        <Checkbox
                          className={classes.checkboxStyle}
                          onClick={event => handleCheckboxClick(event, row)}
                          checked={row.deductible.includeInProposal}
                        />
                      </TableCell>
                      <TableCell>{`${row.deductible.fullCover}/${row.deductible.partCover}`}</TableCell>
                      <TableCell>
                        <NumberText precision={0}>{row.netPremium}</NumberText> {currencySymbol}
                      </TableCell>
                      <TableCell>
                        <NumberText precision={0}>{row.grossPremium}</NumberText> {currencySymbol}
                      </TableCell>
                      <TableCell>
                        <NumberText precision={0}>{row.netPremiumPerRisk}</NumberText>{' '}
                        {currencySymbol}
                      </TableCell>
                      <TableCell>
                        <NumberText precision={0}>{row.grossPremiumPerRisk}</NumberText>{' '}
                        {currencySymbol}
                      </TableCell>
                      <TableCell
                        className={
                          row.difference < 0 ? classes.greenHighlight : classes.redHighlight
                        }
                      >
                        <NumberText precision={0}>{row.difference}</NumberText> {currencySymbol}
                      </TableCell>
                      <TableCell
                        className={`${
                          row.difference < 0 ? classes.greenHighlight : classes.redHighlight
                        } ${classes.bold}`}
                      >
                        {isFinite(row.differencePercentage) ? (
                          <NumberText precision={1}>{row.differencePercentage}</NumberText>
                        ) : (
                          '-'
                        )}{' '}
                        %
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </MuiTable>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}
