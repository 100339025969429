import React from 'react'
import { FleetContainer } from 'src/containers/fleet-search/fleets-container'

export function FleetSearchPage() {
  return (
    <>
      <FleetContainer />
    </>
  )
}
