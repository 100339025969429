import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../state/app-state'
import { FleetDetail, FleetSearchResult } from '../../state/fleet'
import { FleetActions } from './fleet-actions'

export function useFleets(): [FleetSearchResult[]] {
  const fleets = useSelector((state: AppState) => (state ? state.fleets.fleetList : []))
  const searchParameters = useSelector((state: AppState) => state.searchParameters)
  // TO DO after watchlist is up
  // const filteredFleets =
  //   fleets && searchParameters.filterAction === 'apply'
  //     ? [...fleets.filter(fleet => fleet.watchlist === searchParameters.watchlist)]
  //     : fleets && searchParameters.filterAction === 'getTop30'
  //     ? [...fleets.sort((fleetA, fleetB) => (fleetA.annualFee > fleetB.annualFee ? -1 : 1))]
  //     : fleets

  const filteredFleets =
    fleets && searchParameters.filterAction === 'getTop30'
      ? fleets.sort((fleetA, fleetB) => (fleetA.annualFee > fleetB.annualFee ? -1 : 1))
      : fleets
  return [filteredFleets]
}

export function useSelectedFleet(): FleetDetail {
  const selectedFleet = useSelector((state: AppState) => state.fleets.selectedFleet)
  return selectedFleet
}

export function useSelectFleetById(fleetId: string) {
  const selectedFleet = useSelector((state: AppState) => state.fleets.selectedFleet)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(FleetActions.getFleetById(fleetId))
  }, [fleetId, dispatch])
  return selectedFleet
}

export function useSetWatchList() {
  const dispatch = useDispatch()
  return (id: string, watching: boolean) => dispatch(FleetActions.setIntialWatch(id, watching))
}

export function useRemoveWatchList() {
  const dispatch = useDispatch()
  const fleetWatch = useSelector((state: AppState) => (state ? state.fleets.fleetWatch : {}))
  return (id: string, watching: boolean) =>
    dispatch(FleetActions.setWatch(id, fleetWatch.id, watching))
}

export function useGetWatchStatus(id: string) {
  const dispatch = useDispatch()
  const fleetWatch = useSelector((state: AppState) => (state ? state.fleets.fleetWatch : {}))
  useEffect(() => {
    dispatch(FleetActions.getWatchStatus(id))
  }, [id, dispatch])
  return fleetWatch && Object.keys(fleetWatch).length > 0 ? fleetWatch.watching : false
}

export function useSearchFleets(): any {
  const fleets = useSelector((state: AppState) => (state ? state.fleets.fleetList : []))
  return fleets
}
