import { FleetDetail, FleetSearchResult } from '../../state/fleet'
import { SearchParameter } from '../../state/search-filter-params'
import { DeductableType, Deductable } from '../proposal/proposal'

function getCurrentDeductable(currentDeductibleString: string): Deductable {
  const [fullCover = 0, partCover = 0] = currentDeductibleString
    .split('/')
    .map(item => item.trim().split(/\s/))
    .map(item => parseInt(item[1] || '0'))

  return {
    type: DeductableType.CURRENT,
    fullCover,
    partCover,
    includeInProposal: true,
  }
}

export async function getActiveFleets(params: SearchParameter): Promise<FleetSearchResult[]> {
  if (params.searchString && params.searchString !== '' && params.filterAction === 'search') {
    params.fleetName = encodeURIComponent(params.searchString)
    params.uwaName = []
    params.brokerDirectorate = []
    params.brokerAgency = []
    params.fleetType = ''
    params.fleetContractStatus = ''
    params.claimRatio = {}
    params.periodUnderReview = ''
    params.claimRatioPeriod = ''
  } else {
    if (params.searchString) {
      params.fleetName = encodeURIComponent(params.searchString)
    }
  }
  const watchParam = params.watchlist === true ? '&watch=1' : '&watch=0'

  const checkValid = (
    key:
      | 'uwaName'
      | 'brokerDirectorate'
      | 'brokerAgency'
      | 'fleetType'
      | 'fleetContractStatus'
      | 'claimRatio'
      | 'periodUnderReview'
      | 'idFleet'
      | 'fleetName'
      | 'brokerName'
      | 'claimRatioPeriod'
      | 'renewalStatus',
  ) => (params[key] ? params[key] : '')

  return fetch(
    //'/api/proxy/?_program=%2FERGO+System%2FKRG%2FStored+Processes%2Freturn_values&_action=execute&req=fleet_searchlist',
    `/api/proxy/?_program=%2FERGO+System%2FKRG%2FStored+Processes%2Freturn_values&_action=execute&req=fleet_searchlist&uwa_name=${checkValid(
      'uwaName',
    )}&broker_directorate=${checkValid('brokerDirectorate')}&broker_agency=${checkValid(
      'brokerAgency',
    )}&fleet_type=${checkValid('fleetType')}&fleet_contractstatus=${checkValid(
      'fleetContractStatus',
    )}&renewalStatus=${checkValid('renewalStatus')}&claim_ratio_min=${
      params.claimRatio && params.claimRatio.min ? params.claimRatio.min : ''
    }&claim_ratio_max=${
      params.claimRatio && params.claimRatio.max ? params.claimRatio.max : ''
    }&claim_ratio_period=${checkValid('claimRatioPeriod')}
    &pur=${checkValid('periodUnderReview')}&fleet_name=${checkValid('fleetName')}${watchParam}`,
  )
    .then(response => response.json())
    .then(response => response.body || [])
    .then(fleets =>
      fleets.map((fleet: any) => ({
        id: fleet.id_fleet,
        fleetName: fleet.fleet_name,
        fleetNumRisks: fleet.fleet_num_risks,
        fleetSumClaimsCurrYear: fleet.fleet_sum_claims_currYear,
        fleetSumClaims5years: fleet.fleet_sum_claims_5years,
        annualFee: fleet.fleet_total_net,
        uwaName: fleet.uwa_name,
        location: fleet.uwa_location,
        directorate: fleet.id_directorate,
        idAgency: fleet.id_agency,
        brokerName: fleet.broker_name,
        contractType: fleet.fleet_contracttype,
        pur: fleet.PUR,
        currentDeductible: fleet.CURRENT_DEDUCTIBLE,
        watched: fleet.watched,
        dueDate: fleet.dueDate ? fleet.dueDate : '',
        status: fleet.status ? fleet.status : 'Create',
        currentCarpark: fleet.current_carpark,
      })),
    )
    .catch(e => {
      throw new Error('Unable to fetch data' + e)
    })
}

export async function getFleetDetailByMnr(mnr: string): Promise<FleetDetail> {
  // return data.fleetsbyMNR['201044356'] as FleetDetail
  return fetch(
    `/api/proxy/?_program=%2FERGO+System%2FKRG%2FStored+Processes%2Freturn_values&_action=execute&req=fleet_details&id_fleet=${mnr}`,
  )
    .then(response => response.json())
    .then(response => response.body[0])
    .then(fleet => {
      const {
        broker_name,
        broker_postal_town,
        broker_street,
        broker_title_address,
        client_fleet_END_DAT,
        client_fleet_Postcode,
        client_fleet_V_UR_BEG_DAT,
        client_street,
        client_town,
        contactPerson,
        CURRENT_DEDUCTIBLE,
        fleet_contracttype,
        fleet_name,
        fleet_num_risks,
        fleet_sum_claims_5years,
        fleet_sum_claims_currYear,
        fleet_total_net,
        guelt_sl,
        id_agency,
        id_directorate,
        id_fleet,
        PUR,
        uwa_name,
        uwa_location,
        KNR,
        fleet_payment_text,
        fleet_acc_net,
        fleet_acc_sq,
        current_carpark,
        fleet_payment_methode,
      } = fleet
      return {
        agencyId: id_agency,
        agentId: '',
        agentName: '',
        brokerAddress: `${broker_title_address}\n${broker_name}\n${broker_street}\n${broker_postal_town}`,
        brokerName: broker_name,
        contactPerson: contactPerson,
        currentDeductible: getCurrentDeductable(CURRENT_DEDUCTIBLE),
        customerAddress: `${fleet_name}\n${client_street}\n${client_fleet_Postcode} ${client_town}`,
        customerInfo: `Firma ${fleet_name}, ${client_fleet_Postcode} ${client_town} \nRahmenvertrag für die Kraftfahrtversicherungen ${id_fleet}`,
        directorateId: id_directorate,
        endDate: client_fleet_END_DAT,
        fleetContractType: fleet_contracttype,
        fleetId: id_fleet,
        fleetName: fleet_name,
        fleetType: fleet_contracttype,
        id: id_fleet,
        netPremium: fleet_total_net,
        numOfBranches: 0,
        numOfRisks: fleet_num_risks,
        pur: PUR,
        risks: fleet_num_risks,
        startDate: client_fleet_V_UR_BEG_DAT,
        sumClaims5Years: fleet_sum_claims_5years,
        sumClaimsCurrentYear: fleet_sum_claims_currYear,
        year: parseInt(guelt_sl),
        uwa: {
          id: '',
          name: uwa_name,
          location: uwa_location,
        },
        knr: KNR,
        fleetPaymentText: fleet_payment_text,
        premiumValue: fleet_acc_net,
        claimRatio: fleet_acc_sq,
        currentCarpark: current_carpark,
        fleetPaymentMethod: fleet_payment_methode,
      }
    })
}

export async function getWatchListByMnr(mnr: string): Promise<any> {
  return fetch(`/api/watch-list/current/`)
    .then(response => response.json())
    .then(data => data)
    .then(data => {
      const newData =
        data.length > 0
          ? data.filter(function (el: any) {
              return el.fleetId == mnr
            })
          : []
      const { _id, fleetId, watching } = newData[0]
      return {
        id: _id,
        fleetId: fleetId,
        watching: watching,
      }
    })
}

export async function setWatchListIntial(params: any): Promise<any> {
  return fetch(`/api/watch-list`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      fleetId: params.id,
      watching: params.watching,
    }),
  })
    .then(response => response.json())
    .then(data => {
      const { _id, fleetId, watching } = data
      return {
        id: _id,
        fleetId: fleetId,
        watching: watching,
      }
    })
}

export async function setWatchListByMnr(params: any): Promise<any> {
  return fetch(`/api/watch-list/${params.mnr}`, {
    method: 'DELETE',
  })
    .then(response => response.json())
    .then(data => {
      const { _id, fleetId } = data
      return {
        id: _id,
        fleetId: fleetId,
        watching: false,
      }
    })
}
