import { createReducer } from 'redux-saga-tools'
import { Reminder } from '../../state/reminder'
import { ReminderActionTypes } from './reminder-actions'

function addReminders(state: Reminder[], reminders: Reminder[]) {
  return [...(reminders || [])]
}

export const reminderReducer = createReducer([], {
  [ReminderActionTypes.LOAD_REMINDERS]: addReminders,
})
