import { createReducer } from 'redux-saga-tools'
import { Deductable } from '../../state/deductable'
import { DeductableActionTypes } from './deductable-actions'

function addDeductables(state: Deductable[], deductables: Deductable[]) {
  return [...(deductables || [])]
}

export const deductableReducer = createReducer([], {
  [DeductableActionTypes.LOAD_DEDUCTABLES]: addDeductables,
})
