import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { ReminderActions, ReminderActionTypes } from './reminder-actions'
import { AppState } from '../../state/app-state'
import { selectProgress } from 'redux-saga-tools'
import { Reminder } from '../../state/reminder'

export function useReminders(): [Reminder[], { loading: boolean; error?: string }] {
  const dispatch = useDispatch()

  // Select Reminder list from AppState
  const reminders = useSelector((state: AppState) => (state ? state.reminders : []))
  // Watch for LIST_REMINDERS action progress
  const { inProgress = false, error } =
    useSelector(state => selectProgress(state, ReminderActionTypes.LIST_REMINDERS)) || {}
  // Fetch Reminders on load & populate Reminder list
  useEffect(() => {
    dispatch(ReminderActions.listReminders())
  }, [dispatch])

  return [reminders, { loading: inProgress, error }]
}

export function useMarkReminderAsDone(): [
  (id: string) => void,
  { reminderMarkAsDoneLoading: boolean; error?: string },
] {
  const dispatch = useDispatch()
  const { inProgress = false, error = '' } =
    useSelector(state => selectProgress(state, ReminderActionTypes.MARK_AS_COMPLETE)) || {}
  const markReminderAsDone = (id: string) => {
    dispatch(ReminderActions.markReminderAsComplete(id))
  }
  return [markReminderAsDone, { reminderMarkAsDoneLoading: inProgress, error }]
}
