import React, { useState, useEffect } from 'react'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { makeStyles } from '@material-ui/styles'

interface TextAreaProps {
  value?: string
  handleChange?: Function
}

export function TextAreaComponent(props: TextAreaProps) {
  const { value, handleChange } = props
  const [inputValue, handleInput] = useState(value)
  useEffect(() => {
    handleInput(value)
  }, [value])
  const useStyles = makeStyles({
    textField: {
      overflow: 'auto !important',
      width: '100%',
      border: 'none',
      color: '#333333',
      borderBottom: '1px solid #AEAEAE',
      padding: '1rem',
      backgroundColor: '#F8F8F8',
      fontSize: '12px',
      height: '100% !important',
      resize: 'none',
      borderRadius: '4px 4px 0 0',
      '&:focus': {
        outline: 'none !important',
        borderBottom: '2px solid #8E0038 !important',
      },
      '&:hover:before': {
        borderBottom: '2px solid #8E0038 !important',
      },
    },
  })

  function handleOnChange(e: any) {
    handleChange && handleChange(e.target.value)
    handleInput(e.target.value)
  }

  const classes = useStyles()
  return (
    <TextareaAutosize
      value={inputValue}
      className={classes.textField}
      spellCheck={false}
      onChange={handleOnChange}
    />
  )
}
