import {
  ClaimSummary,
  Claim,
  Proposal,
  Deductable,
  ProposalStatus,
  ProposalDetails,
  DeductableType,
} from './proposal'

export const ProposalActionTypes = {
  ADD_CLAIM_DATA: 'ADD_CLAIM_DATA',
  ADD_CLAIM_SUMMARY: 'ADD_CLAIM_SUMMARY',
  CALCULATE_CLAIM_TOTALS: 'CALCULATE_CLAIM_TOTALS',
  CALCULATE_RENEWAL_PREMIUM: 'CALCULATE_RENEWAL_PREMIUM',
  CREATE_NEW_PROPOSAL: 'CREATE_NEW_PROPOSAL',
  FETCH_CLAIM_DETAILS: 'FETCH_CLAIM_DETAILS',
  SIMULATE_CURRENT_DEDUCTABLES: 'SIMULATE_CURRENT_DEDUCTABLES',
  UPDATE_CLAIM_SUMMARY: 'UPDATE_CLAIM_SUMMARY',
  CHANGE_OBSERVATION_PERIOD: 'CHANGE_OBSERVATION_PERIOD',
  CHANGE_RESERVE_RATIO: 'CHANGE_RESERVE_RATIO',
  CHANGE_SURCHARGE: 'CHANGE_SURCHARGE',
  CHANGE_STATUS: 'CHANGE_STATUS',
  APPLY_PROVISION_CUT: 'APPLY_PROVISION_CUT',
  UPDATE_DEDUCTABLE: 'UPDATE_DEDUCTABLE',
  CHANGE_RATIO_OF_CLAIMS: 'CHANGE_RATIO_OF_CLAIMS',
  LIST_PROPOSALS: 'LIST_PROPOSALS',
  LOAD_PROPOSALS: 'LOAD_PROPOSALS',
  SELECT_PROPOSAL: 'SELECT_PROPOSAL',
  SAVE_COMMENTS: 'SAVE_COMMENTS',
  CHANGE_DEDUCTIBLE: 'CHANGE_DEDUCTIBLE',
  SAVE_PROPOSAL: 'SAVE_PROPOSAL',
  ADD_PROPOSAL: 'ADD_PROPOSAL',
  GET_PROPOSAL_BY_ID: 'GET_PROPOSAL_BY_ID',
  CHANGE_DETAILS: 'CHANGE_DETAILS',
  TOGGLE_DEDUCTIBLE_SELECTION: 'TOGGLE_DEDUCTIBLE_SELECTION',
  CHANGE_DATA: 'CHANGE_DATA',
  CLEAR_CURRENT_PROPOSAL: 'CLEAR_CURRENT_PROPOSAL',
  FETCH_FLEET_DETAILS: 'FETCH_FLEET_DETAILS',
  FETCH_CLAIM_HISTORY_BY_ID: 'FETCH_CLAIM_HISTORY_BY_ID',
  CHANGE_CARPARK: 'CHANGE_CARPARK',
}

export const ProposalActions = {
  createProposal: (fleetId: string) => ({
    type: ProposalActionTypes.CREATE_NEW_PROPOSAL,
    payload: fleetId,
  }),
  fetchClaimDetails: (fleetId: string) => ({
    type: ProposalActionTypes.FETCH_CLAIM_DETAILS,
    payload: fleetId,
  }),
  fetchClaimHistory: (fleetId: string) => ({
    type: ProposalActionTypes.FETCH_CLAIM_HISTORY_BY_ID,
    payload: fleetId,
  }),
  addClaimSummary: (claimSummary: ClaimSummary[]) => ({
    type: ProposalActionTypes.ADD_CLAIM_SUMMARY,
    payload: claimSummary,
  }),
  addClaimData: (claimData: Claim[]) => ({
    type: ProposalActionTypes.ADD_CLAIM_DATA,
    payload: claimData,
  }),
  updateClaimSummary: (summary: ClaimSummary) => ({
    type: ProposalActionTypes.UPDATE_CLAIM_SUMMARY,
    payload: summary,
  }),
  changeObservationPeriod: (startYear: number, endYear: number) => ({
    type: ProposalActionTypes.CHANGE_OBSERVATION_PERIOD,
    payload: { startYear, endYear },
  }),
  changeReseveRatio: (value: number) => ({
    type: ProposalActionTypes.CHANGE_RESERVE_RATIO,
    payload: { value },
  }),
  changeStatus: (status: ProposalStatus) => ({
    type: ProposalActionTypes.CHANGE_STATUS,
    payload: { status },
  }),
  changeSurcharge: (value: number) => ({
    type: ProposalActionTypes.CHANGE_SURCHARGE,
    payload: { value },
  }),
  changeCurrrentCarpark: (value: number) => ({
    type: ProposalActionTypes.CHANGE_CARPARK,
    payload: { value },
  }),
  applyProvisionCut: (provisionCut: boolean) => ({
    type: ProposalActionTypes.APPLY_PROVISION_CUT,
    payload: { provisionCut },
  }),
  changeRatioOfClaims: (value: number) => ({
    type: ProposalActionTypes.CHANGE_RATIO_OF_CLAIMS,
    payload: value,
  }),
  calculateClaimTotal: () => ({ type: ProposalActionTypes.CALCULATE_CLAIM_TOTALS }),
  simulateDeductables: () => ({ type: ProposalActionTypes.SIMULATE_CURRENT_DEDUCTABLES }),
  calculateRenewalPremium: () => ({ type: ProposalActionTypes.CALCULATE_RENEWAL_PREMIUM }),
  listProposals: (id: string) => ({ type: ProposalActionTypes.LIST_PROPOSALS, payload: id }),
  loadProposals: (proposals: Proposal[]) => ({
    type: ProposalActionTypes.LOAD_PROPOSALS,
    payload: proposals,
  }),
  selectProposal: (id: string) => ({
    type: ProposalActionTypes.SELECT_PROPOSAL,
    payload: id,
  }),
  saveComment: (comment: string) => ({
    type: ProposalActionTypes.SAVE_COMMENTS,
    payload: comment,
  }),
  changeDeductible: (deductible: Deductable) => ({
    type: ProposalActionTypes.CHANGE_DEDUCTIBLE,
    payload: { deductible },
  }),
  saveProposal: (proposal: Proposal) => ({
    type: ProposalActionTypes.SAVE_PROPOSAL,
    payload: { proposal },
  }),
  addProposal: (proposal: Proposal) => ({
    type: ProposalActionTypes.ADD_PROPOSAL,
    payload: { proposal },
  }),
  getProposalById: (id: string, fleetId: string) => ({
    type: ProposalActionTypes.GET_PROPOSAL_BY_ID,
    payload: { id, fleetId },
  }),
  changeDetails: (details: ProposalDetails) => ({
    type: ProposalActionTypes.CHANGE_DETAILS,
    payload: { details },
  }),
  toggleDeductible: (type: DeductableType, selected: boolean) => ({
    type: ProposalActionTypes.TOGGLE_DEDUCTIBLE_SELECTION,
    payload: { type, selected },
  }),
  changeTemplateData: (data: ProposalDetails) => ({
    type: ProposalActionTypes.CHANGE_DATA,
    payload: { data },
  }),
  clearCurrentProposal: () => ({ type: ProposalActionTypes.CLEAR_CURRENT_PROPOSAL }),
  addFleetDetails: (details: ProposalDetails) => ({
    type: ProposalActionTypes.FETCH_FLEET_DETAILS,
    payload: details,
  }),
}
