import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import RefreshIcon from '@material-ui/icons/Refresh'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { NumberInput } from '../../../components/numberInput/numberInput'
import { ClaimSummary, Proposal } from '../../../reducers/proposal/proposal'
import { NumberText } from './number-text'

const useStyles = makeStyles({
  root: {
    width: '100%',
    minHeight: '250px',
    overflowX: 'auto',
    boxShadow:
      '0 4px 5px 0 rgba(0,0,0,0.12), 0 1px 10px 0 rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.16)',
  },
  table: {
    position: 'relative',
    minHeight: '100px',
    tableLayout: 'fixed',
    '&:after': {
      right: 0,
      width: '1px',
      content: '" "',
      position: 'absolute',
      borderRight: '1px solid #D9D9D9',
      height: '95%',
      top: '2%',
    },
  },
  tableBody: {
    '& td': {
      fontSize: '12px',
    },
  },
  tableHead: {
    '& th': {
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '17px',
    },
  },
  tableFoot: {
    '& td': {
      fontWeight: '600',
      color: '#333333',
    },
  },
  button: {
    margin: '12px',
    color: '#8E0038',
    fontSize: '12px',
    textTransform: 'inherit',
  },
  tableCell: {
    border: 0,
    padding: '5px',
  },
  editableInput: {
    '& input[type="text"]': {
      textAlign: 'center',
      paddingLeft: 0,
    },
  },
})

const columnWidth1 = '9.09%'
const columnWidth2 = '6.09%'
const columnWidth3 = '11.09%'
const columnWidth4 = '6.09%'
const columnWidth5 = '11.09%'
const columnWidth6 = '11.09%'
const columnWidth7 = '9.09%'
const columnWidth8 = '9.09%'
const columnWidth9 = '9.09%'
const columnWidth10 = '9.09%'
const columnWidth11 = '9.09%'

function TableColumn(props: any) {
  const classes = useStyles()
  const { children, editable, width, name, inputWidth, data, onClaimChage, max } = props
  const tableCellStyle = {
    width: width,
  }

  const handleOnChange = (value: string) => {
    value !== children.toString() &&
      onClaimChage &&
      onClaimChage({ ...data, [name]: parseFloat(value) || 0 })
  }

  return (
    <TableCell align='center' style={tableCellStyle} className={classes.tableCell}>
      {editable ? (
        <Box width={inputWidth} margin='0 auto'>
          <NumberInput
            value={children}
            onBlur={handleOnChange}
            onEnter={handleOnChange}
            name={name}
            className={classes.editableInput}
            size='small'
            max={max}
          />
        </Box>
      ) : (
        <>{children}</>
      )}
    </TableCell>
  )
}

const ClaimHistoryTableHead = memo(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableColumn width={columnWidth1}>{t('year')}</TableColumn>
        <TableColumn width={columnWidth2}>{t('risks')}</TableColumn>
        <TableColumn width={columnWidth3}>{t('premium')}</TableColumn>
        <TableColumn width={columnWidth4}>{t('claims')}</TableColumn>
        <TableColumn width={columnWidth5}>{t('payments')}</TableColumn>
        <TableColumn width={columnWidth6}>{t('reserves')}</TableColumn>
        <TableColumn width={columnWidth7}>{t('expense')}</TableColumn>
        <TableColumn width={columnWidth8}>&#216; - {t('claim')}</TableColumn>
        <TableColumn width={columnWidth9}>{t('cpr')}</TableColumn>
        <TableColumn width={columnWidth10}>{t('ratioOfClaims')}</TableColumn>
        <TableColumn width={columnWidth11}>{t('months')}</TableColumn>
      </TableRow>
    </TableHead>
  )
})

function ClaimHistoryTableBody({
  claimHistory,
  onClaimChange,
}: {
  claimHistory: ClaimSummary[]
  onClaimChange: (claim: ClaimSummary) => void
}) {
  const classes = useStyles()
  return (
    <TableBody className={classes.tableBody}>
      {claimHistory.map(data => (
        <TableRow key={data.year}>
          <TableColumn width={columnWidth1} name='year'>
            {data.year}
          </TableColumn>
          <TableColumn
            data={data}
            width={columnWidth2}
            name='risks'
            editable
            inputWidth='40px'
            onClaimChage={onClaimChange}
          >
            {data.risks}
          </TableColumn>
          <TableColumn
            data={data}
            onClaimChage={onClaimChange}
            width={columnWidth3}
            name='premium'
            editable
            inputWidth='80px'
          >
            {data.premium}
          </TableColumn>
          <TableColumn
            data={data}
            onClaimChage={onClaimChange}
            width={columnWidth4}
            name='claims'
            editable
            inputWidth='40px'
          >
            {data.claims}
          </TableColumn>
          <TableColumn
            data={data}
            onClaimChage={onClaimChange}
            width={columnWidth5}
            name='payments'
            editable
            inputWidth='80px'
          >
            {data.payments}
          </TableColumn>
          <TableColumn
            data={data}
            onClaimChage={onClaimChange}
            width={columnWidth6}
            name='reserves'
            editable
            inputWidth='72px'
          >
            {data.reserves}
          </TableColumn>
          <TableColumn data={data} onClaimChage={onClaimChange} width={columnWidth7} name='expense'>
            <NumberText precision={0}>{data.expense}</NumberText>
          </TableColumn>
          <TableColumn
            data={data}
            onClaimChage={onClaimChange}
            width={columnWidth8}
            name='avgClaim'
          >
            <NumberText precision={0}>{data.averageClaim}</NumberText>
          </TableColumn>
          <TableColumn data={data} onClaimChage={onClaimChange} width={columnWidth9} name='cpr'>
            <NumberText precision={0}>{data.cpr}</NumberText>
          </TableColumn>
          <TableColumn
            data={data}
            onClaimChage={onClaimChange}
            width={columnWidth10}
            name='ratioOfClaim'
          >
            <NumberText precision={1} minPrecision={1}>
              {data.ratioOfClaim}
            </NumberText>
            %
          </TableColumn>
          <TableColumn
            data={data}
            editable
            onClaimChage={onClaimChange}
            width={columnWidth11}
            name='months'
            max={12}
          >
            {data.months}
          </TableColumn>
        </TableRow>
      ))}
    </TableBody>
  )
}

function ClaimHistoryTableFooter({ proposal }: { proposal: Proposal }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const euroSymbol = <span>&#8364;</span>
  const {
    totalRisks,
    totalPremium,
    totalClaims,
    totalPayments,
    totalReserves,
    totalExpense,
    totalAverageClaim,
    totalCpr,
    totalRatioOfClaim,
    claimsByYear,
  } = proposal
  if (claimsByYear.length === 0) {
    return null
  }
  return (
    <TableFooter className={classes.tableFoot}>
      <TableRow>
        <TableColumn width={columnWidth1}>{t('total')}</TableColumn>
        <TableColumn width={columnWidth2}>
          <NumberText>{totalRisks}</NumberText>
        </TableColumn>
        <TableColumn width={columnWidth3}>
          <NumberText precision={0}>{totalPremium}</NumberText>
          {euroSymbol}
        </TableColumn>
        <TableColumn width={columnWidth4}>{totalClaims}</TableColumn>
        <TableColumn width={columnWidth5}>
          <NumberText precision={0}>{totalPayments}</NumberText> {euroSymbol}
        </TableColumn>
        <TableColumn width={columnWidth6}>
          <NumberText precision={0}>{totalReserves}</NumberText>
          {euroSymbol}
        </TableColumn>
        <TableColumn width={columnWidth7}>
          <NumberText precision={0}>{totalExpense}</NumberText> {euroSymbol}
        </TableColumn>
        <TableColumn width={columnWidth8}>
          <NumberText precision={0}>{totalAverageClaim}</NumberText> {euroSymbol}
        </TableColumn>
        <TableColumn width={columnWidth9}>
          <NumberText precision={0}>{totalCpr}</NumberText>
        </TableColumn>
        <TableColumn width={columnWidth10}>
          <NumberText precision={1}>{totalRatioOfClaim}</NumberText> %
        </TableColumn>
        <TableColumn width={columnWidth11}></TableColumn>
      </TableRow>
    </TableFooter>
  )
}

export function ClaimHistoryTable({
  proposal,
  onClaimChange,
  onReloadSAS,
}: {
  proposal: Proposal
  onClaimChange: (claim: ClaimSummary) => void
  onReloadSAS: (mnr: string) => void
}) {
  const { t } = useTranslation()
  const classes = useStyles()

  function handleReloadSas() {
    onReloadSAS && onReloadSAS(proposal.fleetId)
  }

  return (
    <Paper className={classes.root}>
      <Box display='flex'>
        <Box flexBasis='85%' padding='13px 0 6px'>
          <Table className={classes.table}>
            <ClaimHistoryTableHead />
            <ClaimHistoryTableBody
              claimHistory={proposal.claimsByYear}
              onClaimChange={onClaimChange}
            />
            <ClaimHistoryTableFooter proposal={proposal} />
          </Table>
        </Box>
        <Box flexBasis='15%' display='flex' alignItems='flex-end'>
          <Button className={classes.button} startIcon={<RefreshIcon />} onClick={handleReloadSas}>
            {t('reloadFromSas')}
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}
