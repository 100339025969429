import { USER_PROFILE_URL } from 'src/constants/routes'
import { User } from './user'

export async function fetchCurrentUser(): Promise<User> {
  // TODO: use a fetch library like Axios & externalize api endpoint
  try {
    const user = await fetch(USER_PROFILE_URL)
      .then(response => response.json())
      .then(token => ({
        displayName: token['http://ERGO/displayName'],
        emailAddress: token['http://ERGO/emailAddress'],
        id: token['http://ERGO/id'],
        surname: token['http://ERGO/surname'],
        givenName: token['http://ERGO/givenName'],
        location: token['http://ERGO/location'],
        telephoneNumber: token['http://ERGO/telephoneNumber'],
      }))
    return user
  } catch (e) {
    throw new Error('Unable to authenticate user')
  }
}

export function fetchPingAPI() {
  return setInterval(() => {
    const documentRef: any = document || {}
    if (documentRef && documentRef.querySelector('iframe.auth')) {
      documentRef && documentRef.querySelector('iframe.auth').remove()
    }
    const iframe: any = documentRef.createElement('iframe')
    iframe.classList.add('auth')
    iframe.src = '/api/auth/refreshToken'
    iframe.style = 'position:absolute;bottom:-10px;right:-10px;width:0;height:0;'
    documentRef.body.appendChild(iframe)
  }, 60000)
}
