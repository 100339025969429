import { FleetSearchResult, FleetDetail } from '../../state/fleet'
import { SearchParameter } from '../../state/search-filter-params'

export const FleetActionTypes = {
  LIST_FLEETS: 'LIST_FLEETS',
  LOAD_FLEETS: 'LOAD_FLEETS',
  SELECT_FLEET: 'SELECT_FLEET',
  SET_FLEET_WATCH: 'SET_FLEET_WATCH',
  GET_FLEET_BY_ID: 'GET_FLEET_BY_ID',
  ADD_FLEET: 'ADD_FLEET',
  GET_WATCH_STATUS: 'GET_WATCH_STATUS',
  ADD_WATCH_STATUS: 'ADD_WATCH_STATUS',
  SET_WATCH_STATUS_INTIAL: 'SET_WATCH_STATUS_INTIAL',
}

export const FleetActions = {
  listFleets: (params: SearchParameter) => ({
    type: FleetActionTypes.LIST_FLEETS,
    payload: { params },
  }),
  loadFleets: (fleets: FleetSearchResult[]) => ({
    type: FleetActionTypes.LOAD_FLEETS,
    payload: fleets,
  }),
  selectFleet: (id: string) => ({
    type: FleetActionTypes.SELECT_FLEET,
    payload: id,
  }),
  setWatch: (id: string, mnr: any, watching: boolean) => ({
    type: FleetActionTypes.SET_FLEET_WATCH,
    payload: { id, watching, mnr },
  }),
  addFleet: (fleet: FleetDetail) => ({
    type: FleetActionTypes.ADD_FLEET,
    payload: { fleet },
  }),
  getFleetById: (mnr: string) => ({
    type: FleetActionTypes.GET_FLEET_BY_ID,
    payload: mnr,
  }),
  getWatchStatus: (id: string) => ({
    type: FleetActionTypes.GET_WATCH_STATUS,
    payload: id,
  }),
  setWatchStatus: (item: any) => ({
    type: FleetActionTypes.ADD_WATCH_STATUS,
    payload: item,
  }),
  setIntialWatch: (id: string, watching: boolean) => ({
    type: FleetActionTypes.SET_WATCH_STATUS_INTIAL,
    payload: { id, watching },
  }),
}
