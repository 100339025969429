export enum ProposalStatus {
  INPROGRESS = 'INPROGRESS',
  SENT = 'SENT',
  NEGOTIATION = 'NEGOTIATION',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
}
export interface Reminder {
  id: string
  dueDate: string
  createdTs: string
  updatedTs: string
  ownerId: string
  fleetId: string
  policyId: string
  proposalId: string
  policyHolder: string
  premium: number
  claimRatio: number
  proposalStatus: 'INPROGRESS' | 'SENT' | 'DONE' | 'CANCELLED' | 'NEGOTIATION'
  isComplete: boolean
}
