import Checkbox from '@material-ui/core/Checkbox'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import React from 'react'
import { translateItem } from '../../utils/languageTransformer'

const useStyles = makeStyles(() =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    headerClass: {
      color: '#333333',
    },
    activeSortIcon: {
      color: '#8E0038 !important',
    },
    inactiveSortIcon: {
      color: '#CCCCCC !important',
    },
  }),
)

const StyledTableSortLabel = withStyles({
  root: {
    color: '#333333 !important',
    fontSize: '.75rem',
    fontWeight: 'bold',
    flexDirection: 'row-reverse',
    padding: 0,
  },
  icon: {
    color: '#CCCCCC',
  },
})(TableSortLabel)

type Order = 'asc' | 'desc'

interface EnhancedTableHeaderProps<T> {
  sortable?: boolean
  checkBoxRequired?: boolean
  //onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void | undefined
  onRequestSort?: any
  order?: Order
  orderBy?: string
  tableHeaderData: T[]
  className?: string
}

function headerStyle(sortable: boolean | undefined) {
  if (sortable) {
    return { borderBottom: '1px solid black', paddingLeft: 0, paddingRight: 0 }
  } else {
    return { borderBottom: '1px solid black' }
  }
}

export function EnhancedTableHeader(props: EnhancedTableHeaderProps<any>) {
  const { sortable, order, orderBy, onRequestSort, tableHeaderData, checkBoxRequired, className } =
    props
  const classes = useStyles()
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }
  return (
    <TableHead className={className}>
      <TableRow>
        {checkBoxRequired ? (
          <TableCell
            padding='checkbox'
            style={{ borderBottom: '1px solid black', visibility: 'hidden' }}
          >
            <Checkbox checked={false} inputProps={{ 'aria-labelledby': 'labelId' }} />
          </TableCell>
        ) : null}
        {tableHeaderData.map((headCell: any, i: number) => (
          <TableCell
            style={headerStyle(sortable)}
            key={i}
            align='left'
            sortDirection={orderBy === headCell.id ? order : false}
            variant='head'
          >
            <StyledTableSortLabel
              active={sortable}
              direction={order}
              hideSortIcon={!sortable}
              classes={{
                icon: orderBy === headCell.id ? classes.activeSortIcon : classes.inactiveSortIcon,
              }}
              onClick={createSortHandler(headCell.id)}
            >
              {translateItem(headCell.label)}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted ascending' : 'sorted descending'}
                </span>
              ) : null}
            </StyledTableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
