import { createReducer } from 'redux-saga-tools'
import { ProposalState } from '../../state/proposal-state'
import { ProposalStatus } from '../../state/reminder'
import {
  ClaimSummary,
  Claim,
  ObservationPeriod,
  Proposal,
  DeductableType,
  Deductable,
  ProposalDetails,
  FleetContractType,
} from './proposal'
import { ProposalActionTypes } from './proposal-actions'
import { CalculationComments } from '../../state/calculation-comments'
import {
  calculateNetClaimDetails,
  simulateClaim,
  getRenewalPremiumsForSimulations,
} from '../../pages/calculation/simulation'
import { FleetDetail } from 'src/state/fleet'

const {
  ADD_CLAIM_DATA,
  ADD_CLAIM_SUMMARY,
  CALCULATE_CLAIM_TOTALS,
  CALCULATE_RENEWAL_PREMIUM,
  CREATE_NEW_PROPOSAL,
  SIMULATE_CURRENT_DEDUCTABLES,
  UPDATE_CLAIM_SUMMARY,
  CHANGE_OBSERVATION_PERIOD,
  CHANGE_RESERVE_RATIO,
  CHANGE_SURCHARGE,
  APPLY_PROVISION_CUT,
  CHANGE_RATIO_OF_CLAIMS,
  LOAD_PROPOSALS,
  SELECT_PROPOSAL,
  CHANGE_DEDUCTIBLE,
  ADD_PROPOSAL,
  SAVE_COMMENTS,
  CHANGE_STATUS,
  CHANGE_DETAILS,
  TOGGLE_DEDUCTIBLE_SELECTION,
  CHANGE_DATA,
  CLEAR_CURRENT_PROPOSAL,
  FETCH_FLEET_DETAILS,
  CHANGE_CARPARK,
} = ProposalActionTypes

function createNewProposal(state: ProposalState, fleetId: string): ProposalState {
  const currentYear = new Date().getFullYear()
  const newProposal = {
    claimsByYear: [],
    createdTs: Date.now(),
    fleetId,
    observationPeriodEnd: currentYear,
    observationPeriodStart: currentYear - 4,
    premium: 0,
    provisionCut: false,
    ratioOfClaims: 70,
    ratioOfReserve: 100,
    risks: 0,
    simulations: [],
    deductables: [
      { type: DeductableType.CURRENT, fullCover: 500, partCover: 500, includeInProposal: true },
      {
        type: DeductableType.SIMULATION1,
        fullCover: 1000,
        partCover: 1000,
        includeInProposal: false,
      },
      {
        type: DeductableType.SIMULATION2,
        fullCover: 2500,
        partCover: 2500,
        includeInProposal: false,
      },
    ],
    status: ProposalStatus.INPROGRESS,
    surCharge: 0,
    totalAverageClaim: 0,
    totalClaims: 0,
    totalCpr: 0,
    totalExpense: 0,
    totalPayments: 0,
    totalPremium: 0,
    totalRatioOfClaim: 0,
    totalReserves: 0,
    totalRisks: 0,
    updatedTs: Date.now(),
    regionalDirectorate: '',
    agency: '',
    version: 0,
    comments: '',
    brStop: '',
    type: 'Renewal',
    resultType: 'Zuschlag',
    resultPercentage1: '0',
    fleetName: '',
    claimRatio: 0,
    premiumValue: 0,
    details: {
      brokerAddress: '',
      contactPerson: '',
      extension: '',
      fax: '',
      customerInfo: '',
      customerAddress: '',
      intro: '',
      reason: '',
      offer: '',
      responseDateAndEnding: '',
      responseTime: 0,
      validFromDate: '',
      proposalDate: '',
      fleetContractType: FleetContractType.SO,
      changeInDeductible: {
        vk: '',
        tk: '',
      },
      dueDateTs: '',
      pur: '',
      purBasedYear: '',
      purBasedClaimRatio: '',
      selectedItem: 'brokerAddress',
    },
    currentCarpark: 0,
    fleetPaymentMethod: '',
  }
  return {
    ...state,
    currentProposal: newProposal,
  }
}

function clearCurrentProposal(state: ProposalState): ProposalState {
  return { ...state, currentProposal: undefined, byId: {} }
}

function addClaimSummary(state: ProposalState, payload: ClaimSummary[]): ProposalState {
  if (!state.currentProposal) {
    return state
  }

  const claimsByYear = payload.map(claim => {
    const { risks, premium, claims, reserves, payments } = claim
    const effectiveRisks = risks
    const cpr = (claims / risks) * 1000
    const expense = payments + reserves
    const averageClaim = expense / claims
    const ratioOfClaim = (expense / premium) * 100
    return {
      ...claim,
      cpr: risks && cpr ? cpr : 0,
      expense: expense ? expense : 0,
      averageClaim: claims && averageClaim ? averageClaim : 0,
      ratioOfClaim: premium && ratioOfClaim ? ratioOfClaim : 0,
      effectiveRisks: effectiveRisks ? effectiveRisks : 0,
    }
  })
  return {
    ...state,
    currentProposal: {
      ...state.currentProposal,
      claimsByYear: [...claimsByYear],
    },
  }
}

function addClaimData(state: ProposalState, payload: Claim[]): ProposalState {
  if (!state.currentProposal) {
    return state
  }
  return {
    ...state,
    claimData: payload,
  }
}

function calculateClaimTotals(state: ProposalState) {
  const currentProposal = state.currentProposal
  if (!currentProposal || !currentProposal.claimsByYear) return state
  const claimTotals = calculateNetClaimDetails(currentProposal.claimsByYear)
  return {
    ...state,
    currentProposal: {
      ...currentProposal,
      ...claimTotals,
    },
  }
}

function simulateDeductables(state: ProposalState) {
  const { currentProposal, claimData } = state

  if (!currentProposal || !claimData || !currentProposal.claimsByYear) return state

  const { deductables = [], claimsByYear } = currentProposal

  const simulations = deductables.map(deductable =>
    simulateClaim(claimData, claimsByYear, deductable),
  )
  return {
    ...state,
    currentProposal: {
      ...state.currentProposal,
      simulations,
    },
  }
}

function calculateRenewalPremiums(state: ProposalState) {
  const { currentProposal, claimData } = state

  if (!currentProposal || !claimData || !currentProposal.claimsByYear) return state
  const {
    claimsByYear,
    observationPeriodEnd,
    observationPeriodStart,
    provisionCut,
    ratioOfClaims,
    ratioOfReserve,
    simulations = [],
    surCharge,
    currentCarpark,
    fleetPaymentMethod,
  } = currentProposal

  const renewalOptions = getRenewalPremiumsForSimulations(
    claimsByYear,
    { startYear: observationPeriodStart, endYear: observationPeriodEnd },
    provisionCut,
    ratioOfReserve,
    ratioOfClaims,
    simulations,
    surCharge,
    currentCarpark,
    fleetPaymentMethod,
  )

  return {
    ...state,
    currentProposal: {
      ...state.currentProposal,
      renewalOptions,
    },
  }
}

function updateClaimSummary(state: ProposalState, updatedSummary: ClaimSummary): ProposalState {
  const { currentProposal } = state
  if (!currentProposal || !currentProposal.claimsByYear) return state

  const claimsByYear = [
    ...currentProposal.claimsByYear.filter(claim => claim.year !== updatedSummary.year),
    updatedSummary,
  ].sort((a, b) => a.year - b.year)
  return addClaimSummary(state, claimsByYear)
}

function changeObservationPeriod(
  state: ProposalState,
  { startYear, endYear }: ObservationPeriod,
): ProposalState {
  const { currentProposal } = state
  if (!currentProposal) return state
  return {
    ...state,
    currentProposal: {
      ...currentProposal,
      observationPeriodStart: startYear,
      observationPeriodEnd: endYear,
    },
  }
}

function changeReserveRatio(state: ProposalState, { value }: { value: number }): ProposalState {
  const { currentProposal } = state

  if (!currentProposal) return state
  return {
    ...state,
    currentProposal: {
      ...currentProposal,
      ratioOfReserve: value,
    },
  }
}

function changeSurcharge(state: ProposalState, { value }: { value: number }): ProposalState {
  const { currentProposal } = state
  if (!currentProposal) return state
  return {
    ...state,
    currentProposal: {
      ...currentProposal,
      surCharge: value,
    },
  }
}

function changeCarpark(state: ProposalState, { value }: { value: number }): ProposalState {
  const { currentProposal } = state
  if (!currentProposal) return state
  return {
    ...state,
    currentProposal: {
      ...currentProposal,
      currentCarpark: value,
    },
  }
}

function applyProvisionCut(
  state: ProposalState,
  { provisionCut }: { provisionCut: boolean },
): ProposalState {
  const { currentProposal } = state
  if (!currentProposal) return state
  return {
    ...state,
    currentProposal: {
      ...currentProposal,
      provisionCut,
    },
  }
}

function changeRatioOfClaims(state: ProposalState, ratioOfClaims: number): ProposalState {
  const { currentProposal } = state
  if (!currentProposal) return state
  return {
    ...state,
    currentProposal: {
      ...currentProposal,
      ratioOfClaims,
    },
  }
}

function addProposals(state: { activeProposals: Proposal[] }, proposals: Proposal[]) {
  return { activeProposals: proposals } || { activeProposals: [] }
}

function setSelectedProposal(state: ProposalState, id: string) {
  const currentProposal = state.byId[id]
  if (!currentProposal) {
    return state
  }
  return { ...state, currentProposal }
}

function changeDeductable(state: ProposalState, { deductible }: { deductible: Deductable }) {
  const { currentProposal } = state
  if (!currentProposal) return state

  return {
    ...state,
    currentProposal: {
      ...currentProposal,
      deductables: currentProposal.deductables.map(d => {
        if (deductible && d.type === deductible.type) return deductible
        return d
      }),
    },
  }
}

function changeDetails(state: ProposalState, { details }: { details: any }) {
  const { currentProposal } = state
  if (!currentProposal) return state
  return {
    ...state,
    currentProposal: {
      ...currentProposal,
      brStop: details.brStop,
      resultType: details.resultType,
      type: details.type,
      resultPercentage1: details.resultPercentage1,
      details: {
        ...currentProposal.details,
        brokerAddress: details.brokerAddress,
        contactPerson: details.contactPerson,
        extension: details.extension,
        fax: details.fax,
        customerInfo: details.customerInfo,
        customerAddress: details.customerAddress,
        responseTime: details.responseTime,
        validFromDate: details.validFromDate,
        proposalDate: details.proposalDate,
        changeInDeductible: details.changeInDeductible,
        intro: details.intro,
        reason: details.reason,
        offer: details.offer,
        responseDateAndEnding: details.responseDateAndEnding,
        dueDateTs: details.dueDateTs,
        purBasedYear: details.purBasedYear,
        purBasedClaimRatio: details.purBasedClaimRatio,
        selectedItem: details.selectedItem,
      },
    },
  }
}

function changeData(state: ProposalState, { data }: { data: ProposalDetails }) {
  const { currentProposal } = state
  if (!currentProposal) return state
  return {
    ...state,
    currentProposal: {
      ...currentProposal,
      details: {
        ...currentProposal.details,
        intro: data.intro,
        reason: data.reason,
        offer: data.offer,
        responseDateAndEnding: data.responseDateAndEnding,
        validFromDate: data.validFromDate,
        proposalDate: data.proposalDate,
        dueDateTs: data.dueDateTs,
        purBasedYear: data.purBasedYear,
        purBasedClaimRatio: data.purBasedClaimRatio,
        selectedItem: data.selectedItem,
      },
    },
  }
}

function addProposal(state: ProposalState, { proposal }: { proposal: Proposal }): ProposalState {
  if (!proposal.id) {
    return state
  }
  return {
    ...state,
    currentProposal: {
      ...proposal,
      type: proposal.type,
      resultType: proposal.resultType,
      resultPercentage1: proposal.resultPercentage1,
    },
    byId: {
      ...state.byId,
      [proposal.id]: {
        ...proposal,
        type: proposal.type,
        resultType: proposal.resultType,
        resultPercentage1: proposal.resultPercentage1,
      },
    },
  }
}

function saveComments(state: ProposalState, comments: CalculationComments) {
  const { currentProposal } = state
  if (!currentProposal) return state
  return {
    ...state,
    currentProposal: {
      ...currentProposal,
      comments,
    },
  }
}

function changeStatus(state: ProposalState, { status }: { status: ProposalStatus }) {
  const { currentProposal } = state
  if (!currentProposal) return state

  return {
    ...state,
    currentProposal: {
      ...currentProposal,
      status,
    },
  }
}

function addFleetDetails(state: ProposalState, payload: FleetDetail): ProposalState {
  if (!state.currentProposal) {
    return state
  }
  const deductables = ((state.currentProposal && state.currentProposal.deductables) || []).filter(
    deductable => deductable.type !== DeductableType.CURRENT,
  )
  return {
    ...state,
    currentProposal: {
      ...state.currentProposal,
      fleetName: payload.fleetName,
      deductables: [payload.currentDeductible, ...deductables],
      regionalDirectorate: payload.directorateId,
      agency: payload.agencyId,
      premiumValue: payload.premiumValue,
      claimRatio: payload.claimRatio,
      details: {
        ...state.currentProposal.details,
        brokerAddress: payload.brokerAddress,
        customerAddress: payload.customerAddress,
        customerInfo: payload.customerInfo,
        contactPerson: payload.contactPerson,
        fleetContractType: payload.fleetContractType,
        pur: payload.pur,
      },
      currentCarpark: payload.currentCarpark,
      fleetPaymentMethod: payload.fleetPaymentMethod,
    },
  }
}

function toggleDeductibleSelection(
  state: ProposalState,
  { type, selected }: { type: DeductableType; selected: boolean },
): ProposalState {
  const { currentProposal } = state
  if (!currentProposal) return state
  return {
    ...state,
    currentProposal: {
      ...currentProposal,
      deductables: currentProposal.deductables.map(deductable => {
        if (deductable.type === type) {
          return { ...deductable, includeInProposal: selected }
        }
        return deductable
      }),
      renewalOptions:
        currentProposal &&
        currentProposal.renewalOptions &&
        currentProposal.renewalOptions.map(renewalOption => {
          if (renewalOption.deductible.type === type) {
            return {
              ...renewalOption,
              deductible: { ...renewalOption.deductible, includeInProposal: selected },
            }
          }
          return renewalOption
        }),
    },
  }
}

// function addPropDetails(state: ProposalState, { fleet }: { fleet: FleetDetail }) {
//   const { currentProposal } = state
//   if (!currentProposal) return state
//   return {
//     ...state,
//     currentProposal: {
//       ...currentProposal,
//       premiumValue: fleet.premiumValue,
//       claimRatio: fleet.claimRatio,
//     },
//   }
// }

export const proposalReducer = createReducer(
  {
    activeProposals: [],
    currentProposal: null,
    byId: {},
  },
  {
    [CREATE_NEW_PROPOSAL]: createNewProposal,
    [ADD_CLAIM_SUMMARY]: addClaimSummary,
    [CALCULATE_CLAIM_TOTALS]: calculateClaimTotals,
    [ADD_CLAIM_DATA]: addClaimData,
    [SIMULATE_CURRENT_DEDUCTABLES]: simulateDeductables,
    [CALCULATE_RENEWAL_PREMIUM]: calculateRenewalPremiums,
    [UPDATE_CLAIM_SUMMARY]: updateClaimSummary,
    [CHANGE_OBSERVATION_PERIOD]: changeObservationPeriod,
    [CHANGE_RESERVE_RATIO]: changeReserveRatio,
    [CHANGE_SURCHARGE]: changeSurcharge,
    [APPLY_PROVISION_CUT]: applyProvisionCut,
    [CHANGE_RATIO_OF_CLAIMS]: changeRatioOfClaims,
    [LOAD_PROPOSALS]: addProposals,
    [SELECT_PROPOSAL]: setSelectedProposal,
    [SAVE_COMMENTS]: saveComments,
    [CHANGE_DEDUCTIBLE]: changeDeductable,
    [ADD_PROPOSAL]: addProposal,
    [CHANGE_STATUS]: changeStatus,
    [CHANGE_DETAILS]: changeDetails,
    [TOGGLE_DEDUCTIBLE_SELECTION]: toggleDeductibleSelection,
    [CHANGE_DATA]: changeData,
    [CLEAR_CURRENT_PROPOSAL]: clearCurrentProposal,
    [FETCH_FLEET_DETAILS]: addFleetDetails,
    [CHANGE_CARPARK]: changeCarpark,
    // [FleetActionTypes.ADD_FLEET]: addPropDetails,
  },
)
