import { createReducer } from 'redux-saga-tools'
import { NoteActionTypes } from './notes-actions'
import { FleetNotes } from 'src/state/note'

function addNote(state: FleetNotes, fleetNotes: FleetNotes) {
  return { ...fleetNotes }
}

export const fleetNotesReducer = createReducer([], {
  [NoteActionTypes.LOAD_NOTE]: addNote,
})
