import React from 'react'
import { Paper, Box, withStyles, WithStyles } from '@material-ui/core'

import { DetailsGraph } from 'src/views/viya-objects/details'
import { ESATable } from 'src/views/viya-objects/esa-table'

const styles = () => ({
  sasWrapper: {
    display: 'flex',
    overflow: 'hidden',
    padding: '1rem',
  },
})

class SasDetailsContainer extends React.Component<WithStyles<typeof styles>, {}> {
  shouldComponentUpdate(): boolean {
    return false
  }

  render() {
    const { classes } = this.props
    return (
      <>
        <Paper>
          <Box className={classes.sasWrapper} minHeight='885px'>
            <DetailsGraph />
          </Box>
        </Paper>
        <Paper>
          <Box margin='2rem 4px 1rem 4px' height='400px'>
            <ESATable />
          </Box>
        </Paper>
      </>
    )
  }
}

export default withStyles(styles)(SasDetailsContainer)
