export const CalculationCommentActionTypes = {
  SAVE_COMMENT: 'SAVE_COMMENT',
  GET_COMMENT: 'GET_COMMENT',
  LOAD_COMMENT: 'LOAD_COMMENT',
  UPDATE_COMMENT: 'UPDATE_COMMENT',
}

export const CalculationCommentsActions = {
  getComment: (mnr: string) => ({ payload: mnr, type: CalculationCommentActionTypes.GET_COMMENT }),
  saveComment: (mnr: string, comment: string) => ({
    payload: { fleetId: mnr, comment: comment },
    type: CalculationCommentActionTypes.SAVE_COMMENT,
  }),
  updateComment: (commentId: string, comment: string) => ({
    payload: { commentId: commentId, comment: comment },
    type: CalculationCommentActionTypes.UPDATE_COMMENT,
  }),
  loadComment: (fleetComment: string) => ({
    type: CalculationCommentActionTypes.LOAD_COMMENT,
    payload: fleetComment,
  }),
}
