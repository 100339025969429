import React from 'react'
import { useState, useEffect } from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import { Card } from '../../../components/card/card'
import { TextAreaComponent } from '../../../components/textArea/textArea'
import { Button } from '../../../components/button/button'
import { useTranslation } from 'react-i18next'
import {
  useAddNotes,
  useFetchNotes,
  useSaveNotes,
  useUpdateNotes,
} from 'src/reducers/notes/notes-hooks'
import { useSelectedFleet } from '../../../reducers/fleet/fleet-hooks'

export default function Notes() {
  const { t } = useTranslation()
  const { id } = useSelectedFleet() || {}
  const [updateNotes, { loading, error }] = useUpdateNotes()
  const [getNotes, { fetchLoading, fetchError }] = useAddNotes()
  const [saveNotes, { saveLoading, saveError }] = useSaveNotes()
  const fleetNotesObject = useFetchNotes()
  const [noteValue, setNote] = useState('')
  const [buttonDisableStatus, setButtonDisableStatus] = useState(true)

  const handleSaveButtonClick = () => {
    setButtonDisableStatus(true)
    fleetNotesObject.commentId && fleetNotesObject.fleetId === parseInt(id)
      ? updateNotes(fleetNotesObject.commentId, noteValue)
      : saveNotes(id, noteValue)
  }

  const handleNoteChange = (note: string) => {
    setNote(note)
    !fetchError && buttonDisableStatus && setButtonDisableStatus(false)
  }

  useEffect(() => {
    id && getNotes(id)
  }, [id])

  useEffect(() => {
    setButtonDisableStatus(fetchError || (!error && !saveError))
  }, [fetchError, error, saveError])

  useEffect(() => {
    fleetNotesObject && fleetNotesObject.note && fleetNotesObject.fleetId === parseInt(id)
      ? setNote(fleetNotesObject.note)
      : setNote('')
  }, [fleetNotesObject])

  return (
    <React.Fragment>
      <Box display='flex' flexDirection='column'>
        <Box width='63rem' height='31rem'>
          <Card rounded={true}>
            <Box
              padding='1.5rem'
              display='flex'
              position='relative'
              flexDirection='column'
              height='496px'
            >
              <TextAreaComponent
                value={noteValue}
                handleChange={(value: any) => handleNoteChange(value)}
              />
              <Box display='flex' flex-flexDirection='row'>
                {!error && !fetchError && !saveError ? (
                  <>
                    {loading || fetchLoading || saveLoading ? (
                      <Box position='absolute' top='50%' left='45%'>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Box />
                    )}
                  </>
                ) : (
                  <Box
                    display='flex'
                    justifyContent='center'
                    color='#666666'
                    fontSize='16px'
                    flexDirection='column'
                  >
                    {t('somethingWentWrong')}
                  </Box>
                )}
                <Box display='flex' flexGrow='1' justifyContent='flex-end' paddingTop='1.5rem'>
                  <Button
                    clickHandler={handleSaveButtonClick}
                    disabled={buttonDisableStatus}
                    color='primary'
                    padding='0 20px'
                  >
                    {t('save')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
    </React.Fragment>
  )
}
