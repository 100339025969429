import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../state/app-state'
import {
  CalculationCommentsActions,
  CalculationCommentActionTypes,
} from './calculation-comments-action'
import { CalculationComments } from '../../state/calculation-comments'
import { selectProgress } from 'redux-saga-tools'

export function useGetComments(): [
  (fleetId: string) => void,
  { fetchLoading: boolean; fetchError: any },
] {
  const dispatch = useDispatch()
  const { inProgress = false, error = '' } =
    useSelector(state => selectProgress(state, CalculationCommentActionTypes.GET_COMMENT)) || {}
  const getComment = (fleetId: string) => dispatch(CalculationCommentsActions.getComment(fleetId))
  return [getComment, { fetchLoading: inProgress, fetchError: error }]
}

export function useSaveComments(): [
  (fleetId: string, comment: string) => void,
  { saveLoading: boolean; saveError: any },
] {
  const dispatch = useDispatch()
  const { inProgress = false, error = '' } =
    useSelector(state => selectProgress(state, CalculationCommentActionTypes.SAVE_COMMENT)) || {}
  const saveComment = (fleetId: string, comment: string) =>
    dispatch(CalculationCommentsActions.saveComment(fleetId, comment))
  return [saveComment, { saveLoading: inProgress, saveError: error }]
}

export function useUpdateComments(): [
  (commentId: string, comment: string) => void,
  { loading: boolean; error: any },
] {
  const dispatch = useDispatch()
  const { inProgress = false, error = '' } =
    useSelector(state => selectProgress(state, CalculationCommentActionTypes.UPDATE_COMMENT)) || {}
  const updateComments = (commentId: string, comment: string) =>
    dispatch(CalculationCommentsActions.updateComment(commentId, comment))
  return [updateComments, { loading: inProgress, error }]
}

export function useFetchComments(): CalculationComments {
  const fleetComment = useSelector((state: AppState) => state.calculationComments)
  return fleetComment
}
