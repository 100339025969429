import { SearchParameter } from '../../state/search-filter-params'

export const SearchParametersActionTypes = {
  ADD_SEARCH_PARAMETERS: 'ADD_SEARCH_PARAMETERS',
}

export const SearchParametersActions = {
  addSearchFilterParameters: (filterItems: SearchParameter) => ({
    type: SearchParametersActionTypes.ADD_SEARCH_PARAMETERS,
    payload: filterItems,
  }),
}
