export enum ProposalStatus {
  INPROGRESS = 'INPROGRESS',
  SENT = 'SENT',
  NEGOTIATION = 'NEGOTIATION',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
}

export enum DeductableType {
  CURRENT = 'CURRENT',
  SIMULATION1 = 'SIMULATION1',
  SIMULATION2 = 'SIMULATION2',
}

export interface Proposal {
  _id?: string
  brStop: string
  id?: string
  claimsByYear: ClaimSummary[]
  createdTs: number
  deductables: Deductable[]
  fleetId: string
  premium: number
  risks: number
  ratioOfReserve: number
  ratioOfClaims: number
  observationPeriodStart: number
  observationPeriodEnd: number
  surCharge: number
  provisionCut: boolean
  simulations: Simulation[]
  status: ProposalStatus
  totalAverageClaim: number
  totalClaims: number
  totalCpr: number
  totalExpense: number
  totalPayments: number
  totalPremium: number
  totalRatioOfClaim: number
  totalReserves: number
  totalRisks: number
  updatedTs: number
  version: number
  renewalOptions?: RenewalOptions[]
  comments: string
  date?: string
  resultPercentage1?: string
  resultType?: string
  type?: string
  content?: string
  underwriter?: string
  details: ProposalDetails
  fleetName: string
  agency: string
  regionalDirectorate: string
  claimRatio: number
  premiumValue: number
  currentCarpark: number
  fleetPaymentMethod: string
}

export interface Deductable {
  type: DeductableType
  fullCover: number
  partCover: number
  includeInProposal: boolean
}

export interface ClaimSummary {
  year: number
  risks: number
  premium: number
  claims: number
  payments: number
  reserves: number
  expense: number
  averageClaim: number
  cpr: number
  ratioOfClaim: number
  months: number
  effectiveRisks?: number
}

export interface Simulation {
  type: DeductableType
  includeInProposal: boolean
  fullCoverDeductible: number
  partCoverDeductible: number
  byYear: SimulationByYear[]
  totalExpense: number
}

export interface SimulationByYear {
  year: number
  claimPerRisk: number
  noOfClaims: number
  averageClaim: number
  expense: number
  reserves: number
  payments: number
  difference: number
}

export interface Claim {
  claimNumber: string
  claimSegment: string
  claimYear: number
  deductible: number
  deductiblePaidByErgo: number
  expenseForErgo: number
  fleetId: string
  reserve: number
  totalExpense: number
  claimDate: string
  glas: string
}

export interface RenewalOptions {
  includeInProposal?: boolean
  deductible: Deductable
  difference: number
  differencePercentage: number
  grossPremium: number
  grossPremiumPerRisk: number
  netPremium: number
  netPremiumPerRisk: number
}

export interface ObservationPeriod {
  startYear: number
  endYear: number
}

export enum FleetContractType {
  SO = 'SO',
  STEP = 'SE',
  SR = 'SR',
  SF = 'SF',
  SP = 'SP',
}

export interface ProposalDetails {
  selectedItem: string
  purBasedYear?: string
  purBasedClaimRatio?: string
  pur?: any
  fleetContractType?: 'SE' | 'SR' | 'SF' | 'SP' | 'SO'
  proposalDate?: any
  brokerAddress?: string
  customerAddress?: string
  contactPerson?: string
  extension?: string
  fax?: string
  customerInfo?: string
  intro?: string
  reason?: string
  offer?: string
  responseDateAndEnding?: string
  responseTime?: number
  validFromDate?: string
  changeInDeductible?: ChangeInDeductible
  dueDateTs?: string
}

interface ChangeInDeductible {
  vk: string
  tk: string
}
