export interface ProposalTableData {
  deductible: string
  premiumNetto: string
  premiumBrutto: string
  perRiskNetto: string
  perRiskBrutto: string
  difference: string
  differencePercentage: string
}

export const proposalTableData: ProposalTableData[] = [
  {
    deductible: 'Ygtj chen cdd',
    premiumNetto: '2589647',
    premiumBrutto: 'DDSS',
    perRiskNetto: '2.1%',
    perRiskBrutto: '2589647',
    difference: 'DDSS',
    differencePercentage: '2.1%',
  },
  {
    deductible: 'true',
    premiumNetto: '2589647',
    premiumBrutto: 'DDSS',
    perRiskNetto: '2.1%',
    perRiskBrutto: '2589647',
    difference: 'DDSS',
    differencePercentage: '2.1%',
  },
  {
    deductible: 'Ygtj chen cdd',
    premiumNetto: '2589647',
    premiumBrutto: 'DDSS',
    perRiskNetto: '2.1%',
    perRiskBrutto: '2589647',
    difference: 'DDSS',
    differencePercentage: '2.1%',
  },
]

interface ProposalTableHeadRowData {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
}

export const proposalTableHeadData: ProposalTableHeadRowData[] = [
  { id: 'include', numeric: false, disablePadding: true, label: 'include' },
  { id: 'deductible', numeric: true, disablePadding: false, label: 'deductible' },
  { id: 'premiumNetto', numeric: true, disablePadding: false, label: 'premiumNetto' },
  { id: 'premiumBrutto', numeric: true, disablePadding: false, label: 'premiumBrutto' },
  { id: 'perRiskNetto', numeric: true, disablePadding: false, label: 'perRiskNetto' },
  { id: 'perRiskBrutto', numeric: true, disablePadding: false, label: 'perRiskBrutto' },
  { id: 'difference', numeric: true, disablePadding: false, label: 'difference' },
  {
    id: 'differencePercentage',
    numeric: true,
    disablePadding: false,
    label: 'differencePercentage',
  },
]
