import { useDispatch, useSelector } from 'react-redux'
import { SearchFiltersActions, SearchFiltersActionTypes } from './fleet-search-filters-actions'
import { AppState } from 'src/state/app-state'
import { selectProgress } from 'redux-saga-tools'
import { useEffect } from 'react'
import { SearchFilters } from 'src/state/fleet-search-filters'

export function useAddSearchFilters(): [SearchFilters, boolean] {
  const dispatch = useDispatch()
  const searchFilters = useSelector((state: AppState) => state.searchFilters)
  const { inProgress = false } =
    useSelector(state => selectProgress(state, SearchFiltersActionTypes.ADD_SEARCH_FILTERS)) || {}
  useEffect(() => {
    dispatch(SearchFiltersActions.addSearchFilters())
  }, [dispatch])
  return [searchFilters, inProgress]
}

export function useSearchFilterParams(): any {
  const searchParameters = useSelector((state: AppState) => state.searchParameters)
  return searchParameters
}
