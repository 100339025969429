import { Paper, Box, makeStyles, createStyles } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Label } from '../../components/label/label'
import { Button } from '../../components/button/button'
import { TextFieldComponent } from '../../components/textField/textField'
import { useTranslation } from 'react-i18next'
import { useAddSearchParameters } from 'src/reducers/fleet-search-parameters/search-parameters-hook'
import { useFleets } from 'src/reducers/fleet/fleet-hooks'
import { FLEETS_PAGE, FLEET_DETAILS_PAGE } from 'src/constants/routes'

const useStyles = makeStyles(() =>
  createStyles({
    searchBox: {
      width: '100%',
      padding: '1.5rem 1rem',
    },
  }),
)

export default function FleetSearch() {
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()
  const [addSearchFilterParams] = useAddSearchParameters()
  const [fleetData] = useFleets()
  const [fleetSearchKeyword, setFleetSearchKeyword] = useState('')
  useEffect(() => {
    if (fleetSearchKeyword !== '') {
      if (fleetData && fleetData.length == 1 && fleetData[0].id) {
        history.push(`${FLEET_DETAILS_PAGE}/${fleetData[0].id}/overview`)
        addSearchFilterParams({
          searchString: '',
        })
      } else history.push(FLEETS_PAGE)
    }
  }, [fleetData])
  return (
    <Box>
      <Label value={t('fleetSearch')} customStyles={{ marginBottom: '8px' }} />
      <Paper className={classes.searchBox}>
        <Box justifyContent='space-between' display='flex'>
          <Button
            clickHandler={() => history.push(`${FLEETS_PAGE}/?top=30`)}
            size='small'
            color='secondary'
            height='40px'
            variant='outlined'
          >
            {t('mytop30')}
          </Button>
          <Button
            clickHandler={() => history.push(`${FLEETS_PAGE}/?claimRatio=>75`)}
            size='small'
            color='secondary'
            height='40px'
            variant='outlined'
          >
            {t('75percentage')}
          </Button>
          <Button
            clickHandler={() => history.push(`${FLEETS_PAGE}/?watchlist=true`)}
            size='small'
            color='secondary'
            height='40px'
            variant='outlined'
          >
            {t('watchlist')}
          </Button>
        </Box>
        <Box p={2} />
        <Box display='flex' justifyContent='space-between'>
          <TextFieldComponent
            placeholder={t('fleetNameNumber')}
            size='large'
            value={fleetSearchKeyword}
            handleChange={value => {
              setFleetSearchKeyword(value)
            }}
            handleCustomKeyPress={e => {
              if (e.key === 'Enter') {
                fleetSearchKeyword === ''
                  ? history.push(FLEETS_PAGE)
                  : addSearchFilterParams({
                      searchString: fleetSearchKeyword,
                      filterAction: 'search',
                    })
              }
            }}
          />
          <Button
            clickHandler={() => {
              fleetSearchKeyword === ''
                ? history.push(FLEETS_PAGE)
                : addSearchFilterParams({
                    searchString: fleetSearchKeyword,
                    filterAction: 'search',
                  })
            }}
            margin='0 0 0 1rem'
            size='large'
            color='primary'
            height='40px'
          >
            {t('search')}
          </Button>
        </Box>
        <Box p={0.5} />
      </Paper>
    </Box>
  )
}
