import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Label } from '../../components/label/label'
import Radio from '@material-ui/core/Radio'
import { useTranslation } from 'react-i18next'

interface RadioProps {
  onChange?: Function
  selectedItem?: string
}

export function RadioComponent(props: RadioProps) {
  const { onChange, selectedItem } = props
  const [selectedValue, setSelectedValue] = React.useState(selectedItem)
  const { t } = useTranslation()
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value)
    if (onChange) onChange(event.target.value)
  }

  const useStyles = makeStyles({
    radioButtonStyle: {
      '& svg': {
        width: '20px',
      },
    },
  })

  const classes = useStyles()

  React.useEffect(() => {
    setSelectedValue(selectedItem)
  }, [selectedItem])

  return (
    <Box display='flex' padding='2rem 1rem 1rem .8rem' alignItems='center'>
      <Radio
        checked={selectedValue === 'brokerAddress'}
        onChange={handleChange}
        value='brokerAddress'
        name='radio-button-demo'
        inputProps={{ 'aria-label': 'broker' }}
        color='primary'
        className={classes.radioButtonStyle}
      />
      <Label value={t('broker')} customStyles={{ fontSize: '12px', marginRight: '41px' }} />
      <Radio
        checked={selectedValue === 'customerAddress'}
        onChange={handleChange}
        value='customerAddress'
        name='radio-button-demo'
        inputProps={{ 'aria-label': 'customerAddress' }}
        color='primary'
        className={classes.radioButtonStyle}
      />
      <Label value={t('customer')} customStyles={{ fontSize: '12px' }} />
    </Box>
  )
}
