import { createReducer } from 'redux-saga-tools'
import { SearchParameter } from '../../state/search-filter-params'
import { SearchParametersActionTypes } from './search-parameters-actions'

function setSearchFilterParams(state: SearchParameter, filterCriteria: SearchParameter) {
  return filterCriteria
}

export const searchFilterParamsReducer = createReducer(
  {},
  {
    [SearchParametersActionTypes.ADD_SEARCH_PARAMETERS]: setSearchFilterParams,
  },
)
