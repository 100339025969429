import React from 'react'
import { Box } from '@material-ui/core'

import envLink, { SAS_REPORT_URL } from '../../utils/envLink'
import { SAS_REPORT_URI } from '../../constants/routes'

export const DetailsGraph = React.memo(() => {
  return (
    <Box alignItems='center' justifyContent='center' width='100%'>
      <sas-report-object
        authenticationType='credentials'
        url={envLink(SAS_REPORT_URL)}
        reportUri={SAS_REPORT_URI}
        objectName='ve5980'
        style={{ width: '100%', height: '113%' }}
        id='sas-report-ve5980-details'
      ></sas-report-object>
    </Box>
  )
})
