import React from 'react'
import { Box } from '@material-ui/core'

import envLink, { SAS_REPORT_URL } from '../../utils/envLink'
import { SAS_REPORT_URI } from '../../constants/routes'

export const Dashboard = React.memo(() => {
  return (
    <Box alignItems='center' justifyContent='center' width='100%'>
      <sas-report-object
        authenticationType='credentials'
        url={envLink(SAS_REPORT_URL)}
        reportUri={SAS_REPORT_URI}
        objectName='ve9744'
        style={{ width: '100%', height: '232px' }}
        id='sas-report-ve9744-dashboard'
      ></sas-report-object>
    </Box>
  )
})
