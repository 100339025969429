import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/styles'
import { Select, MenuItem, makeStyles } from '@material-ui/core'
import { ReactComponent as ArrowIcon } from '../../images/arrowIcon.svg'

interface FilterOptions {
  label: string
  value?: string | number
}

interface SingleSelectProps<FilterOptions> {
  options: FilterOptions[]
  label?: string
  onChangeHandler?: Function
  index?: number
  value?: any
  size?: string
  variant?: string
  defaultValue?: string
  disabled?: boolean
}

const CustomSelect = withStyles({
  root: {
    // backgroundColor: '#ffffff',
    borderRadius: '4px 4px 0 0',
    width: '100%',
  },
  icon: {
    width: '13px',
    margin: '0.5rem',
  },
})(Select)

const CustomMenuItem = withStyles({
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.14) !important',
  },
  root: {
    fontSize: '12px',
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
})(MenuItem)

const useStyles = makeStyles({
  select: {
    '&:hover:not(.Mui-disabled):before': {
      borderColor: '#8E0038',
    },
  },
  selectLarge: {
    '& > div': {
      padding: '14px 10px',
    },
    borderRadius: '4px 4px 0 0',
  },
  selectMedium: {
    '& > div': {
      padding: '13px',
    },
  },
  selectSmall: {
    '& > div': {
      padding: '10px 14px',
    },
    '& > svg': {
      top: '4%',
    },
  },
  selectVariantGrey: {
    backgroundColor: '#F8F8F8',
  },
  selectVariantDefault: {
    backgroundColor: '#ffffff',
  },
})

export function SingleSelect(props: SingleSelectProps<FilterOptions>) {
  const { options, size, onChangeHandler, variant, defaultValue = '', disabled = false } = props
  const classes = useStyles()
  const [value, setValue] = useState(defaultValue)
  const onChange = (event: React.ChangeEvent<{ value: any }>) => {
    setValue(event.target.value)
    onChangeHandler && onChangeHandler(event.target.value)
  }

  useEffect(() => {
    setValue(defaultValue)
  }, [options, defaultValue])

  function getSelectSize() {
    let returnClass = ``
    switch (size) {
      case 'small':
        returnClass = classes.selectSmall
        break
      case 'medium':
        returnClass = classes.selectMedium
        break
      default:
        returnClass = classes.selectLarge
        break
    }
    return returnClass
  }

  function getSelectVariant() {
    let returnClass = ``
    switch (variant) {
      case 'grey':
        returnClass = classes.selectVariantGrey
        break
      default:
        returnClass = classes.selectVariantDefault
        break
    }
    return returnClass
  }

  function getClassnames() {
    return `${classes.select} ${getSelectSize()} ${getSelectVariant()}`
  }

  return (
    <CustomSelect
      value={value}
      fullWidth={true}
      IconComponent={ArrowIcon}
      className={getClassnames()}
      onChange={onChange}
      disabled={disabled}
    >
      {options.map((item: any, index) => (
        <CustomMenuItem key={index} value={item.value || item.label}>
          {item.label}
        </CustomMenuItem>
      ))}
    </CustomSelect>
  )
}
