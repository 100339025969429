import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { LoginPage } from './login/login-page'
import { NoAccessPage } from './no-access/no-access-page'
import SasReportsContainer from '../containers/sas-reports/SasReportsContainer'
import { ProtectedRoute } from 'src/components/protected-route/protected-route'

export function AppRoutes() {
  return (
    <>
      <Switch>
        <Route path='/noaccess' component={NoAccessPage} />
        <ProtectedRoute path='/app' component={SasReportsContainer} />
        <Route path='/login' component={LoginPage} />
        <Route path='/*' component={LoginPage} />
      </Switch>
    </>
  )
}
