import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  Typography,
  // Menu,
  // MenuItem,
} from '@material-ui/core'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { Card } from '../../components/card/card'
import { Logo } from '../../components/logo/logo'
import { useTranslation } from 'react-i18next'
import { useCurrentUser, usePing } from 'src/reducers/user/user-hook'
import { DASHBOARD_PAGE, FLEETS_PAGE } from 'src/constants/routes'

const useStyles = makeStyles(theme =>
  createStyles({
    dividerStyle: {
      height: '80%',
      marginRight: '24px',
    },
    profileNameLabel: {
      marginRight: '24px',
    },
    menuItem: {
      textDecoration: 'none',
      padding: '1rem 1.5rem',
      color: theme.palette.secondary.main,
      borderBottom: '4px solid',
      borderBottomColor: 'transparent',
      '&.active': {
        color: theme.palette.primary.main,
        borderBottomColor: theme.palette.primary.main,
      },
    },
  }),
)
export function Header() {
  const [currentUser] = useCurrentUser()
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  usePing()
  // const [anchorEl, setAnchorEl] = React.useState<null | SVGElement>(null)
  // const handleClick = (event: React.MouseEvent<SVGElement>) => {
  //   setAnchorEl(event.currentTarget)
  // }

  // const handleClose = () => {
  //   i18next.changeLanguage(i18next.language === 'en' ? 'de' : 'en')
  //   setAnchorEl(null)
  // }
  return (
    <Box height='60px' flex='none' display='flex' flexDirection='column' width='100%' zIndex={800}>
      <Box display='flex' flexDirection='column' width='100%' position='fixed' zIndex='100'>
        <Card>
          <Box
            display='flex'
            maxWidth='96rem'
            justifyContent='space-between'
            margin='auto'
            paddingX='2rem'
            paddingBottom='0'
          >
            <Box
              display='flex'
              alignItems='center'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push(DASHBOARD_PAGE)
              }}
            >
              <Logo width='118px' height='50px' />
            </Box>
            <Box display='flex' alignItems='center'>
              <NavLink className={classes.menuItem} to={DASHBOARD_PAGE}>
                <Typography>{t('dashboard')}</Typography>
              </NavLink>
              <Box m={1} />
              <NavLink className={classes.menuItem} to={FLEETS_PAGE}>
                <Typography>{t('fleets')}</Typography>
              </NavLink>
              <Box m={1} />
              <Divider orientation='vertical' className={classes.dividerStyle} />
              <Typography variant='caption' className={classes.profileNameLabel}>
                {t('hello')} {currentUser?.givenName} {currentUser?.surname}
              </Typography>
              <PersonOutlineIcon width={'20px'} height={'20px'} style={{ cursor: 'default' }} />
              {/* <PersonOutlineIcon width={'20px'} height={'20px'} onClick={handleClick} /> */}
              {/* <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleClose}>Switch Language</MenuItem>
              </Menu> */}
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  )
}
