import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../state/app-state'
import { NoteActions, NoteActionTypes } from './notes-actions'
import { FleetNotes } from '../../state/note'
import { selectProgress } from 'redux-saga-tools'

export function useAddNotes(): [
  (fleetId: string) => void,
  { fetchLoading: boolean; fetchError: any },
] {
  const dispatch = useDispatch()
  const { inProgress = false, error = '' } =
    useSelector(state => selectProgress(state, NoteActionTypes.GET_NOTE)) || {}
  const getNote = (fleetId: string) => dispatch(NoteActions.getNote(fleetId))
  return [getNote, { fetchLoading: inProgress, fetchError: error }]
}

export function useSaveNotes(): [
  (fleetId: string, note: string) => void,
  { saveLoading: boolean; saveError: any },
] {
  const dispatch = useDispatch()
  const { inProgress = false, error = '' } =
    useSelector(state => selectProgress(state, NoteActionTypes.SAVE_NOTE)) || {}
  const saveNote = (fleetId: string, note: string) => dispatch(NoteActions.saveNote(fleetId, note))
  return [saveNote, { saveLoading: inProgress, saveError: error }]
}

export function useUpdateNotes(): [
  (commentId: string, note: string) => void,
  { loading: boolean; error: any },
] {
  const dispatch = useDispatch()
  const { inProgress = false, error = '' } =
    useSelector(state => selectProgress(state, NoteActionTypes.UPDATE_NOTE)) || {}
  const updateNotes = (commentId: string, note: string) =>
    dispatch(NoteActions.updateNote(commentId, note))
  return [updateNotes, { loading: inProgress, error }]
}

export function useFetchNotes(): FleetNotes {
  const fleetNotes = useSelector((state: AppState) => state.fleetNotes)
  return fleetNotes
}
