import {
  Box,
  Button as MuiButton,
  createStyles,
  Divider,
  makeStyles,
  Paper,
  CircularProgress,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import SaveRoundedIcon from '@material-ui/icons/SaveRounded'
import moment from 'moment'
import 'moment/locale/de'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Proposal } from '../../reducers/proposal/proposal'
import { FleetDetail } from '../../state/fleet'
import { ProposalStatus } from '../../state/reminder'
import { Button } from '../button/button'
import AlertDialog from '../dialog/dialog'
import { SingleSelect } from '../singleSelect/singleSelect'
import { useClearCurrentProposal } from 'src/reducers/proposal/proposal-hooks'
import { NumberText } from '../../pages/calculation/claimHistoryTable/number-text'
import { CALCULATION_PAGE, FLEET_DETAILS_PAGE, SUMMARY_PAGE } from 'src/constants/routes'

const euroSymbol = <span>&#8364;</span>
const useStyles = makeStyles(() =>
  createStyles({
    headerWrapper: {
      position: 'fixed',
      height: '72px',
      width: '100vw',
      zIndex: 999,
    },
    navigationButtonStyle: {
      textTransform: 'none',
      borderRadius: '0',
      fontSize: '16px',
    },
    forwardButton: {
      minWidth: '13%',
    },
    backButton: {
      height: '72px',
    },
    saveButton: { width: '13.5%' },
    heading: {
      margin: 0,
    },
    statusLabel: { fontSize: '16px', marginRight: '25px' },
    progressSelect: {
      width: '144px',
      color: '#333333',
      fontSize: '12px',
      paddingRight: '5px',
    },
    headingLabel: {
      fontSize: '12px',
      color: '#807E6F',
      paddingRight: '25px',
    },
    smallLabel: {
      fontSize: '8px',
      color: '#807E6F',
    },
    dividerStyle: {
      height: '80%',
      margin: '8px',
    },
  }),
)

function BackNavigationButton({
  proposalId,
  fleetId,
  setDialogStatus,
  active,
}: {
  proposalId?: string
  fleetId?: string
  setDialogStatus: any
  active: any
}) {
  const classes = useStyles()
  const history = useHistory()
  const clearCurrentProposal = useClearCurrentProposal()
  const isCalculationPage = history.location.pathname.includes(CALCULATION_PAGE)
  const navigationPath = isCalculationPage
    ? `${FLEET_DETAILS_PAGE}/${fleetId}/versions`
    : `${CALCULATION_PAGE}/${fleetId && proposalId ? `${fleetId}/${proposalId}` : `${fleetId}/`}`
  return (
    <MuiButton
      variant='contained'
      color='secondary'
      size='large'
      className={`${classes.navigationButtonStyle} ${classes.backButton}`}
      startIcon={isCalculationPage ? <CloseIcon /> : <ArrowBackIcon />}
      onClick={() => {
        if (isCalculationPage && active) {
          setDialogStatus(true)
        } else {
          isCalculationPage && clearCurrentProposal()
          history.push(navigationPath)
          // history.goBack()
        }
      }}
    >
      &nbsp;
    </MuiButton>
  )
}

function ForwardNavigationButton(props: any) {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const clearCurrentProposal = useClearCurrentProposal()
  const [buttonLabel, setButton] = React.useState<string>(t('complete'))
  const {
    navigationHandler,
    fleetId,
    proposalId,
    setDialogStatus,
    active,
    allowClick,
    handleSaveVersion,
  } = props
  const isSummaryPage = history.location.pathname.includes('/summary')
  return (
    <MuiButton
      disabled={!allowClick}
      variant='contained'
      color={allowClick ? 'primary' : 'secondary'}
      size='large'
      className={`${classes.navigationButtonStyle} ${classes.forwardButton}`}
      endIcon={isSummaryPage ? '' : <ArrowForwardIcon />}
      startIcon={isSummaryPage ? <CheckCircleIcon /> : ''}
      onClick={() => {
        if (!isSummaryPage) {
          history.push(`${SUMMARY_PAGE}/${proposalId ? `${fleetId}/${proposalId}` : fleetId}`)
        }
        if (isSummaryPage && buttonLabel === t('complete')) {
          setButton(t('close'))
          active && handleSaveVersion()
        }
        if (isSummaryPage && buttonLabel === t('close')) {
          if (active) {
            setDialogStatus(true)
          } else {
            clearCurrentProposal()
            history.push(`${FLEET_DETAILS_PAGE}/${fleetId}/versions`)
          }
        }
        if (isSummaryPage && navigationHandler && buttonLabel === t('complete')) {
          navigationHandler && navigationHandler()
        }
      }}
    >
      {isSummaryPage ? buttonLabel : t('continue')}
    </MuiButton>
  )
}

function DetailsSection({ fleet }: { fleet: FleetDetail }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { fleetName, id, netPremium, currentCarpark, fleetPaymentText } = fleet
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' paddingLeft='35px'>
      <h3 className={classes.heading}>{fleetName}</h3>
      <Box>
        <span className={classes.smallLabel}>MNR:</span>
        <span className={classes.headingLabel}>{id}</span>
        <span className={classes.smallLabel}>Fuhrpark:</span>
        <span className={classes.headingLabel}> {currentCarpark}</span>
        <span className={classes.smallLabel}>{t('premium')}: </span>
        <span className={classes.headingLabel}>
          <NumberText precision={0}>{netPremium}</NumberText> {euroSymbol}
        </span>
        <span className={classes.smallLabel}>ZW:</span>
        <span className={classes.headingLabel}> {fleetPaymentText}</span>
      </Box>
    </Box>
  )
}

function VersionDetailsSection(props: any) {
  const { handleSaveVersion, active = false, timeSaved, versionNumber } = props
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const setCounter = useState(0)[1]
  const handleButtonClick = () => {
    active && handleSaveVersion()
  }

  useEffect(() => {
    const interval = setInterval(() => {
      // Update state every 1 minute to update last saved timestamp on screen
      setCounter(counter => counter + 1)
    }, 60 * 1000)
    return () => clearInterval(interval)
  }, [setCounter])

  return (
    <Box display='flex' justifyContent='center' alignItems='center'>
      <Button
        disabled={!active}
        color={active ? 'primary' : 'secondary'}
        variant='contained'
        size='medium'
        className={classes.saveButton}
        clickHandler={handleButtonClick}
        padding='0 30px'
        startIcon={<SaveRoundedIcon />}
      >
        {t('saveANewVersion')}
      </Button>
      <Box display='flex' flexDirection='column' justifyContent='center' paddingLeft='20px'>
        <h3 className={classes.heading}>v.{versionNumber}</h3>
        <span className={classes.headingLabel}>
          {versionNumber > 0 ? moment(timeSaved).lang(i18n.language).fromNow() : t('unsaved')}
        </span>
      </Box>
    </Box>
  )
}

function StatusSection({
  status,
  onChange,
  disabled,
}: {
  status: ProposalStatus
  onChange?: (status: ProposalStatus) => void
  disabled: boolean
}) {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Box display='flex' justifyContent='center' alignItems='center'>
      <p className={classes.statusLabel}>Status:</p>
      <Box className={classes.progressSelect}>
        <SingleSelect
          variant='grey'
          onChangeHandler={onChange}
          options={[
            { label: t('inProgress'), value: ProposalStatus.INPROGRESS },
            { label: t('send'), value: ProposalStatus.SENT },
            { label: t('done'), value: ProposalStatus.DONE },
            { label: t('canceled'), value: ProposalStatus.CANCELLED },
            { label: t('negotiation'), value: ProposalStatus.NEGOTIATION },
          ]}
          defaultValue={status}
          disabled={disabled}
        />
      </Box>
    </Box>
  )
}
interface CalculationHeaderProps {
  proposal: Proposal
  fleet: FleetDetail
  saveEnabled?: boolean
  onSaveProposal?: (proposal: Proposal) => void
  navigationHandler?: any
  onChangeStatus?: (stats: ProposalStatus) => void
  original?: Proposal | null
  contractType?: string
  isError?: boolean
  isLoading?: boolean
}
export function CalculationHeader(props: CalculationHeaderProps) {
  const {
    navigationHandler,
    proposal,
    fleet,
    onSaveProposal,
    saveEnabled,
    onChangeStatus,
    contractType,
    isError,
    isLoading,
  } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const clearCurrentProposal = useClearCurrentProposal()
  const [dialogStatus, setDialogStatus] = React.useState(false)

  const handleDataDiscard = (confirmed: boolean) => {
    const isCalculationPage = history.location.pathname.includes(CALCULATION_PAGE)
    const isSummaryPage = history.location.pathname.includes(SUMMARY_PAGE)
    const navigationPath = isCalculationPage
      ? `${FLEET_DETAILS_PAGE}/${fleet.id}/versions`
      : isSummaryPage
      ? `${FLEET_DETAILS_PAGE}/${fleet.id}/versions`
      : `${CALCULATION_PAGE}/${proposal.id ? `${fleet.id}/${proposal.id}` : fleet.id}`
    if (confirmed && (isCalculationPage || isSummaryPage)) {
      clearCurrentProposal()
      history.push(navigationPath)
      // history.goBack()
    }
    setDialogStatus(false)
  }

  function handleSaveVersion() {
    onSaveProposal && onSaveProposal(proposal)
  }

  function checkFleetContractType() {
    return contractType === 'SE' || contractType === 'SO' || contractType === 'SP' ? true : false
  }

  return (
    <Paper square={true} className={classes.headerWrapper}>
      <Box
        width='100%'
        height='100%'
        display='flex'
        justifyContent='space-between'
        alignItems='stretch'
      >
        <BackNavigationButton
          proposalId={proposal.id}
          fleetId={fleet.id}
          setDialogStatus={setDialogStatus}
          active={saveEnabled}
        />
        <DetailsSection fleet={fleet} />
        {!isError ? (
          <>
            <Divider orientation='vertical' className={classes.dividerStyle} />
            <VersionDetailsSection
              handleSaveVersion={handleSaveVersion}
              active={saveEnabled && checkFleetContractType()}
              timeSaved={proposal.updatedTs}
              versionNumber={proposal.version}
            />
            {isLoading ? (
              <Box position='absolute' right='33%' top='25%'>
                <CircularProgress />
              </Box>
            ) : (
              <></>
            )}
            <Divider orientation='vertical' className={classes.dividerStyle} />
            <StatusSection
              status={proposal.status}
              onChange={onChangeStatus}
              disabled={!checkFleetContractType()}
            />
            <ForwardNavigationButton
              navigationHandler={navigationHandler}
              fleetId={fleet.id}
              proposalId={proposal.id}
              setDialogStatus={setDialogStatus}
              active={saveEnabled}
              allowClick={checkFleetContractType()}
              handleSaveVersion={handleSaveVersion}
            />
          </>
        ) : (
          <Box display='flex' flexGrow='1' />
        )}
      </Box>
      <AlertDialog open={dialogStatus} title={t('confirmation')} handleClose={handleDataDiscard}>
        {t('confirmDiscardData')} ?
      </AlertDialog>
    </Paper>
  )
}
