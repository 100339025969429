import React, { ReactNode } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTranslation } from 'react-i18next'

interface DialogProps {
  open: boolean
  handleClose: Function
  title: string
  children: ReactNode
}
export default function AlertDialog(props: DialogProps) {
  const { t } = useTranslation()
  const { open = false, handleClose, title, children } = props
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{children}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color='primary' autoFocus>
            {t('no')}
          </Button>
          <Button onClick={() => handleClose(true)} color='primary'>
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
