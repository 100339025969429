import React from 'react'
import { Box } from '@material-ui/core'
import envLink, { SAS_REPORT_URL } from '../../utils/envLink'
import { SAS_REPORT_URI } from 'src/constants/routes'

export const ESATable = React.memo(() => {
  return (
    <Box alignItems='center' justifyContent='center' width='100%'>
      <sas-report-object
        authenticationType='credentials'
        url={envLink(SAS_REPORT_URL)}
        reportUri={SAS_REPORT_URI}
        objectName='ve7475'
        style={{ width: '100%', height: '400px' }}
        id='sas-report-ve7475-esaTable'
      ></sas-report-object>
    </Box>
  )
})
