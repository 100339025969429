import { createSaga } from 'redux-saga-tools'
import { all, call, put } from 'redux-saga/effects'
import { AppState } from '../../state/app-state'
import { getFleetDetailByMnr } from '../fleet/fleet-service'
import { Proposal } from './proposal'
import { ProposalActions, ProposalActionTypes } from './proposal-actions'
import {
  fetchClaimDetailsByFleetId,
  fetchClaimSummaryByFleetId,
  fetchProposalById,
  getActiveProposals,
  saveProposal,
  saveReminder,
} from './proposal-service'
import { markAsDone } from '../reminder/reminder-service'

const {
  CREATE_NEW_PROPOSAL,
  FETCH_CLAIM_DETAILS,
  UPDATE_CLAIM_SUMMARY,
  CHANGE_OBSERVATION_PERIOD,
  CHANGE_RESERVE_RATIO,
  CHANGE_SURCHARGE,
  APPLY_PROVISION_CUT,
  UPDATE_DEDUCTABLE,
  CHANGE_RATIO_OF_CLAIMS,
  LIST_PROPOSALS,
  CHANGE_DEDUCTIBLE,
  SAVE_PROPOSAL,
  GET_PROPOSAL_BY_ID,
  TOGGLE_DEDUCTIBLE_SELECTION,
  FETCH_CLAIM_HISTORY_BY_ID,
  CHANGE_CARPARK,
} = ProposalActionTypes

function* recalculateProposal() {
  yield put(ProposalActions.calculateClaimTotal())
  yield put(ProposalActions.simulateDeductables())
  yield put(ProposalActions.calculateRenewalPremium())
}

function* fetchClaimDetails(fleetId: string) {
  let calls = 0
  let reCall = true
  const maxCalls = 5
  while (reCall) {
    try {
      const { claimSummary, claimDetails, fleetDetails } = yield all({
        claimSummary: call(fetchClaimSummaryByFleetId, fleetId),
        claimDetails: call(fetchClaimDetailsByFleetId, fleetId),
        fleetDetails: call(getFleetDetailByMnr, fleetId),
      })
      reCall = false
      yield put(ProposalActions.addClaimData(claimDetails))
      yield put(ProposalActions.addClaimSummary(claimSummary))
      yield put(ProposalActions.addFleetDetails(fleetDetails))
      yield recalculateProposal()
    } catch (e) {
      if (++calls === maxCalls) {
        reCall = false
        throw new Error(e as any)
      }
    }
  }
}

function* fetchClaimHistoryByFleetId(fleetId: string): any {
  const claimDetails = yield call(fetchClaimDetailsByFleetId, fleetId)
  yield put(ProposalActions.addClaimData(claimDetails))
}

function* getProposals(fleetId: string): any {
  const proposals = yield call(getActiveProposals, fleetId)
  yield put(ProposalActions.loadProposals(proposals))
}

function* saveCurrentProposal({ proposal }: { proposal: Proposal }): any {
  const updatedProposal = {
    ...proposal,
    version: proposal.version,
  }
  const savedProposal = yield call(saveProposal, updatedProposal)
  if (proposal.status !== 'DONE' && proposal.status !== 'CANCELLED') {
    yield call(saveReminder, savedProposal)
  } else {
    yield call(markAsDone, proposal.fleetId)
  }
  yield put(ProposalActions.addProposal(savedProposal))
  yield put(ProposalActions.selectProposal(savedProposal.id))
}

function* fetchProposal({ id, fleetId }: { id: string; fleetId: string }, state: AppState) {
  if (
    state.proposals === undefined ||
    state.proposals.byId === undefined ||
    Object.keys(state.proposals.byId).length === 0
  ) {
    const { proposal, claimDetails } = yield all({
      proposal: call(fetchProposalById, id),
      claimDetails: call(fetchClaimDetailsByFleetId, fleetId),
    })
    yield put(ProposalActions.addProposal(proposal))
    yield put(ProposalActions.addClaimData(claimDetails))
  }
}

export const proposalSagas = [
  createSaga(CREATE_NEW_PROPOSAL, fetchClaimDetails),
  createSaga(FETCH_CLAIM_DETAILS, fetchClaimDetails),
  createSaga(FETCH_CLAIM_HISTORY_BY_ID, fetchClaimHistoryByFleetId),
  createSaga(
    [
      UPDATE_CLAIM_SUMMARY,
      CHANGE_OBSERVATION_PERIOD,
      CHANGE_RESERVE_RATIO,
      CHANGE_SURCHARGE,
      APPLY_PROVISION_CUT,
      UPDATE_DEDUCTABLE,
      CHANGE_RATIO_OF_CLAIMS,
      CHANGE_DEDUCTIBLE,
      TOGGLE_DEDUCTIBLE_SELECTION,
      CHANGE_CARPARK,
    ],
    recalculateProposal,
  ),
  createSaga(LIST_PROPOSALS, getProposals),
  createSaga(SAVE_PROPOSAL, saveCurrentProposal),
  createSaga(GET_PROPOSAL_BY_ID, fetchProposal),
]
