import { Box } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import DatePicker from 'src/components/datePicker/date-picker'
import { Label } from '../../components/label/label'
import { TextAreaComponent } from '../../components/textArea/textArea'
import { TextFieldComponent } from '../../components/textField/textField'
import { RadioComponent } from './radioComponent'
import moment from 'moment'

interface CalimsTableData {
  label: string
  value: any
}

interface SummaryProposalProps {
  calimsTableData: CalimsTableData[]
  details: any
  handleChange: Function
  changeRecipient: Function
  selectedRecipient: string
}

export function SummaryProposalView(props: SummaryProposalProps) {
  const { calimsTableData, details, handleChange, changeRecipient, selectedRecipient } = props
  const { t } = useTranslation()

  const styleOne = {
    width: '336px',
    height: '89px',
  }
  const styleFour = {
    width: '336px',
    height: '75px',
  }
  const styleFive = {
    width: '95%',
    height: '81px',
  }
  const styleSix = {
    width: '95%',
    height: '61px',
  }
  const styleEight = {
    width: '440px',
    height: '75px',
    fontSize: '12px',
    backgroundColor: '#F8F8F8',
    borderBottom: '1px solid #AEAEAE',
    padding: '12px 0 4px 16px',
    borderRadius: '4px 4px 0 0',
  }
  const styleSeven = {
    width: '81%',
    height: '167px',
  }
  const styleNine = {
    width: '93%',
    height: '120px',
  }

  return (
    <Box display='flex' flexDirection='column' padding='2rem' width='100%' position='relative'>
      <Box display='flex' flexDirection='row' width='100%'>
        <Box flexBasis='45%'>
          <Box display='flex' flexDirection='column' flexBasis='100%'>
            <Label
              value={t('recipient')}
              customStyles={{ fontSize: '0.625rem', textTransform: 'upperCase' }}
            />
            <div style={styleOne}>
              <TextAreaComponent
                value={details[selectedRecipient]}
                handleChange={(value: any) => {
                  handleChange(value, selectedRecipient)
                }}
              />
            </div>
          </Box>
        </Box>
        <Box flexBasis='55%' display='flex' alignItems='center' flexDirection='row'>
          <RadioComponent
            onChange={changeRecipient}
            selectedItem={details.selectedItem ? details.selectedItem : 'brokerAddress'}
          />
        </Box>
      </Box>
      <Box display='flex' flexDirection='row' paddingTop='2rem'>
        <Box flexBasis='18%' display='flex' flexDirection='column'>
          <Label
            value={t('proposalDate')}
            customStyles={{ fontSize: '0.625rem', textTransform: 'upperCase' }}
          />
          <Box height='40px'>
            <DatePicker
              getSelectedDate={(date: any) => {
                handleChange(date, 'proposalDate')
              }}
              intialDate={
                details && details.proposalDate ? moment.unix(details.proposalDate) : moment()
              }
            />
          </Box>
        </Box>
        <Box flexBasis='23%' display='flex' flexDirection='column' padding='0 1rem'>
          <Label
            value='Ansprechpartner'
            customStyles={{ fontSize: '0.625rem', textTransform: 'upperCase' }}
          />
          <Box height='40px'>
            <TextFieldComponent
              value={details.contactPerson}
              size='large'
              handleChange={(value: any) => {
                handleChange(value, 'contactPerson')
              }}
            />
          </Box>
        </Box>
        <Box padding='0 1rem'>
          <Box display='flex' flexDirection='column'>
            <Label
              value='Tel:'
              customStyles={{ fontSize: '0.625rem', textTransform: 'upperCase' }}
            />
            <Box height='40px' width='120px'>
              <TextFieldComponent
                value={details.extension}
                size='large'
                handleChange={(value: any) => {
                  handleChange(value, 'extension')
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display='flex' flexDirection='row' paddingTop='2rem'>
        <Box display='flex' flexDirection='column' flexBasis='47%'>
          <Label
            value={t('customer')}
            customStyles={{ fontSize: '0.625rem', textTransform: 'upperCase' }}
          />
          <Box display='flex' flexDirection='row'>
            <Box style={styleFour}>
              <TextAreaComponent
                value={details.customerInfo}
                handleChange={(value: any) => {
                  handleChange(value, 'customerInfo')
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box width='120px' marginLeft='1rem'>
          <Label value={t('validFrom')} customStyles={{ fontSize: '0.625rem' }} />
          <DatePicker
            intialDate={
              details && details.validFromDate
                ? moment.unix(details.validFromDate)
                : moment().startOf('year')
            }
            minDate={new Date(new Date().getFullYear(), 0, 1)}
            getSelectedDate={(date: any) => {
              handleChange(date, 'validFromDate')
            }}
          />
        </Box>
      </Box>
      <Box display='flex' flexDirection='row' paddingTop='2rem' width='100%'>
        <Box display='flex' flexDirection='column' flexBasis='100%'>
          <Label
            value='Intro'
            customStyles={{ fontSize: '0.625rem', textTransform: 'upperCase' }}
          />
          <div style={styleFive}>
            <TextAreaComponent
              value={details.intro}
              handleChange={(value: any) => {
                handleChange(value, 'intro')
              }}
            />
          </div>
        </Box>
      </Box>
      <Box display='flex' flexDirection='row' paddingTop='2rem'>
        <Box display='flex' flexDirection='column'>
          <Label
            value={t('claimRatio')}
            customStyles={{ fontSize: '0.625rem', textTransform: 'upperCase' }}
          />
          <div style={styleEight}>
            <Box display='flex'>
              {calimsTableData.map((item, i) => {
                return (
                  <Box display='flex' flexDirection='column' key={i} width='140px'>
                    <p style={{ fontWeight: 'bold', margin: '0 0 1em 0' }}>{item.label}</p>
                    <p style={{ margin: 0 }}>{item.value}</p>
                  </Box>
                )
              })}
              <Box display='flex' flexDirection='column' width='110px'>
                <Box marginTop='-3px'>
                  <TextFieldComponent
                    value={details.purBasedYear}
                    handleChange={(value: any) => {
                      handleChange(value, 'purBasedYear')
                    }}
                  />
                </Box>
                <Box marginTop='3px' width='110px'>
                  <TextFieldComponent
                    value={details.purBasedClaimRatio}
                    handleChange={(value: any) => {
                      handleChange(value, 'purBasedClaimRatio')
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </div>
        </Box>
      </Box>
      <Box display='flex' flexDirection='row' paddingTop='2rem'>
        <Box display='flex' flexDirection='column' flexBasis='100%'>
          <Label
            value={t('reason')}
            customStyles={{ fontSize: '0.625rem', textTransform: 'upperCase' }}
          />
          <div style={styleSix}>
            <TextAreaComponent
              value={details.reason}
              handleChange={(value: any) => {
                handleChange(value, 'reason')
              }}
            />
          </div>
        </Box>
      </Box>
      <Box display='flex' flexDirection='column' paddingTop='2rem'>
        <Label
          value={t('offer')}
          customStyles={{ fontSize: '0.625rem', textTransform: 'upperCase' }}
        />
        <Box flexDirection='row' display='flex'>
          <Box width='180px' marginBottom='5px'>
            <TextFieldComponent
              value={`${moment.unix(details.validFromDate).format('DD.MM.YYYY')} (${t(
                'validDate',
              )})`}
              size='large'
              disabled={true}
            />
          </Box>
        </Box>
        <div style={styleSeven}>
          <TextAreaComponent
            value={details.offer}
            handleChange={(value: any) => {
              handleChange(value, 'offer')
            }}
          />
        </div>
      </Box>
      <Box display='flex' flexDirection='row' paddingTop='2rem'>
        <Box width='78%' flexDirection='column' display='flex'>
          <Label
            value={t('responseDate&Ending')}
            customStyles={{ fontSize: '0.625rem', textTransform: 'upperCase' }}
          />
          <div style={styleNine}>
            <TextAreaComponent
              value={details.responseDateAndEnding}
              handleChange={(value: any) => {
                handleChange(value, 'responseDateAndEnding')
              }}
            />
          </div>
        </Box>
        <Box width='110px'>
          <Label
            value={t('dueDate')}
            customStyles={{ fontSize: '0.625rem', textTransform: 'upperCase' }}
          />
          <DatePicker
            intialDate={
              details && details.dueDateTs
                ? moment.unix(details.dueDateTs)
                : moment().add(14, 'days')
            }
            getSelectedDate={(date: any) => {
              handleChange(date, 'dueDateTs')
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}
