import React from 'react'
import { Box, Container } from '@material-ui/core'
import { ReminderContainer } from '../../containers/reminder/reminder-container'
import FleetSearch from './fleet-search'
import QuickLinks from './quick-links'

export function HomePage() {
  return (
    <>
      <Container disableGutters={true}>
        <Box display='flex' flexDirection='column' padding='2rem 0.5rem' height='100%'>
          <Box display='flex' paddingTop='21px' height='100%'>
            <Box width='60%' display='flex'>
              <ReminderContainer />
            </Box>
            <Box p={1} />
            <Box flexDirection='column' width='40%' display='flex' flex='auto'>
              <FleetSearch />
              <QuickLinks />
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  )
}
