import React from 'react'
import { Paper, Box, withStyles, WithStyles } from '@material-ui/core'

import { Dashboard } from 'src/views/viya-objects/dashboard'

const styles = () => ({
  dashboardWrapper: {
    display: 'flex',
    overflow: 'hidden',
    margin: '4px',
  },
})
class SasDashboardContainer extends React.Component<WithStyles<typeof styles>, {}> {
  shouldComponentUpdate(): boolean {
    return false
  }

  render() {
    const { classes } = this.props
    return (
      <Paper square={false}>
        <Box className={classes.dashboardWrapper}>
          <Dashboard />
        </Box>
      </Paper>
    )
  }
}

export default withStyles(styles)(SasDashboardContainer)
