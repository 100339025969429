import { createSaga } from 'redux-saga-tools'
import { call, put } from 'redux-saga/effects'
import {
  getActiveFleets,
  getFleetDetailByMnr,
  getWatchListByMnr,
  setWatchListByMnr,
  setWatchListIntial,
} from './fleet-service'
import { FleetActions, FleetActionTypes } from './fleet-actions'
import { SearchParameter } from '../../state/search-filter-params'

function* getFleets({ params }: { params: SearchParameter }): any {
  let calls = 0
  let reCall = true
  const maxCalls = 5
  while (reCall) {
    try {
      const fleets = yield call(getActiveFleets, params)
      reCall = false
      yield put(FleetActions.loadFleets(fleets))
    } catch (e) {
      if (++calls === maxCalls) {
        reCall = false
        throw new Error(e as any)
      }
    }
  }
}

function* getFleetDetails(mnr: string): any {
  const fleet = yield call(getFleetDetailByMnr, mnr)
  yield put(FleetActions.addFleet(fleet))
  yield put(FleetActions.selectFleet(fleet.id))
}

function* getWatchList(mnr: string): any {
  const watchStatus = yield call(getWatchListByMnr, mnr)
  yield put(FleetActions.setWatchStatus(watchStatus))
}

function* setItemWatch(params: any): any {
  const watchStatus = yield call(setWatchListByMnr, params)
  yield put(FleetActions.setWatchStatus(watchStatus))
}

function* setIntialWatch(params: any): any {
  const watchStatus = yield call(setWatchListIntial, params)
  yield put(FleetActions.setWatchStatus(watchStatus))
}

export const fleetSagas = [
  createSaga(FleetActionTypes.LIST_FLEETS, getFleets),
  createSaga(FleetActionTypes.GET_FLEET_BY_ID, getFleetDetails),
  createSaga(FleetActionTypes.GET_WATCH_STATUS, getWatchList),
  createSaga(FleetActionTypes.SET_FLEET_WATCH, setItemWatch),
  createSaga(FleetActionTypes.SET_WATCH_STATUS_INTIAL, setIntialWatch),
]
