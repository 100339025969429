import 'date-fns'
import React, { useEffect } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { Box, makeStyles, createStyles } from '@material-ui/core'
import { de } from 'date-fns/locale'

interface DatePickerProps {
  getSelectedDate?: Function
  intialDate?: any
  minDate?: any
}

export default function DatePicker(props: DatePickerProps) {
  const { getSelectedDate, intialDate = new Date(), minDate = new Date() } = props
  const [selectedDate, setSelectedDate] = React.useState(intialDate)

  const handleDateChange = (date: any) => {
    setSelectedDate(date)
    getSelectedDate && getSelectedDate(date)
  }

  useEffect(() => {
    setSelectedDate(intialDate)
  }, [intialDate])

  const useStyles = makeStyles(() =>
    createStyles({
      progressSelect: {
        borderRadius: '4px 4px 0 0',
        backgroundColor: '#F8F8F8',
        '& > div': {
          '& input': {
            paddingLeft: '10px',
            padding: '6px 0 12px',
            fontSize: '12px',
          },
          marginTop: '8px',
          '& > div': {
            '& button': {
              padding: '6px 0 9px',
            },
          },
        },
      },
    }),
  )
  const classes = useStyles()
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
      <Box className={classes.progressSelect} height='40px'>
        <KeyboardDatePicker
          disableToolbar
          variant='inline'
          format='dd.MM.yyyy'
          margin='normal'
          id='date-picker-inline'
          minDate={minDate}
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          minDateMessage='Datum kann nicht in der Vergangenheit liegen'
        />
      </Box>
    </MuiPickersUtilsProvider>
  )
}
