import React from 'react'
import { Box } from '@material-ui/core'
import { FleetList } from '../../views/fleet-list/fleet-list'
import { useTranslation } from 'react-i18next'
import { Label } from '../../components/label/label'

export function FleetListContainer(props: any) {
  const { loading, fleetData, error } = props
  const { t } = useTranslation()
  const fleetLabel = t('fleets')
  const fleetDataLength =
    fleetData && fleetData.length > 0 && fleetData[0].id && !error ? fleetData.length : ''

  return (
    <>
      <Label value={`${fleetLabel} (${fleetDataLength})`} customStyles={{ paddingLeft: '1rem' }} />
      <Box paddingTop='1rem'>
        <FleetList isLoading={loading} data={fleetData} error={error} />
      </Box>
    </>
  )
}
