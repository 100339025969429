import { Deductable } from '../../state/deductable'

export const DeductableActionTypes = {
  LIST_DEDUCTABLES: 'LIST_DEDUCTABLES',
  LOAD_DEDUCTABLES: 'LOAD_DEDUCTABLES',
}

export const DeductableActions = {
  listDeductables: () => ({ type: DeductableActionTypes.LIST_DEDUCTABLES }),
  loadDeductables: (deductables: Deductable[]) => ({
    type: DeductableActionTypes.LOAD_DEDUCTABLES,
    payload: deductables,
  }),
}
