import React from 'react'
import { useReminders, useMarkReminderAsDone } from '../../reducers/reminder/reminder-hooks'
import ReminderListView from '../../views/reminder-list/reminder-list'

export function ReminderContainer() {
  const [reminders, { loading }] = useReminders()
  const [markReminderAsDone, { reminderMarkAsDoneLoading }] = useMarkReminderAsDone()
  return (
    <ReminderListView
      isLoading={loading}
      reminders={reminders}
      onMarkReminderAsDone={markReminderAsDone}
      reminderMarkAsDoneLoading={reminderMarkAsDoneLoading}
    />
  )
}
