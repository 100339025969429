import React from 'react'
import { Button as MuiButton } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'

interface ButtonProps extends MuiButtonProps {
  clickHandler: Function
  height?: string
  padding?: string
  margin?: string
}

const StyleButton = withStyles({
  root: {
    fontSize: '12px',
    minWidth: '80px',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: '24px',
  },
  outlinedSecondary: {
    border: '1px solid #737373',
    '&:hover': {
      border: '1px solid #737373',
    },
  },
  outlinedPrimary: {
    border: '2px solid #8E0038',
    '&:hover': {
      border: '2px solid #8E0038',
    },
  },
})(MuiButton)

export function Button(props: ButtonProps) {
  const {
    children,
    color = 'primary',
    size = 'medium',
    variant = 'contained',
    startIcon,
    clickHandler,
    height = '40px',
    padding,
    margin,
    disabled,
  } = props

  function handleClick() {
    clickHandler && clickHandler()
  }

  return (
    <StyleButton
      variant={variant}
      color={color}
      style={{ height, padding, margin }}
      size={size}
      startIcon={startIcon}
      onClick={() => handleClick()}
      disabled={disabled}
    >
      {children}
    </StyleButton>
  )
}
